import {
  authtoken,
  Logincheck,
  setCreateCollection,
} from "../../../../logic/actions/wallet";
import swal from "sweetalert";
import React, { useState, useEffect } from "react";
import { InactiveBTN, H1 } from "../ProfileManagement/style";
import Applebutton from "./Applesigninbutton";
import GoogleLogin from "react-google-login";
//@ts-ignore
import Geetest from "react-geetest";
import Google from "../../../../assets/icons/Google.svg";
import Close from "../../../../assets/image/close.svg";
import {
  LoginFormLeft,
  LoginFormRight,
  Checkalign,
  ForgotPassLink,
  ExtraLinks,
  Img,
  Container,
  Input,
  Label,
  LoginText,
  Button,
  CheckBox,
  RememberLabel,
  NotaMember,
  Image,
  ButtonAuth,
  OR,
  Auth,
  ImageText,
  PopUpOverlay,
  Popup,
  ClosePopUp,
  ContentDiv,
} from "./style";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import history from "../history";

const Login = () => {
	const dispatch = useDispatch();
	const [noError, setNoError] = useState<boolean>(true);
	const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });
	const [Feildsfilled, setfeildsFilled] = useState(false);
	const [rememberme, setRememberMe] = useState(false);
	const [message, setMessage] = useState<string>('');
	const [showModal, setShowModal] = useState(false);
	const [otherError, setOtherError] = useState<string>('');
	const [forgotPassModal, setForgotPassModal] = useState<boolean>(false);
	const [forgotpassInfo, setForgotPassInfo] = useState({
		email: '',
		OTP: 0,
		newpassword: '',
		confirmnewpassword: '',
	});
	const [otpMessage, setOTPMessage] = useState('');
	const [passMessage, setPassMessage] = useState('');
	const [otpVerifyMessage, setOtpVerifyMessage] = useState('');
	const [twoFactOtp, setTwoFactOtp] = useState(0);
	const [disable, setDisable] = useState(true);
	const clientId = process.env.REACT_APP_CLIENT_ID;

	const LOGIN = gql`
		mutation Login($input: LoginUserDto!) {
			login(input: $input) {
				user {
					first_name
					last_name
					kyc_status
					email
					username
					isEmailVerified
					twoFactorAuth
					role
					status
					socialDiscord
					socialTelegram
					mobile
					profileImageUrl
					_id
					updatedAt
					isProfileUpdated
					createdAt
					canCreateCollection
				}
				token
				message
				expiresIn
				refreshToken
			}
		}
	`;

	const GLogin = gql`
		mutation GLogin($input: GoogleLoginDto!) {
			googleLogin(input: $input) {
				token
				expiresIn
				refreshToken
				message
				user {
					role
				}
			}
		}
	`;

	const VerifyForgotPassOtp = gql`
		mutation forgotPassword($input: ForgotPasswordDto!) {
			forgotPassword(input: $input) {
				message
			}
		}
	`;

	const TwoFactAuth = gql`
		mutation TwoFactorVerify($input: TwoFactorOtpDto!) {
			twoFactorVerify(input: $input) {
				message

				token
			}
		}
	`;

	const ForgotPassOtp = gql`
		query ForgotPasswordOtp($email: String!) {
			forgotPasswordOtp(input: { email: $email }) {
				message
				expiresIn
			}
		}
	`;

	const SendingTwoFactOtp = gql`
		query twoFactorOtp($email: String!) {
			twoFactorOtp(input: { email: $email }) {
				message
				expiresIn
				forTask
			}
		}
	`;
	const [code,setGt]=useState()
	const [Part,setChallenge]=useState()
	
	const onSuccess = (isSuccess:any) => {
		console.log(isSuccess);
	}




	const showingModal = (event: any) => {
		event?.preventDefault();
		setShowModal(true);
		TwoFactSendingOTP(event);
	};

	const HideModal = (e:any) => {
		e.preventDefault()
		setShowModal(false);
		setDisable(false)
		setForgotPassModal(false);
	};

	useEffect(() => {
		if (loginInfo.email.length !== 0 && loginInfo.password.length !== 0) {
			setNoError(true);
			setfeildsFilled(true);
		} else {
			setNoError(false);
			setfeildsFilled(false);
		}
	}, [loginInfo]);

	const [doTwoFactAuth, { data: data2 }] = useMutation(TwoFactAuth);

	const [SendTwoFactOTP, { error: error1, data: TwoFactOTP }] = useLazyQuery(SendingTwoFactOtp, {
		variables: { email: loginInfo.email },
	});

	const TwoFactSendingOTP = (event: any) => {
		event?.preventDefault();
		SendTwoFactOTP();
		console.log('fact2 ', data2, error);

	};

	//verifying otp for two fact auth
	const VerifyingTwoFactOTP = (event: any) => {
		event?.preventDefault();
		const Otp = twoFactOtp.toString();
		doTwoFactAuth({
			variables: {
				input: {
					email: loginInfo.email,
					otp: Otp,
				},
			},
		})
			.then((res) => {
				console.log('res', res.data);
				// Navigate the user
				/* @ts-ignore */
				localStorage.setItem('token', JSON.stringify(res.data?.twoFactorVerify.token));
				history.push('/profile');
			})
			.catch((error) => {
				swal(error.message);
//				console.log('error ', error);
			});

	};
	interface LoginMutationResponse {
		token: string;
		message: string;
		expiresIn: string;
		refreshToken: string;
	}
	interface LoginMutationInput {
		input: {
			emailOrUsername: string;
			password: string;
		};
	}

	//login query
	const [doLogin, { data }] = useMutation<LoginMutationResponse, LoginMutationInput>(LOGIN);
	//console.log('data ', data);

	//checking login (main login function)
	const checklogin = (event: any) => {
		if (Feildsfilled && noError) {
			doLogin({
				variables: {
					input: {
						emailOrUsername: loginInfo.email,
						password: loginInfo.password,
					},
				},
			})
				.then((res) => {
					/* @ts-ignore */
					const canCreateCollection= res.data?.login.user.canCreateCollection;
				 /* @ts-ignore */
					console.log("auth", res.data?.login.user.twoFactorAuth)
					dispatch(setCreateCollection(canCreateCollection))
					/* @ts-ignore */
					const twoFactAuth = res.data?.login.user.twoFactorAuth;
					if (twoFactAuth) {
		
							/* @ts-ignore */
							const user = res.data?.login.user.role;
							/* @ts-ignore */
							localStorage.setItem('user', JSON.stringify(user));
							/* @ts-ignore */
							const UserData = res.data?.login.user;
		
							dispatch(Logincheck(UserData));
		
		
						/* @ts-ignore */
						showingModal(event);
		
						} else {
							/* @ts-ignore */
							const user = res.data?.login.user.role;
							/* @ts-ignore */
							localStorage.setItem('user', JSON.stringify(user));
							/* @ts-ignore */
							const UserData = res.data?.login.user;
		                    	/* @ts-ignore */ 
						  const Profileupdated=	res.data?.login.user.isProfileUpdated
							dispatch(Logincheck(UserData));
								/* @ts-ignore */
								const token = res.data?.login.token;
												
							/* @ts-ignore */
							localStorage.setItem('token', JSON.stringify(token));
			
			                if(Profileupdated)
							{
                                   history.push("/builder") 

							}
			else {
							history.push('/profile');
			}
								}
					// Navigate the user
				})
				.catch((error) => {
					swal(error.message);
					console.log('error ', error);
				});
			event.preventDefault();
		}
	};

	//Remember me
	const checkRememberMe = () => {
		setRememberMe(!rememberme);
	};

	//modal for forgot password
	const handleForgotPass = () => {
		setForgotPassModal(true);
		setPassMessage('');
	};
	const [ForgotPassword, { loading, error, data: forgotPassData }] = useLazyQuery(ForgotPassOtp, {
		variables: { email: forgotpassInfo.email },
		errorPolicy: 'all',
			});

	//sending otp on an entered email address
	const sendingOTP = (e:any) => {
		e.preventDefault()
		var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if(forgotpassInfo.email.match(mailformat))
		{
//           console.log("email",forgotpassInfo.email)
			ForgotPassword();
	
		}
		else {

			swal("Please provide an valid email address")
	
		}
			 if( data && data?.message?.length>0) {
			setOTPMessage('OTP sent successfully');
			setTimeout(() => setOTPMessage(''), 4000);
			//			setDisable(true)
		}
	};
	const [doVerifyOtp, { data: verfiyData }] = useMutation(VerifyForgotPassOtp);
	//console.log('data ', data);

useEffect(() => {
		//console.log("forgot",forgotpassInfo)
		if (
			forgotpassInfo.newpassword === forgotpassInfo.confirmnewpassword &&
			forgotpassInfo.newpassword.length >= 8 &&
			forgotpassInfo.confirmnewpassword.length >= 8
		) {
		//	console.log('validate');
			setDisable(true);
		} else {
			setDisable(false);
		//	console.log('invalid');
		}
	}, [forgotpassInfo.newpassword, forgotpassInfo.confirmnewpassword]);

	//Verifying otp and new password
	const handleSentOTP = (e:any) => {
		e.preventDefault()
		if (
			
			forgotpassInfo.newpassword === forgotpassInfo.confirmnewpassword &&
			forgotpassInfo.newpassword.length >= 8 &&
			forgotpassInfo.confirmnewpassword.length >= 8
	
		) {
		//	console.log('pass equal');
			doVerifyOtp({
				variables: {
					input: {
						email: forgotpassInfo.email,
						otp: forgotpassInfo.OTP.toString(),
						password: forgotpassInfo.newpassword,
					},
				},
			})
				.then((res) => {
					console.log('res', res.data);

					swal('password reset succesfully please login ');
				})
				.catch((error) => {
					swal(error.message);
		//			console.log('error ', error);
				});
		} else {
			swal('password doesnt match');
		}
	};
	/* @ts-ignore */

	const [doGoogleLogin, { data: googleResponse }] = useMutation(GLogin);

	//TODO = manage admin section disable/enable part by using redux (currently we are doin it vai local storage token)
	//Log in with google Function
	const responseGoogle = (response: any) => {
		if (!response.Zb.access_token)
			swal('Error with Google Login, Please try signing in other options');
		const token = response.Zb.access_token;
		const email = response.profileObj.email;
		const name = response.profileObj.name;
		const firstName = response.profileObj.givenName;
		const lastName = response.profileObj.familyName;
		const LoggedInWithGoogle = true;
		const profileImageUrl = response.profileObj.imageUrl;

		//localStorage.setItem('email', JSON.stringify(response.profileObj.email));
		const data = {
			token,
			email,
			profileImageUrl,
			name,
			LoggedInWithGoogle,
		};
		//  dispatch(saveGoogleData(data))
		dispatch(Logincheck(data));

		doGoogleLogin({
			variables: {
				input: {
					//firstName:firstName,
					//lastName:lastName,
					email: email,
					imageUrl: profileImageUrl,
					accessToken: token,
					name: name,
				},
			},
		})
			.then((res) => {
				/* @ts-ignore */
 console.log("",res?.data.googleLogin.user)
				const user = res?.data.googleLogin.user.role;

				/* @ts-ignore */
				localStorage.setItem('user', JSON.stringify(user));

				localStorage.setItem('token', JSON.stringify(token));
				history.push('/profile');
			})
			.catch((error) => {
				swal(error.message);
				console.log('error ', error);
			});
	};

	// google login error function
	const errorResponseGoogle = (response: any) => {
		console.log('Failure');
		// console.log("error", response);
		setOtherError('Error occured Please try again');
		setTimeout(() => setOtherError(''), 5000);
	};
	return (
		<Container>
			
			<LoginFormRight>
				<LoginText>Login</LoginText>

				<Label>Email address</Label>
				<Input
					type='email'
					value={loginInfo.email}
					onChange={(e) => setLoginInfo({ ...loginInfo, email: e.target.value })}
					placeholder='Type your Email'
				//	required
				/>
				<Label>Password</Label>
				<Input
					type='password'
					value={loginInfo.password}
					placeholder='Type your Password'
					onChange={(e) => setLoginInfo({ ...loginInfo, password: e.target.value })}
				//	required
				/>

				<Checkalign>
					<CheckBox type='checkbox' id='rememberMe' onClick={checkRememberMe} />
					<RememberLabel> {"  "}&nbsp;

Remember me</RememberLabel>
				</Checkalign>

				{Feildsfilled && noError ? (
					<Button onClick={checklogin}>Login</Button>
				) : (
					<InactiveBTN style={{ width: '90%' }}>Sign in</InactiveBTN>
				)}
				{(message || otherError) && (
					<Label
						style={{
							color: 'red',
							fontSize: '12px',
							height: '10px',
							paddingTop: '10px',
						}}
					>
						{message || otherError}
					</Label>
				)}
				
				<ExtraLinks>
				</ExtraLinks>
			</LoginFormRight>
		</Container>
	);
};

export default Login;
