import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TopBar from "./TopBar";
import { IMG, Label, BTNimg } from "./style";
import ImageUpload from "./asserts/Image-Upload.png";
import ChooseFromUpload from "./asserts/Choose-from-library.png";
import Button from "@mui/material/Button";
import upload from "../../../../assets/icons/upload.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Squared from "../../../../assets/image/Squared.png";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import swal from "sweetalert";
import { Profilepicinput } from "../ProfileManagement/style";
import { useDispatch, useSelector } from "react-redux";

import { useInView } from "react-hook-inview";
import {
  processingLibrarySelectedImage,
  UseProccessedImage,
} from "../../../../logic/actions/wallet";
import CircularProgress from "@mui/material/CircularProgress";
import Abstract from "./../../../../category_files/Abstract.jpg";
import Anime from "./../../../../category_files/Anime.jpg";
import Archillect from "./../../../../category_files/Archillect.jpg";
import ArtworkModel from "./../../../../category_files/ArtworkModel.jpg";
import DisneyCartoonFaces from "./../../../../category_files/DisneyCartoonFaces.jpg";
import Fursona from "./../../../../category_files/Fursona.jpg";
import Humanfaces from "./../../../../category_files/Humanfaces.jpg";
import Landscape from "./../../../../category_files/Landscape.jpg";
import LandscapeOilPainting from "./../../../../category_files/LandscapeOilPainting.jpg";
import MetalAlbumArt from "./../../../../category_files/MetalAlbumArt.jpg";
import Modernartmodel from "./../../../../category_files/Modernartmodel.jpg";
import NewAbstractArtModel from "./../../../../category_files/NewAbstractArtModel.jpg";
import NewArtModel from "./../../../../category_files/NewArtModel.jpg";
import NightSkyAdaModel from "./../../../../category_files/NightSkyAdaModel.jpg";
import Paintingfacesmodel from "./../../../../category_files/Paintingfacesmodel.jpg";
import Poniesmodel from "./../../../../category_files/Poniesmodel.jpg";
import Scifiartmodel from "./../../../../category_files/Scifiartmodel.jpg";
import Texturesmodel from "./../../../../category_files/Texturesmodel.jpg";
import VisionaryArtModel from "./../../../../category_files/VisionaryArtModel.jpg";
import Wikiartmodel from "./../../../../category_files/Wikiartmodel.jpg";
import { SpinningLoader } from "../../../../shared/components/Loader/SpinningLoader";

interface Props {
  setIsOpen?: () => void;
  setOrder: (orderNum: number) => void;
  order: number;
}
const UploadImagePopUp = ({ setIsOpen, order, setOrder }: Props) => {
  const [currentState, setCurrentState] = useState(0);
  const [collectionImage, setCollectionImage] = useState<string>("");
  const {
    createCollectionData,
    canCreateCollection,
    loginData,
    processedImage,
    LibraryImageData,
  } = useSelector((state: any) => state.wallet);
  const [UploadedImage, setUploadedImage] = useState("");
  const dispatch = useDispatch();
  const [SelectedLibImage, setSelectedLibImage] = useState("");
  const [selectedModal, setSelectedModal] = useState();
  const [libraryImageChoose, setLibraryImageChoose] = useState("");
  const [checkedModal, setCheckedModal] = useState(false);
  const ListLibraryImages = gql`
    query ListLibrary(
      $sortOrder: Int!
      $limit: Int!
      $offset: Int!
      $modelId: String
    ) {
      listLibrary(
        input: {
          sortOrder: $sortOrder
          limit: $limit
          offset: $offset
          modelId: $modelId
        }
      ) {
        count
        images {
          _id
          name
          imageURL
          status
          modelId
        }
      }
    }
  `;
  const [confirmImage, setConfirmImage] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [imageName, setImageName] = useState("");

  const [imageID, setImageID] = useState("");
  const [jobId, setJobId] = useState();
  const [ProcessedImage, setProcessedImage] = useState("");
  const [progress, setProgress] = useState("");
  const [processedSeedId, setPrcessedSeedId] = useState("");
  const [timeoutState, setTimeoutState] = useState([]);
  const [showStatus, setShowStatus] = useState(true);
  const [ImageInformation, { error: ImageError, data: ImageData }] =
    useLazyQuery(ListLibraryImages, {
      variables: {
        offset: 0,
        limit: 100,
        modelId: imageID,
        sortOrder: 1,
      },
    });
  const handleCheckBoxChange = (e: any) => {
    setSelectedModal(e.target.value);
    setCheckedModal(!checkedModal);
  };
  const LibararyImageData = ImageData?.listLibrary.images;
  useEffect(() => {
    ImageInformation();
  }, []);
  var timeouts: any = [];

  const Appleid = process.env.REACT_APP_APPLE_ID;
  const statusCheck = () => {
    //event.preventDefault()
    const data = JSON.stringify({ job_id: jobId });

    if (jobId != null)
      var config1 = {
        method: "post",
        url: "https://stylegan.rapidinnovation.tech/result",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
    //@ts-ignore
    axios(config1)
      .then((response) => {
        setProgress(response.data.status);
        setShowStatus(false);
        if (response.data.status === "PROGRESS") {
          setTimeout(() => {
            statusCheck();
          }, 10000);
        } else {
          clearTimeout();
        }
        setProcessedImage(response.data.object_url);
        const data = [
          {
            Pimageurl: response.data.object_url,
            UploadedImageUrl: UploadedImage,
            seedId: processedSeedId,
            modalId: imageID,
          },
        ];

        if (confirmImage) {
          dispatch(UseProccessedImage(data));
        }
      })
      .catch(function (error) {
        clearTimeout();
        swal("Internal server error, try again later!");
        console.log(error);
      });
  };

  const imageCheckbox = (name: any, path: any, id: any) => {
    setImageName(name);
    setImagePath(path);
    setImageID(id);
  };

  const image: any = [
    { id: "metal_album_art_512", file: MetalAlbumArt, name: "Metal Art" },
    {
      id: "landscape_oil_painting_1024",
      file: LandscapeOilPainting,
      name: "Landscape Oil Painting Model",
    },
    { id: "abstract_art_256", file: Abstract, name: "Abstract" },
    { id: "archillect_1024", file: Archillect, name: "Archillect" },
    { id: "artwork_512", file: ArtworkModel, name: "Art World" },
    {
      id: "disney_cartoon_faces_1024",
      file: DisneyCartoonFaces,
      name: "Disney faces",
    },
    { id: "fursona_512", file: Fursona, name: "Fursona" },
    { id: "human", file: Humanfaces, name: "Human faces" },
    { id: "landscapes_ada_256", file: Landscape, name: "Landscape" },
    { id: "modern_art_512", file: Modernartmodel, name: "Modern ARt" },
    {
      id: "new_abstract_art_1024",
      file: NewAbstractArtModel,
      name: "New Abstracrt",
    },
    { id: "new_art_1024", file: NewArtModel, name: "New Art" },
    {
      id: "painting_metfaces_1024",
      file: Paintingfacesmodel,
      name: "Painting faces",
    },
    { id: "anime_512", file: Anime, name: "Anime" },
    { id: "night_sky_1024", file: NightSkyAdaModel, name: "Night sky" },
    { id: "ponies_1024", file: Poniesmodel, name: "Poniesmodel" },
    { id: "scifi_1024", file: Scifiartmodel, name: "Scifiartmodel" },
    { id: "texture_1024", file: Texturesmodel, name: "Texturesmodel" },
    {
      id: "visionary_art_1024",
      file: VisionaryArtModel,
      name: "VisionaryArtModel",
    },
    { id: "wikiart_1024", file: Wikiartmodel, name: "Wikiartmodel " },
  ];
  const [show, setShow] = useState("");

  useEffect(() => {
    if (currentState === 1 && order === 1) {
      if (imageName === "") {
        setShow("none");
      } else {
        setShow("block");
      }
    }
    if (currentState === 1 && order === 2) {
      if (imageName === "") {
        setShow("none");
      } else {
        setShow("block");
      }
    }
  }, [currentState, imageName]);

  const [showProgress, setShowProgress] = useState("none");
  const handleImageCompare = (selectedModal: any) => {
    let a = new Date().getTime().toString();
    setPrcessedSeedId(a);
    var data = JSON.stringify({
      raw_image_url: UploadedImage,
      collection_id: a,
      model_id: imageID,
    });
    var config = {
      method: "post",
      url: "https://stylegan.rapidinnovation.tech/rawImageUpload",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //@ts-ignore
    axios(config)
      .then(function (response) {
        console.log(response);
        if (response.data.Success != false) {
          setJobId(response.data.job_id);
        } else {
          swal(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        swal("Internal server error, try again later!");
      });
  };

  useEffect(() => {
    if (
      (order === 1 && currentState === 2) ||
      (order === 1 && currentState === 3)
    ) {
      statusCheck();
    }
  }, [jobId]);

  const [ref, isVisible] = useInView({
    threshold: 0,
  });

  const [libraryInviewData, setLibraryInviewData] = useState([]);

  const [viewCount, setViewCount] = useState(10);

  useEffect(() => {
    const temp = ImageData?.listLibrary?.images;
    if (temp?.length) setLibraryInviewData(temp?.slice(0, viewCount) || []);
  }, [viewCount, ImageData?.listLibrary.images]);
  useEffect(() => {
    setViewCount((state) => state + 10);
  }, [isVisible]);

  const onChangeAttribute = (value: any, Oldindex: any) => {
    const selectedItems = LibararyImageData?.filter(
      (item: any, index: any) => index === Oldindex
    );
    setSelectedLibImage(selectedItems[0].imageURL);
    const imageData = [
      {
        imageUrl: selectedItems[0].imageURL,
        seed: selectedItems[0].name,
        id: selectedItems[0]._id,
        status: selectedItems[0].status,
        modalid: selectedItems[0].modelId,
      },
    ];

    dispatch(processingLibrarySelectedImage(imageData));
  };

  //handling image upload
  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;
    if (!fileList) return;
    const image = URL.createObjectURL(fileList[0]);
    setCollectionImage(image);
    setShow("none");
    setShowProgress("block");

    /* @ts-ignore */
    const userData: string = JSON.parse(localStorage.getItem("token"));
    var formData = new FormData();
    /* @ts-ignore */
    formData.append("image", fileList[0]);
    axios
      .post(process.env.REACT_APP_BaseURl + "/upload/image", formData, {
        headers: {
          Authorization: `Token ${userData}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status == 201) {
          setUploadedImage(response.data.data.profileImageUrl);
          setShowProgress("none");
          setShow("block");
        }
      })
      .catch((error) => {
        swal("error in image upload");
      });
  };

  //UI
  if (currentState === 0)
    return (
      <Grid container>
        {/* <Grid item md={5}>
          <h2>Upload your image</h2>
          <div
            style={{
              width: "100%",
              height: "88%",
              backgroundColor: "#ededed",
              outline: "2px dashed #dee5ef",
              cursor: "pointer",
            }}
            onClick={() => {
              setOrder(1);
              setCurrentState(1);
            }}
          >
            <img src={ImageUpload} alt="Art Upload" style={{ margin: "25%" }} />
          </div>
        </Grid> */}
        {/* <Grid item md={2} style={{ margin: "auto" }}> */}
        <Grid item md={2}></Grid>
        <Grid
          item
          md={8}
          onClick={() => {
            setOrder(2);
            setCurrentState(1);
          }}
        >
          <h2>Choose from library</h2>
          <IMG
            src={ChooseFromUpload}
            alt="Image Upload"
            style={{ cursor: "pointer" }}
            onClick={() => ImageInformation()}
          />
        </Grid>
      </Grid>
    );
  if (currentState === 1 || (order === 1 && currentState === 2))
    return (
      <Grid container>
        <Grid item md={12}>
          <TopBar order={order} activeState={currentState} />
        </Grid>
        <Grid
          item
          container
          style={{
            border: "1px solid lightgrey",
            borderRadius: "4px",
            padding: "0 0 15px 8px",
            overflowY: "scroll",
          }}
        >
          <div style={{ height: "450px" }}>
            {currentState === 2 ? (
              <h5>
                <br />
                Category:{imageName}
                <div>
                  <img
                    src={imagePath}
                    style={{
                      width: "350px",
                      height: "400px",
                    }}
                    alt="No image"
                  ></img>
                </div>
              </h5>
            ) : (
              <div>
                {image.map((data: any) => {
                  return (
                    <div
                      style={{
                        display: "inline-grid",
                      }}
                    >
                      <h5>
                        <Checkbox
                          value={data.name}
                          checked={imageName == data.name}
                          onChange={() =>
                            imageCheckbox(data.name, data.file, data.id)
                          }
                          id={data.name}
                        />
                        <b>{data.name}</b>
                      </h5>
                      <label htmlFor={data.name}>
                        {" "}
                        <img
                          src={data.file}
                          style={{
                            width: "350px",
                            height: "400px",
                            cursor: "pointer",
                          }}
                          alt="No image"
                        />
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <Grid item md={6}>
            {currentState === 2 ? (
              <div style={{ position: "relative" }}>
                <br />
                <h5>Upload Image</h5>
                <div
                  style={{
                    width: "400px",
                    height: "400px",
                    marginLeft: "15%",
                    border: "1px solid black",
                  }}
                >
                  {collectionImage !== "" ? (
                    <img
                      src={collectionImage}
                      style={{
                        width: "398px",
                        height: "398px",
                        // marginLeft: "70px",
                        marginBottom: "20px",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <Profilepicinput
                    type="file"
                    id="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                  <Label htmlFor="file" style={{ marginLeft: "70px" }}>
                    <BTNimg src={upload} />
                    Upload
                  </Label>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "10px" }}
        >
          <Grid item md={4}>
            <Button variant="outlined" fullWidth onClick={setIsOpen}>
              Cancel
            </Button>
          </Grid>
          <Grid item md={5}>
            <div style={{ display: showProgress }}>
              <CircularProgress
                style={{ width: "30px", marginLeft: "100px" }}
              />
              <b>Uploading Image...</b>
            </div>

            <Button
              style={{ display: show }}
              variant="contained"
              fullWidth
              onClick={() => {
                if (currentState == 2) {
                  handleImageCompare(selectedModal);
                }
                setCurrentState(currentState === 1 ? 2 : 3);
                setShow("none");
                setShowProgress("none");
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  if (order === 2 && currentState === 2)
    return (
      <Grid container>
        <Grid item md={12}>
          <TopBar order={order} activeState={currentState} />
        </Grid>

        <Grid
          item
          md={12}
          style={{
            height: "500px",
            border: "1px solid lightgrey",
            borderRadius: "4px",
            padding: "0 0 20px 10px",
            overflowY: "scroll",
          }}
        >
          {libraryInviewData?.map((item: any, index: any) => (
            <>
              <div
                {...{ ref: viewCount === index + 1 ? ref : null }}
                style={{ display: "inline-grid" }}
              >
                <h2>
                  <Checkbox
                    checked={libraryImageChoose == item.name}
                    onChange={(event) => {
                      setLibraryImageChoose(item.name);
                      onChangeAttribute(event, index);
                    }}
                    name="myCheckbox"
                    id={item.name}
                  />
                  <b>{item.name}</b>
                </h2>
                <label htmlFor={item.name}>
                  <img
                    style={{
                      width: "350px",
                      height: "400px",
                      padding: "2px",
                      cursor: "pointer",
                    }}
                    src={item.imageURL || Squared}
                  />
                </label>
              </div>
            </>
          ))}
        </Grid>

        <Grid
          item
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "10px" }}
        >
          <Grid item md={4}>
            <Button variant="outlined" fullWidth onClick={setIsOpen}>
              Cancel
            </Button>
          </Grid>
          <Grid item md={4}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                setCurrentState(3);
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  if (order === 1 && currentState === 3)
    return (
      <Grid container>
        <Grid item md={12}>
          <TopBar order={order} activeState={currentState} />
        </Grid>
        <Grid
          item
          container
          style={{
            border: "1px solid lightgrey",
            borderRadius: "4px",
          }}
        >
          <Grid item md={6}>
            <h5>Uploaded Image</h5>
            <img
              src={collectionImage || Squared}
              alt="Upload image"
              width="80%"
            />
          </Grid>
          <Grid item md={6}>
            <h5>Processed Image</h5>
            {ProcessedImage ? (
              <img
                src={ProcessedImage || Squared}
                alt="Processed image"
                width="80%"
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h5 style={{ paddingTop: "10px" }}>
                  Creating processed image will take about 3-4 minutes.
                </h5>
                <br />
                <br />

                <br />
                <div>
                  <SpinningLoader message="Processing image..." />
                </div>
              </div>
            )}
          </Grid>
          <Grid style={{ textAlign: "left", marginLeft: "40px" }}>
            <br />
            <Checkbox
              onChange={(e) => {
                setConfirmImage(e.target.checked);
              }}
              checked={confirmImage}
            />
            I confirm that the above two look images similar.
            <br />
            <span style={{ marginLeft: "45px" }}>
              If not, I need to revisit the process or try with another image
              else AI might not be beneficial to this collection.
            </span>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "10px" }}
        >
          <Grid item md={4}>
            <Button variant="outlined" fullWidth onClick={setIsOpen}>
              Cancel
            </Button>
          </Grid>

          <Grid item md={4}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                statusCheck();
                //@ts-ignore
                setIsOpen();
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  if (order === 2 && currentState === 3)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={12}>
          <TopBar order={order} activeState={currentState} />
        </Grid>
        <Grid item md={6}>
          <h2>Selected Image</h2>
          <IMG src={SelectedLibImage || Squared} />
        </Grid>
        <Grid
          item
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "30px" }}
        >
          <Grid item md={4}>
            <Button variant="outlined" fullWidth onClick={setIsOpen}>
              Cancel
            </Button>
          </Grid>
          <Grid item md={4}>
            <Button variant="contained" fullWidth onClick={setIsOpen}>
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  else return <> </>;
};

export default UploadImagePopUp;
