import React, { FC } from 'react';
import styled from 'styled-components';
import { H1 } from '../Claimvrynt/style';
import { BTN, BTN2 } from '../Collection2/style';
import BidImage from '../../../../assets/icons/bid.png';
import close from '../../../../assets/icons/close.svg';
import { Span } from '../Gallery2/style';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export const PopUpOverlay = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
height: 240vh;
width: 100%;
background: rgba(0,0,0,0.5);
position: absolute;
top: 0;
left: 0;
z-index: 2;
*{
    /* border: 2px solid black; */
}
`

export const Popup = styled.div`
margin-top: 20vh;
position: relative;
width: 550px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
background-color: white;
border-radius: 5px;
padding: 40px 30px;
`

export const ContentDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
margin-top:-3%;
`

export const Close = styled.img`
position: absolute;
top: 20px;
right: 26px;
cursor: pointer;

`

const Heading = styled.h1`

font-family: Source Sans Pro;
font-style: normal;
font-weight: 600;
font-size: 27px;
letter-spacing: -0.01em;
color: #000000;

`

export const PopUpContent = styled.p`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 146%;
/* or 20px */
text-align: center;
color: #000000;
`
const BTN4 = styled.button
    `align-items: center;
padding: 11px 16px;
width: 227px;
height: 40px;
left: 611px;
top: 500px;
margin-top:2%;
background: #35BCFF;
border-radius: 1px;

`
export const LineHR = styled.hr`
left: 31.25%;
right: 31.25%;
top: 28.49%;
bottom: 71.51%;
/* Grey / 2 */
border: 4px solid #DEE5EF;`


interface Bidprop {
    Showornot: string;
    statechanger: any;
}

const Bid =() => {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };
    const handleClickAway = () => {
        setOpen(false);
      };
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
       {open ? (
        <PopUpOverlay>
            <Popup>
                <img style={{ width: '70px', height: '70px' }} src={BidImage} alt="" />
                <Heading>Bid on Component </Heading>
                <ContentDiv>
                    <PopUpContent>Select a component to Bid on</PopUpContent>
                    <LineHR
                        style={{
                            border: "1px solid #DEE5EF",
                            width: "100%"
                        }} />
                    <BTN2
                        style={{ background: "#35BCFF", height: "40px", width: "50%", margin: "2%" }}
                    >
                        Ok
                    </BTN2>
                </ContentDiv>

                <Close onClick={ handleClick} src={close} alt="" />
            </Popup>
        </PopUpOverlay>
          ) : null}
        </ClickAwayListener>
    )
}

export default Bid
