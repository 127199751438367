import {
	GET_BALANCE,
	LOGIN_CHECK,
	SIGNUP_CHECK,
	CREATE_COLLECTION,
	PROFILE_EDIT,
	AUTH_TOKEN,
	SEND_EMAIL_OTP,
	OTP_VERIFICATION,
	GOOGLE_LOGIN_INFO,
	ERROR_MESSAGE,
	CREATE_COLLECTION_STATE,
	PROCESSED_IMAGE_URL,
	LIBRARY_IMAGE_DATA,
} from '../actions/constants';

type State = {
	amount: number;
	loginData: string[];
	signUpData: string[];
	createCollectionData: object;
	profileData: object;
	authtoken: string;
	otpMessage: string;
	Message: string;
	googleUserdata: [];
	errorMessage: string;
	canCreateCollection:string;
	processedImage:[],
	LibraryImageData:[]
};
const initialState: State = {
	amount: 0,
	processedImage:[],
	signUpData: [],
	loginData: [],
	createCollectionData: [],
	profileData: [],
	authtoken: '',
	otpMessage: '',
	Message: '',
	googleUserdata: [],
	errorMessage: '',
	canCreateCollection:"",
	LibraryImageData:[]
};

const walletReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case GET_BALANCE:
			return {
				...state,
				amount: action.amount,
			};
		case LOGIN_CHECK: {
			return {
				loginData: action.payload,
			};
		}
		case SIGNUP_CHECK: {
			return {
				signUpData: action.payload,
			};
		}
		case CREATE_COLLECTION: {
			console.log('reducer', action.payload);
			return {
				createCollectionData: action.payload,
			};
		}
		case PROFILE_EDIT: {
			return {
				profileData: action.payload,
			};
		}
		case AUTH_TOKEN: {
			return {
				authtoken: action.payload,
			};
		}
		case OTP_VERIFICATION: {
			return {
				Message: action.payload,
			};
		}
		case GOOGLE_LOGIN_INFO: {
			return {
				googleUserdata: action.payload,
			};
		}
		case ERROR_MESSAGE: {
			return {
				errorMessage: action.payload,
			};
		}

		case CREATE_COLLECTION_STATE: {
			console.log("create collection reducer",action.payload)
			return {
				canCreateCollection: action.payload,
			};
		}
		case PROCESSED_IMAGE_URL: {
			console.log("create collection reducer",action.payload)
			return {
				processedImage: action.payload,
			};
		}

		case LIBRARY_IMAGE_DATA: {
			console.log("create collection reducer",action.payload)
			return {
				LibraryImageData: action.payload,
			};
		}


		default:
			return state;
	}
};

export default walletReducer;
