import React, { useState, useEffect } from 'react';
import { FormData, FormImage, Title, Label } from './style';
import { InactiveBTN } from '../ProfileManagement/style';
import GoogleLogin from 'react-google-login';
import swal from 'sweetalert';
import GoogleButton from 'react-google-button';
import Applebutton from '../Login/Applesigninbutton';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import {
	LoginFormLeft,
	LoginFormRight,
	Checkalign,
	ForgotPassLink,
	ExtraLinks,
	Img,
	Input,
	LoginText,
	Button,
	CheckBox,
	RememberLabel,
	NotaMember,
	Image,
	ButtonAuth,
	OR,
	Auth,
	AppleButton,
	Container,
} from '../Login/style';

import placeholder from '../../../../assets/image/placeholder.jpg';
import apple from '../../../../assets/icons/Apple.svg';
import Google from '../../../../assets/icons/Google.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Logincheck, saveGoogleData, signupcheck } from '../../../../logic/actions/wallet';
import history from '../history';
import { BaseURl } from '../../../../utils/AccessPoints/Endpoints';
import axios from 'axios';
const SignUp = () => {
	const [signupInfo, setSignUpInfo] = useState({
		email: '',
		password: '',
		confirmpassword: '',
	});
	const [checkbox, setCheckBox] = useState('');
	const [noError, setNoError] = useState<boolean>(true);
	const dispatch = useDispatch();
	const [isSignUp, setIsSignup] = useState(false);
	const [Feildsfilled, setfeildsFilled] = useState(false);
	const { signUpData } = useSelector((state: any) => state.wallet);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [TermsCheckbox,setTermsChecbox]=useState(false)
	const clientId = process.env.REACT_APP_CLIENT_ID;
	const [googleuseInfo, setGoogleUserInfo] = useState({
		email: '',
		name: '',
		image: '',
		token: '',
	});
	const [passErrorMessage, setPassErrorMessage] = useState('');
	const [otherError, setOtherError] = useState<string>('');
	//checking password
	const checkpass = (event: any) => {
		if (signupInfo.password !== signupInfo.confirmpassword) {
			event.preventDefault();
			setNoError(false);
			setPassErrorMessage("Password doesn't match");
			setTimeout(() => setPassErrorMessage(''), 3000);
			return false;
		} else return true;
	};

	const handleCheckBoxSelection=()=>{
      
		setTermsChecbox(!TermsCheckbox)		

	}
	const GLogin = gql`
		mutation GLogin($input: GoogleLoginDto!) {
			googleLogin(input: $input) {
				token
				expiresIn
				refreshToken
				message
			}
		}
	`;

	const SignUpCheck = gql`
		mutation CreateUser($input: CreateUserDto!) {
			createUser(input: $input) {
				token
				message
			}
		}
	`;

	const [doLogin, { data }] = useMutation(SignUpCheck);

	//main sign up function
	const handleSignUp = (event: any) => {
		event.preventDefault();
		
		if(TermsCheckbox===false)
		{
          swal("Please tick on terms and condition to proceed")

		}
		else if
		 (Feildsfilled && noError) {
			const passcheckStatus = checkpass(event);
			if (passcheckStatus === true) {
				doLogin({
					variables: {
						input: {
							email: signupInfo.email,
							password: signupInfo.password,
						},
					},
				})
				
					.then((res) => {
						console.log('res', res.data);

						/* @ts-ignore */
						localStorage.setItem('token', JSON.stringify(res.data?.createUser.token));
						history.push('/profile');
					})
					.catch((error) => {
						swal(error.message);
						console.log('error ', error);
					});
			
			}
			else {

				swal("Password doesn't match")
			}
		}
	};

	useEffect(() => {
				if (
			signupInfo.email.length !== 0 &&
			signupInfo.password.length !== 0 &&
			signupInfo.confirmpassword.length !== 0  
				) {
			setNoError(true);
			setfeildsFilled(true);
		} else {
			setNoError(false);
			setfeildsFilled(false);
		}
	}, [signupInfo,TermsCheckbox]);

	//switching to sign up if user already has an account
	const handleLogin = (event: any) => {
		event.preventDefault();
		history.push('/login');
	};
	const [doGoogleLogin, { data: googleResponse }] = useMutation(GLogin);

	//sign up with google
	const responseGoogle = (response: any) => {
		if (!response.Zb.access_token)
		swal('Error with Google Login, Please try signing in other options');
	const token = response.Zb.access_token;
	const email = response.profileObj.email;
	const name = response.profileObj.name;
	const firstName = response.profileObj.givenName;
	const lastName = response.profileObj.familyName;
	const LoggedInWithGoogle = true;
	const profileImageUrl = response.profileObj.imageUrl;


		localStorage.setItem('email', JSON.stringify(response.profileObj.email));
		const data = {
			token,
			email,
			profileImageUrl,
			name,
			LoggedInWithGoogle,
		};
		//  dispatch(saveGoogleData(data))
		dispatch(Logincheck(data));

		doGoogleLogin({
			variables: {
				input: {
					//firstName:firstName,
					//lastName:lastName,
					email: email,
					imageUrl: profileImageUrl,
					accessToken: token,
					name: name,
				},
			},
		})
			.then((res) => {
				localStorage.setItem('token', JSON.stringify(token));
				history.push('/profile');
			})
			.catch((error) => {
				swal(error.message);
				console.log('error ', error);
			});
	};
	const errorResponseGoogle = (response: any) => {
		setOtherError('Network error please try again');
		setNoError(false);
		setTimeout(() => setOtherError(''), 5000);
	};

	return (
		<Container onSubmit={handleSignUp}>
			<LoginFormLeft>
				<Auth>
					<GoogleLogin
						/* @ts-ignore */
						clientId={clientId}
						render={(renderProps) => (
							<ButtonAuth onClick={renderProps.onClick}>
								<Image src={Google} alt='' />
								Continue with Google
							</ButtonAuth>
						)}
						onSuccess={responseGoogle}
						approvalPrompt='force'
						prompt='consent'
						onFailure={errorResponseGoogle}
						cookiePolicy={'single_host_origin'}
					/>
					<br />
					<Applebutton />
				</Auth>
			</LoginFormLeft>
			<LoginFormRight>
				<LoginText>Sign up</LoginText>

				<Label>Email address</Label>
				<Input
					style={{ marginBottom: '7px' }}
					type='email'
					value={signupInfo.email}
					placeholder='Enter your Email'
					onChange={(e) => setSignUpInfo({ ...signupInfo, email: e.target.value })}
					required
				/>
				<Label
					style={{ color: 'red', fontSize: '12px', height: '5px', marginBottom: '10px' }}
				>
					{errorMessage ? errorMessage : ''}
				</Label>

				<Label>Password</Label>
				<Input
					type='password'
					value={signupInfo.password}
					placeholder='Type your password'
					minLength={8}
					onChange={(e) => setSignUpInfo({ ...signupInfo, password: e.target.value })}
					required
				/>
				<Label>Confirm password</Label>
				<Input
					type='password'
					value={signupInfo.confirmpassword}
					placeholder='Type your password'
					onChange={(e) => {
						setSignUpInfo({ ...signupInfo, confirmpassword: e.target.value });
						checkpass(e);
					}}
					required
				/>
				<Label
					style={{ color: 'red', fontSize: '12px', height: '5px', marginBottom: '10px' }}
				>
					{passErrorMessage ? passErrorMessage : ''}
				</Label>
				<Checkalign>
					<CheckBox
				onChange={handleCheckBoxSelection}
				type='checkbox'
						id='rememberMe'
				//		required
					/>
					<RememberLabel>
					{' '}&nbsp;

			Confirm {' '}
						<span>
							{' '}
							<a
								href='terms and conditions'
								style={{ color: '#BD60FE', textDecoration: 'underline' }}
							>
								{' '}
								Terms and conditions
							</a>
						</span>{' '}
					</RememberLabel>
				</Checkalign>

				{Feildsfilled && noError ? (
					<Button>Sign up</Button>
				) : (
					<InactiveBTN style={{ width: '90%' }}>Sign up</InactiveBTN>
				)}
				<Label
					style={{ color: 'red', fontSize: '12px', height: '10px', paddingTop: '10px' }}
				>
					{otherError}
				</Label>
				<ExtraLinks>
					<NotaMember>
						Already have an account? {"  "}
						<span>
							<a
								style={{ color: '#BD60FE', textDecoration: 'none' }}
								href='signup'
								onClick={handleLogin}
							>
								 Log In
							</a>
						</span>
					</NotaMember>
				</ExtraLinks>
			</LoginFormRight>
		</Container>
	);
};

export default SignUp;
