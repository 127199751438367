import loadingImage from "../../../assets/image/loading.svg";
import styled, { keyframes } from "styled-components";
import React from "react";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    animation: ${rotate} 1s linear infinite;
  }
  p {
    text-align: center;
    margin-top: 16px;
  }
`;

interface IProps {
  containerStyle?: object;
  imgStyle?: object;
  message?: string;
}

export const SpinningLoader = ({
  containerStyle,
  imgStyle,
  message,
}: IProps) => {
  return (
    <Container style={containerStyle}>
      <img src={loadingImage} style={imgStyle} alt="spinner" />
      {message ? <p>{message}</p> : null}
    </Container>
  );
};
