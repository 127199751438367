import React from 'react';
import { Gradientborder } from '../../Claimvrynt/style';
import { CreditTitle } from './style';
import CustomToggle from '../../../../../shared/components/Toggle';
import swal from 'sweetalert';

const ToggleContainer = ({
	isActive = false,
	handleChange = () => {
		console.error('!!! "handleChange" Not Provided to ToggleContainer !!!');
		swal('"handleChange" Not Provided to ToggleContainer');
	},
	title = 'TITLE NOT PROVIDED',
	textOnRightWithoutContainer = false,
}) =>
	textOnRightWithoutContainer ? (
		<div style={{ display: 'flex' }}>
			<CustomToggle
				isActive={isActive}
				handleChange={handleChange}
			/>
			<CreditTitle style={{ fontSize: '18px' }}>{title}</CreditTitle>
		</div>
	) : (
		<Gradientborder
			style={{
				width: '380px',
				height: '20px',
				marginTop: '3%',
				marginBottom: '3%',
			}}
		>
			<CreditTitle>{title}</CreditTitle>
			<CustomToggle isActive={isActive} handleChange={handleChange} />
		</Gradientborder>
	);

export default ToggleContainer;
