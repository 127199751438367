import React, { useState, useEffect } from "react";
// import logo from "../../../../assets/icons/vrynt.svg";
import bell from "../../../../assets/icons/bell.svg";

import logo from "../../../../assets/image/logo-new.svg";
import wallet from "../../../../assets/icons/wallet.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  Parentdiv,
  Navdiv,
  NavFirst,
  Logoimg,
  NavSecond,
  NavSearchbar,
  Navtext,
  Bellimg,
  Userimg,
  Flexdiv,
  Flexdiv2,
  Dropdown,
  Mobileimg,
  Mobilenavsecond,
  Burgericon,
  Sidebar,
  Sidebaroverlay,
  Sidebarclose,
} from "./style";

import menuburger from "../../../../assets/icons/menuburger.svg";
import close from "../../../../assets/icons/close.svg";
import logout from "../../../../assets/icons/logout.svg";
import { Flex } from "../Claimvrynt/style";
import { gql, useQuery } from "@apollo/client";

function Navbar() {
  const { loginData, profileData } = useSelector((state: any) => state.wallet);
  const [showPlatform, setShowPlatform] = useState(false);
  const [Showcredit, setShowCredit] = useState(false);
  const [Navshow, setNavshow] = useState<string>("none");
  const [Sidebarshow, setSidebarshow] = useState<string>("none");
  /* @ts-ignore */
  const userData: string = JSON.parse(localStorage.getItem("token"));
  /* @ts-ignore */
  const user: string = JSON.parse(localStorage.getItem("user"));
  // const UserData: any = gql`
  //   query {
  //     getLoggedInUser {
  //       first_name
  //       last_name
  //       email
  //       username
  //       socialDiscord
  //       status
  //       socialTwitter
  //       socialYoutube
  //       socialInstagram
  //       socialTwitch
  //       socialTiktok
  //       socialTelegram
  //       _id
  //       isEmailVerified
  //       mobile
  //       kyc_status
  //       twoFactorAuth
  //       profileImageUrl
  //     }
  //   }
  // `;
  // const { error: erros, data: Userdata } = useQuery(UserData);
  // console.log("navbar", Userdata);
  const history = useHistory();

  const handleLogOut = () => {
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  };

  history.listen((location, action) => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      setNavshow("flex");
    } else {
      setNavshow("none");
    }
    setSidebarshow("none");
  });

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (token !== null) {
  //     setNavshow("flex");
  //   } else {
  //     setNavshow("none");
  //   }
  // }, []);

  return (
    <>
      <Parentdiv>
        <Navdiv>
          <NavFirst>
           <Flexdiv style={{ width: "50%", marginLeft: "40px" }}>
                <Logoimg src={logo} />
            </Flexdiv>

            {/* <NavSearchbar style={{ width: "90%" }} placeholder="Search" /> */}
          </NavFirst>
          <Mobileimg src={logo} />
          <NavSecond>
            <Flexdiv
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
             
              {/* <Flexdiv2
                style={{ position: "relative", justifyContent: "flex-start" }}
              >
                <Navtext>Collections</Navtext>

                <Dropdown style={{width: "100px", textAlign: "left"}}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/createCollection"
                  >
                    <Navtext
                      style={{
                        color: "black",
                        fontSize: "14px",
                        borderBottom: "1px solid #DEE5EF",
                        paddingBottom: "10px",
                        textDecoration: "none",
                        textAlign: "left",
                        marginRight: "40px"
                      }}
                    >
                      Create
                    </Navtext>
                  </Link>
                  {userData ? (
                    <Link style={{ textDecoration: "none" }} to="/gallery">
                      <Navtext
                        style={{
                          color: "black",
                          fontSize: "14px",
                          borderBottom: "1px solid #DEE5EF",
                          paddingBottom: "10px",
                          textDecoration: "none",
                          marginLeft: "7px"
                        }}
                      >
                        Collections & Components
                      </Navtext>
                    </Link>
                  ) : (
                    <> </>
                  )}
                </Dropdown>
              </Flexdiv2> */}

             

              {user === "superadmin" ? (
                null
              ) : null}
            </Flexdiv>

            {/* {!userData && history.location.pathname !== "/login" ? (
              <Link style={{ textDecoration: "none" }} to="/">
                <Navtext style={{ width: "10%" }}>Login</Navtext>
              </Link>
            ) : (
              <> </>
            )} */}
          </NavSecond>

          {/* <Navtext
            style={{
              width: "40%",
              marginLeft: "30px",
              overflow: "hidden",
              display: "none",
              // display: `${Navshow}`,
              background: "rgba(255,255,255,0.2)",
              padding: "5px 0px",
            }}
          >
            <img
              src={wallet}
              alt="icon"
              style={{ marginRight: "10px", paddingLeft: "5px" }}
            />
            Connect wallet
          </Navtext> */}
          {/* <Flexdiv
            style={{
              width: "30%",
              display: `${Navshow}`,
              justifyContent: "space-between",
            }}
          > */}
            {/* <Bellimg
              style={{ width: "40%", marginRight: "10px", opacity: "0" }}
              src={bell}
            /> */}
            {/* <Flexdiv2 style={{ position: "relative" }}> */}
              <Navtext >
                GAN NFT ART DEMONSTRATION
              </Navtext>

              {/* <Dropdown style={{ width: "100px" }}> */}
                {/* <Link style={{ textDecoration: "none" }} to="profile">
                  <Navtext
                    style={{
                      color: "black",
                      borderBottom: "1px solid #DEE5EF",
                      paddingBottom: "10px",
                      textDecoration: "none",
                    }}
                  >
                    Profile
                  </Navtext>
                </Link> */}
                {/* <Navtext onClick={handleLogOut} style={{ color: "black" }}>
                  Logout
                </Navtext> */}
              {/* </Dropdown> */}
            {/* </Flexdiv2> */}
          {/* </Flexdiv> */}

          <Mobilenavsecond>
            {/* <Burgericon
              src={menuburger}
              alt=""
              style={{ width: "20px", fill: "white" }}
              onClick={() => setSidebarshow("Flex")}
            /> */}
            {/* <Sidebaroverlay style={{ display: `${Sidebarshow}` }}> */}
              {/* <Sidebar style={{ display: `${Sidebarshow}` }}>
                <Link
                  style={{ textDecoration: "none", width: "100%" }}
                  to="/profile"
                >
                  <Flex
                    style={{
                      background: "linear-gradient(to right, #66ccff, #be61ff)",
                      borderRadius: "0px",
                      border: "none",
                      margin: "0",
                      justifyContent: "flex-start",
                      height: "40px",
                      padding: "20px 10px",
                      alignItems: "flex-start",
                    }}
                  >
                    <Userimg
                      style={{ marginRight: "20px" }}
                      src={(loginData && loginData.profileImageUrl) || profile}
                    />
                    <Navtext style={{}}>
                      {" "}
                      {(loginData && loginData.name) ||
                        (profileData && profileData.name) ||
                        "user"}
                    </Navtext>
                  </Flex>
                </Link>
                {/* <Navtext
                  style={{
                    color: "black",
                    display: `${Navshow}`,
                    width: "100%",
                    padding: "10px 20px",
                    borderBottom: "1px solid #DEE5EF",
                  }}
                >
                  Connect wallet
                </Navtext> */}
                {/* <Link
                  style={{ textDecoration: "none", width: "100%" }}
                  to="/adminpanel"
                >
                  <Navtext
                    style={{
                      color: "black",
                      marginLeft: "20%",
                      display: "flex",
                      padding: "10px 20px",
                      borderBottom: "1px solid #DEE5EF",
                    }}
                  >
                    Admin
                  </Navtext>
                </Link> */}
                {/* <Link
                  style={{ textDecoration: "none", width: "100%" }}
                  to="/gallery"
                >
                  <Navtext
                    style={{
                      color: "black",
                      display: "flex",
                      padding: "10px 20px",
                      borderBottom: "1px solid #DEE5EF",
                    }}
                  >
                    Collections & Components
                  </Navtext>
                </Link> */}
                {/* <Link
                  style={{ textDecoration: "none", width: "100%" }}
                  to="/claimvrynt"
                >
                  <Navtext
                    style={{
                      color: "black",
                      display: "flex",
                      padding: "10px 20px",
                      borderBottom: "1px solid #DEE5EF",
                    }}
                  >
                    Claim Vrynt
                  </Navtext>
                </Link> */}
                {/* <Link
                  style={{ textDecoration: "none", width: "100%" }}
                  to="/createCollection"
                >
                  <Navtext
                    style={{
                      color: "black",
                      display: "flex",
                      padding: "10px 20px",
                      borderBottom: "1px solid #DEE5EF",
                    }}
                  >
                    Create Collection
                  </Navtext>
                </Link> */}
                {/* <Navtext
                  onClick={handleLogOut}
                  style={{
                    color: "black",
                    fontSize: "14px",
                    width: "100%",
                    display: "flex",
                    padding: "10px 20px",
                    alignItems: "center",
                  }}
                >
                  Logout
                  <img
                    src={logout}
                    alt=""
                    width="13px"
                    style={{ marginLeft: "6px" }}
                  />
                </Navtext> */}
              {/* </Sidebar> */}
              <Sidebarclose
                src={close}
                alt=""
                width="25px"
                onClick={() => setSidebarshow("none")}
              />
            {/* </Sidebaroverlay> */}
          </Mobilenavsecond>
        </Navdiv>
      </Parentdiv>
    </>
  );
}

export default Navbar;
