import styled from "styled-components";

export const RewardValue =styled.h5 `

font-family: Source Sans Pro;
font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 59%;
color: #BE61FF;


`

export const RewardText =styled.h6  ` 

font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 91.5%;
/* or 13px */


/* text color */

color: #000000;



`   

export const ClaimButton = styled.button  `


display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 11px 16px;
width: 185px;
height: 40px;
left: 1097px;
top: 1194px;

/* gradient_linear */

background: linear-gradient(92.28deg, #66CCFF 40.06%, #BE61FF 69.89%);
border-radius: 4px;



`