import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { LineThrough, Input, TelegramDiv, BTN } from '../ProfileManagement/style';
import { H2, Flexcolumn, Flex } from '../Claimvrynt/style';
import CustomToggle from '../AccountSetting/UserAccountInfo/ToggleContainer';
import questionmark from '../../../../assets/icons/questionmark.svg';
import { BTN2, Button } from '../Collection2/style';
//import Image1 from "../../../../assets/icons/Admin.png";

import admin from "../../../../assets/icons/Admin.png";
import image2 from "../../../../assets/icons/Image2.png";
import image8 from "../../../../assets/icons/Image8.png"
import image3 from "../../../../assets/icons/Image3.png";
import image4 from "../../../../assets/icons/Image4.png"
import image5 from "../../../../assets/icons/Image5.png";
import image6 from "../../../../assets/icons/Image6.png"
import image7 from "../../../../assets/icons/Image7.png"
import Fimage from "../../../../assets/icons/Fimage.png"
import Simage from "../../../../assets/icons/Simage.png"

import set from "../../../../assets/icons/set.png"
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client';
import swal from 'sweetalert';
import { parse } from 'dotenv';
import { update } from 'lodash';
import { Div1, Div2 } from './style';
/* @ts-ignore */
import Grid from "@material-ui/core/Grid";

function Adminpanel() {

    const [createCollection, setCreateCollection] = useState(false);
    const [updateConStant, setUPdateConstant] = useState({

        DirectmarketplaceDistributiontoVRYNTProceedspool: 0,
        DirectMarketplaceDitributiontoArtistsProceedsPool: 0,
        ConsumerMarketplaceDistributiontoVRYNTProceedsPoolcomponents: 0,
        ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents: 0,
        ConsumerMarketplaceDistributiontoVRYNTProceedsPool: 0,
        ConsumermarketplaceDistributiontoArtistProceedspoolNFT: 0,
        ConsumermarketplaceDistributiontoPreviousCollectorProceedspoolNFT: 0,
        MarketLiquidityBootstrapPool: 0,
        Earlygalleryrewartclaim: 0,
        VRYNTCredittoVRYNTConversionMethodTreasuryFunded: 0,
        VRYNTCredittoVRYNTConvertionMethodSupplyFunded: 0,
        VRYNTCreditInactivityFee: 0,
        CreditPurchaseComponentHolding: 0,


    })


    const UpdatePlatformConstant = gql`
    mutation updatePlatformConstant($input: UpdatePlatformConstantDto!) {
        updatePlatformConstant (input: $input) {
            platformConstant {
                DirectmarketplaceDistributiontoVRYNTProceedspool
                    DirectMarketplaceDitributiontoArtistsProceedsPool
                    ConsumerMarketplaceDistributiontoVRYNTProceedsPoolcomponents
                    ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents
                    ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents
                    ConsumerMarketplaceDistributiontoVRYNTProceedsPool
                    ConsumermarketplaceDistributiontoArtistProceedspoolNFT
                    ConsumermarketplaceDistributiontoPreviousCollectorProceedspoolNFT
                    MarketLiquidityBootstrapPool
                    Earlygalleryrewartclaim
                    VRYNTCredittoVRYNTConversionMethodTreasuryFunded
                    VRYNTCredittoVRYNTConvertionMethodSupplyFunded
                    VRYNTCreditInactivityFee
                    CreditPurchaseComponentHolding
                    name
                    AllowedCollectionCreation   }
                message
                }
        }
`;




    const platformData = gql`
query {
    getPlatformConstant
  {
     platformConstant {
        DirectmarketplaceDistributiontoVRYNTProceedspool
                  DirectMarketplaceDitributiontoArtistsProceedsPool
                  ConsumerMarketplaceDistributiontoVRYNTProceedsPoolcomponents
                  ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents
                  ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents
                  ConsumerMarketplaceDistributiontoVRYNTProceedsPool
                  ConsumermarketplaceDistributiontoArtistProceedspoolNFT
                  ConsumermarketplaceDistributiontoPreviousCollectorProceedspoolNFT
                  MarketLiquidityBootstrapPool
                  Earlygalleryrewartclaim
                  VRYNTCredittoVRYNTConversionMethodTreasuryFunded
                  VRYNTCredittoVRYNTConvertionMethodSupplyFunded
                  VRYNTCreditInactivityFee
                  CreditPurchaseComponentHolding
                  AllowedCollectionCreation   }
   
  }
 
}
`;



    const Inputtablediv = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 45%;
    margin-bottom: 10px;
    `;

    const Dashboardcontainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 90%;
    margin-bottom: 10px;
   // place-content: space-between;
    `;
    const VRYNTCredit = styled.div`
    width: 23%;
    margin-bottom: 10px;
    height:200px;
    background-color:rgba(255, 224, 170, 0.29);
    text-align:center;
    
    `;
    const H1 = styled.h1`
    font-family: Source Sans Pro;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 120%;
/* identical to box height, or 36px */

letter-spacing: -0.01em;
    `;
    const P = styled.p`

    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 146%;
/* or 20px */


/* text color */

color: #000000;

opacity: 0.77;
    `;

    const [doUpdate, { data }] = useMutation(UpdatePlatformConstant);
    const { error: erros, data: platformConstdata } = useQuery(platformData)
    const platformConstantData = platformConstdata?.getPlatformConstant.platformConstant

const DashBoardData=[
    {  heading : "$11,530,524.22", para:"Unclaimed VRYNT Credit",image:admin },
    {  heading : "$82,580,524.22", para:"Unclaimed VRYNT Platform Credit",image:image3,color:"#F1DDFF " },
    {  heading : "30", para:"Outsanding componenets",image:image5,color:"#35F67C4A" },
    {  heading : "25493.00", para:"Total Gallery Reward Liability",image:image4,color:"#EC6EDF" },
    
]

const DashBoardData1=[
    {  heading : "$11,530,524.22", para:"Revenue generated from fees",image:Fimage,color:"#FCDFCA" },
    {  heading : "$1024", para:"NFTs minted",image:Simage,color:"#F1DDFF " },
   {  heading : "2000", para:"Artworks traded ",image:image7,color:"#F0F0F0" },
    {  heading : "25493.00", para:"Total Gallery Reward Liability",image:image8,color:"#F6F5A2" },

   

]

    useEffect(() => {
        if (platformConstantData) {

            setUPdateConstant({
                ...updateConStant,
                DirectmarketplaceDistributiontoVRYNTProceedspool: platformConstantData?.DirectmarketplaceDistributiontoVRYNTProceedspool,
                DirectMarketplaceDitributiontoArtistsProceedsPool: platformConstantData?.DirectMarketplaceDitributiontoArtistsProceedsPool,
                ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents: platformConstantData?.ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents,
                ConsumerMarketplaceDistributiontoVRYNTProceedsPool: platformConstantData?.ConsumerMarketplaceDistributiontoVRYNTProceedsPool,
                ConsumerMarketplaceDistributiontoVRYNTProceedsPoolcomponents: platformConstantData?.ConsumerMarketplaceDistributiontoVRYNTProceedsPoolcomponents,
                ConsumermarketplaceDistributiontoArtistProceedspoolNFT: platformConstantData?.ConsumermarketplaceDistributiontoArtistProceedspoolNFT,
                ConsumermarketplaceDistributiontoPreviousCollectorProceedspoolNFT:
                    platformConstantData?.ConsumermarketplaceDistributiontoPreviousCollectorProceedspoolNFT,
                CreditPurchaseComponentHolding:
                    platformConstantData?.CreditPurchaseComponentHolding,
                Earlygalleryrewartclaim:
                    platformConstantData?.Earlygalleryrewartclaim,
                MarketLiquidityBootstrapPool:
                    platformConstantData?.MarketLiquidityBootstrapPool,
                VRYNTCreditInactivityFee:
                    platformConstantData?.VRYNTCreditInactivityFee,
                VRYNTCredittoVRYNTConversionMethodTreasuryFunded: platformConstantData?.VRYNTCredittoVRYNTConversionMethodTreasuryFunded,
                VRYNTCredittoVRYNTConvertionMethodSupplyFunded:
                    platformConstantData?.VRYNTCredittoVRYNTConvertionMethodSupplyFunded
            })
            setCreateCollection(platformConstantData?.AllowedCollectionCreation)

        }
    }, [platformConstantData])

//handleSubmit
    const handleSubmit = () => {
        {
            doUpdate({
                variables: {
                    input: {
                        DirectmarketplaceDistributiontoVRYNTProceedspool:
                            updateConStant.DirectmarketplaceDistributiontoVRYNTProceedspool,
                        DirectMarketplaceDitributiontoArtistsProceedsPool:
                            updateConStant.DirectMarketplaceDitributiontoArtistsProceedsPool,
                        ConsumerMarketplaceDistributiontoVRYNTProceedsPoolcomponents:
                            updateConStant.ConsumerMarketplaceDistributiontoVRYNTProceedsPoolcomponents,
                        ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents:
                            updateConStant.ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents,
                        ConsumerMarketplaceDistributiontoVRYNTProceedsPool:
                            updateConStant.ConsumerMarketplaceDistributiontoVRYNTProceedsPool,
                        ConsumermarketplaceDistributiontoArtistProceedspoolNFT:
                            updateConStant.ConsumermarketplaceDistributiontoArtistProceedspoolNFT,
                        ConsumermarketplaceDistributiontoPreviousCollectorProceedspoolNFT:
                            updateConStant.ConsumermarketplaceDistributiontoPreviousCollectorProceedspoolNFT,
                        MarketLiquidityBootstrapPool: updateConStant.MarketLiquidityBootstrapPool,
                        Earlygalleryrewartclaim: updateConStant.Earlygalleryrewartclaim,
                        VRYNTCredittoVRYNTConversionMethodTreasuryFunded: updateConStant.VRYNTCredittoVRYNTConversionMethodTreasuryFunded,
                        VRYNTCredittoVRYNTConvertionMethodSupplyFunded: updateConStant.VRYNTCredittoVRYNTConvertionMethodSupplyFunded,
                        VRYNTCreditInactivityFee: updateConStant.VRYNTCreditInactivityFee,
                        CreditPurchaseComponentHolding: updateConStant.CreditPurchaseComponentHolding,
                        AllowedCollectionCreation: createCollection
                    },
                },
            })
                .then((res) => {
                    /* @ts-ignore */
                    swal("Platform constant updated sucessfully")
                })
                .catch((error) => {
                    swal("error occured")
                  });
                }
            }

    return (
        <div style={{
            display: "flex", flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
      
      
      <LineThrough style={{marginTop:'20px'}}>
            <span
              style={{
            backgroundColor: "white",
          }}
            >
          Dashboard
        </span>
         </LineThrough>
         <Dashboardcontainer>
         
    { DashBoardData.map(item => (
       <Grid  item container xs={12} spacing={3} >
           <Grid  item  md={3} >
        <VRYNTCredit style={{marginRight:"2%",backgroundColor:item.color,width:"500%"}}>
         <img  style={{width:"30px",marginTop:"15px"}} src={item.image}/>
            <H1>{item.heading}</H1>
            <P>{item.para}</P>
         </VRYNTCredit>
         </Grid>
         </Grid>
           )       )}
         
   
   
         </Dashboardcontainer>

<Dashboardcontainer>
{ DashBoardData1.map(item => (
         <VRYNTCredit style={{marginRight:"2%",backgroundColor:item.color}}>
         <img  style={{width:"30px",marginTop:"15px"}} src={item.image}/>
            <H1>{item.heading}</H1>
            <P>{item.para}</P>
         </VRYNTCredit>
           )       )}
   
    </Dashboardcontainer>

<LineThrough style={{marginTop:'20px'}}>
            <span
              style={{
            backgroundColor: "white",
          }}
            >
          Platform Constant
        </span>
         </LineThrough>
      
               <Flex style={{ border: 'none', width: '90%', flexWrap: 'wrap', marginTop: '0', justifyContent: 'space-between', padding: '' }}>
                <Div1>
                    <Div2>
                        <H2>
                            Direct market place Distribution to VRYNT Proceeds pool %  <img src={questionmark} />
                        </H2>
                        <Input
                        type="number"
                            style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                            onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    DirectmarketplaceDistributiontoVRYNTProceedspool: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.DirectmarketplaceDistributiontoVRYNTProceedspool}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            Direct Marketplace Distribution to Artists Proceeds Pool %  <img src={questionmark} />
                        </H2>
                        <Input
                        type="number"
                       min={0}
                       style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                            onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    DirectMarketplaceDitributiontoArtistsProceedsPool: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.DirectMarketplaceDitributiontoArtistsProceedsPool}
                        />
                    </Div2>
                </Div1>

                <Div1>
                    <Div2>
                        <H2>
                            Consumer Marketplace Distribution to VRYNT Proceeds Pool % components <img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                         type="number"
                         min={0}
                            onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    ConsumerMarketplaceDistributiontoVRYNTProceedsPoolcomponents: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.ConsumerMarketplaceDistributiontoVRYNTProceedsPoolcomponents}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            Consumer Marketplace Distribution to Artists Proceeds Pool % components  <img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                            type="number"
                            min={0}
                              onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.ConsumerMarketplaceDistributiontoArtistsProceedsPoolcomponents}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            Consumer Marketplace Distribution to VRYNT Proceeds Pool % <img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                        type="number"
                        min={0}
                           onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    ConsumerMarketplaceDistributiontoVRYNTProceedsPool: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.ConsumerMarketplaceDistributiontoVRYNTProceedsPool}

                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            Consumer marketplace Distribution to Artist Proceeds pool % NFT <img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                            type="number"
                            min={0}
                            onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    ConsumermarketplaceDistributiontoArtistProceedspoolNFT: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.ConsumermarketplaceDistributiontoArtistProceedspoolNFT}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            Consumer marketplace Distribution to Previous Collector Proceeds pool % NFT  <img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                           type="number"
                           min={0}
                           onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    ConsumermarketplaceDistributiontoPreviousCollectorProceedspoolNFT: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.ConsumermarketplaceDistributiontoPreviousCollectorProceedspoolNFT}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            Market Liquidity Bootstrap Pool %  <img src={questionmark} />
                        </H2>
                        <Input
                           type="number"
                           min={0}
                           style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                            onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    MarketLiquidityBootstrapPool: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.MarketLiquidityBootstrapPool}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            Early gallery reward claim %  <img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                      type="number"
                      min={0}
                           onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    Earlygalleryrewartclaim: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.Earlygalleryrewartclaim}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            VRYNT Credit to VRYNT Conversion Method ( Treasury Funded)<img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                      type="number"
                      min={0}
                           onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    VRYNTCredittoVRYNTConversionMethodTreasuryFunded: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.VRYNTCredittoVRYNTConversionMethodTreasuryFunded}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            VRYNT Credit to VRYNT Convertion Method( supply Funded)  <img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                      type="number"
                      min={0}
                           onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    VRYNTCredittoVRYNTConvertionMethodSupplyFunded: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.VRYNTCredittoVRYNTConvertionMethodSupplyFunded}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            VRYNT Credit Inactivity Fee  <img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                      type="number"
                      min={0}
                           onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    VRYNTCreditInactivityFee: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.VRYNTCreditInactivityFee}
                        />
                    </Div2>
                </Div1>
                <Div1>
                    <Div2>
                        <H2>
                            Credit Purchase Component Holding <img src={questionmark} />
                        </H2>
                        <Input style={{ marginBottom: '20px', marginTop: '0', paddingLeft: '10px' }}
                      type="number"
                      min={0}
                           onChange={(e) =>
                                setUPdateConstant({
                                    ...updateConStant,
                                    CreditPurchaseComponentHolding: parseInt(e.target.value)
                                })
                            }
                            defaultValue={platformConstantData?.CreditPurchaseComponentHolding}
                        />

                    </Div2>
                </Div1>

                <Inputtablediv>
                 <Flexcolumn>
                        <TelegramDiv style={{marginTop:"5%"}}>
                            <CustomToggle 
                                isActive={createCollection}
                                handleChange={() => setCreateCollection(!createCollection)}
                                title='Global Setting for Create Collection'
                            />
                        </TelegramDiv>
                    </Flexcolumn>
                </Inputtablediv>

            </Flex>
            <BTN2
									style={{
										padding: '10px 20px',
										//backgroundColor: '#35BCFF',
                                        borderImage: 'linear-gradient(to bottom, #66ccff, #be61ff) 1 1',
										width: '30%',
                                        height:'50px',
										fontSize: '15px',
										margin: '3%',
									}}
									onClick={handleSubmit}
								>
								Submit
								</BTN2>
						
                 

        </div>
    )




}

export default Adminpanel
