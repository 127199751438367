import React, { useState } from "react";
import { Grid, Slider, withStyles, makeStyles } from "@material-ui/core";
import swal from "sweetalert";
import Refreshicon from "./Icons/Refreshicon.png";
import Xicon from "./Icons/Xicon.png";
const useStyles = makeStyles({
  icon: {
    cursor: "pointer",
  },
  container: {
    textAlign: "center",
    border: "1px solid #cecece",
    width: "90%",
    marginBottom: "3px",
    borderRadius: "5px",
    padding: "15px 6px",
  },
});
const PrettoSlider = withStyles({
  root: {
    marginLeft: "30px",
    color: "#fff",
    height: 8,
    padding: 0,
  },
  thumb: {
    height: 24,
    width: 24,
    background: "linear-gradient(180deg, #66CCFF 0%, #BE61FF 100%);",
    border: "4px solid currentColor",
    marginTop: -10,
    marginLeft: -12,
    position: "relative",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
    "&:after": {
      content: "",
      background: "linear-gradient(180deg, #66CCFF 0%, #BE61FF 100%);",
      zIndex: "-1",
      height: "31px",
      width: "31px",
      position: "absolute",
      top: "-7px",
      left: "-7px",
    },
  },
  active: {},
  valueLabel: {
    color: "#7C8188",
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 4.5,
    borderRadius: 4,
    background: "linear-gradient(92.28deg, #66CCFF 0%, #BE61FF 100%);",
  },
  rail: {
    height: 4.5,
    borderRadius: 4,
    backgroundColor: "#e1e1e1",
  },
})(Slider);
// @ts-ignore
const SeedSlider = ({
  name,
  value,
  onChange,
  setSeedNumber,
  setCollection,
  setChaosData,
  onResetClick,
  onMouseOut,
  removeGene,
  minimum,
  maximum,
  seed,
  setSeed
}: any) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid item md={12} style={{ marginBottom: "10px", textAlign: "center" }}>
        {name}
      </Grid>
      <Grid item container>
        <Grid
          item
          xs={7}
          md={7}
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <PrettoSlider
            defaultValue={0}
            value={value || 0}
            onChange={onChange}
            // onMouseUp={onMouseOut}
            // onMouseOver={setValueOnHover(value)}
            valueLabelDisplay="auto"
            step={1}
            min={parseInt(minimum)}
            max={maximum}
          />
        </Grid>
        <Grid item xs={5} md={5}>
          <input
		  type="text"
		  placeholder="1-99999"
		  // min="1"
		  // max="99999"
		  // onBlur={(e)=>{
      //   setChaosData({})
			// if(seed > 999){
			// 	setCollection(seed)
			//   }
			//   else if(seed < 999 && seed > 99){
			// 	setCollection("0"+seed.toString())
			//   }
			//   else if(seed < 99 && seed > 9){
			// 	setCollection("00"+seed.toString())
			//   }
			//   else if(seed < 9 && seed > 0){
			// 	setCollection("000"+seed.toString())
			//   }
			//   setSeedNumber(seed)
			// }}

      onKeyDown={(e)=>{
        if(e.key === 'Enter') {
  setChaosData({})
			if(seed > 999){
				setCollection(seed)
			  }
			  else if(seed < 999 && seed > 99){
				setCollection("0"+seed.toString())
			  }
			  else if(seed < 99 && seed > 9){
				setCollection("00"+seed.toString())
			  }
			  else if(seed < 9 && seed > 0){
				setCollection("000"+seed.toString())
			  }
        else if(seed < 0){
          setCollection("0001")
          }
			  setSeedNumber(seed)
        }
      
			}}
		  onChange={(e)=> {
        if(parseInt(e.target.value) === 0){
          setSeed(1)
        }
			  else if(parseInt(e.target.value) > 99999){
				setSeed(99999)
			  }
			  else if(parseInt(e.target.value) < 99999){
				setSeed(e.target.value)
			  }
        else if(e.target.value.length === 0){
          setSeed("")
          }
			}
			}
		value={seed}
		   style={{ width: "40%" }}></input>
        </Grid>
      </Grid>
    </div>
  );
};

export default SeedSlider;
