import React,{useState} from 'react';
import { Grid, Slider, withStyles, makeStyles } from '@material-ui/core';
import swal from 'sweetalert';
import Refreshicon from './Icons/Refreshicon.png';
import Xicon from './Icons/Xicon.png';
const useStyles = makeStyles({
	icon: {
		cursor: 'pointer',
	},
	container: {
		border: '1px solid #cecece',
		borderRadius: '5px',
		padding: '15px 6px',
	},
});
const PrettoSlider = withStyles({
	root: {
		color: '#fff',
		height: 8,
		padding: 0,
	},
	thumb: {
		height: 24,
		width: 24,
		background: 'linear-gradient(180deg, #66CCFF 0%, #BE61FF 100%);',
		border: '4px solid currentColor',
		marginTop: -10,
		marginLeft: -12,
		position: 'relative',
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
		'&:after': {
			content: '',
			background: 'linear-gradient(180deg, #66CCFF 0%, #BE61FF 100%);',
			zIndex: '-1',
			height: '31px',
			width: '31px',
			position: 'absolute',
			top: '-7px',
			left: '-7px',
		},
	},
	active: {},
	valueLabel: {
		color: '#7C8188',
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: 4.5,
		borderRadius: 4,
		background: 'linear-gradient(92.28deg, #66CCFF 0%, #BE61FF 100%);',
	},
	rail: {
		height: 4.5,
		borderRadius: 4,
		backgroundColor: '#e1e1e1',
	},
})(Slider);
// @ts-ignore
const ChaosSlider = ({ name, value, onChange, onResetClick,onMouseOut, removeGene, minimum, maximum, step }: any) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<Grid item md={12} style={{ marginBottom: '10px' }}>
				{name}
			</Grid>
			<Grid item container>
				<Grid
					item
					xs={1}
					md={2}
					style={{ textAlign: 'left' }}
					className={classes.icon}
					onClick={onResetClick}
				>
					<img src={Refreshicon} alt='refresh' />
				</Grid>
				<Grid item xs={8} md={8} style={{marginLeft:"10px"}}>
					<PrettoSlider
						defaultValue={0}
						value={value || 0}
						onChange={onChange}
						// onMouseUp={onMouseOut}
						// onMouseOver={setValueOnHover(value)}
						valueLabelDisplay='auto'
						step={step}
						min={parseFloat(minimum)}
						max={maximum}
					/>
				</Grid>
				{/* <Grid
					item
					xs={1}
					md={2}
					style={{ textAlign: 'right' }}
					className={classes.icon}
					onClick={removeGene}
				>
					<img src={Xicon} alt='cancel' />
				</Grid> */}
			</Grid>
		</div>
	);
};

export default ChaosSlider;
