import React,{FC,useEffect,useState} from "react";
import {
    EmailIcon,
    EmailShareButton,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";

  import { FacebookIcon, TwitterIcon,WhatsappIcon,PinterestIcon,RedditIcon,ViberIcon,InstapaperIcon,LinkedinIcon} from "react-share";
import { ClosePopUp } from "../../modules/app/components/Login/style";
import { Popup, PopUpOverlay } from "../../modules/app/components/Popups/Bid";

interface shareProps {
    url: string,
    shareState: boolean,
  }
  
const Share : FC<shareProps> = (props): JSX.Element =>  {
const [ShowModal,setShowModal]=useState(props.shareState)
    
return(

  <div >
   {ShowModal ?

<PopUpOverlay >
<Popup style={{display:"flex",justifyContent:"space-between", flexDirection:"row", width:"1000px", height:"150px",margin:"5px"}}>
        <FacebookShareButton
        url={props.url}
        className="Demo__some-network__share-button"
      >
        <FacebookIcon size={32} round /> Facebook
      </FacebookShareButton>
      <br />
      <TwitterShareButton
        url={props.url}  
        hashtags={["hashtag1", "hashtag2"]}
      >
        <TwitterIcon size={32} round />
        Twitter
      </TwitterShareButton>
      <WhatsappShareButton
        url={props.url}    >
        <WhatsappIcon size={32} round />
        Whatsapp
      </WhatsappShareButton>
      <InstapaperShareButton
        title={"sharing nft"}
        url={props.url}
      >
        <InstapaperIcon size={32} round />
        Instagram
      </InstapaperShareButton>
      <RedditShareButton
     title={"sharing nft"}
     url={props.url}
     >
        <RedditIcon size={32} round />
        Reddit
      </RedditShareButton>
 
      <LinkedinShareButton
     title={"sharing nft"}
     url={props.url}
     >
        <LinkedinIcon size={32} round />
        Linked In
      </LinkedinShareButton>
 
      <ViberShareButton
     title={"sharing nft"}
     url={props.url}
   >
        <ViberIcon size={32} round />
        Viber 
      </ViberShareButton>
      
      
      <FacebookMessengerShareButton
   title={"sharing nft"}
   url={props.url}
     appId={""}

      >
        <FacebookMessengerIcon size={32} round />
        Messenger
      </FacebookMessengerShareButton>

      <TelegramShareButton
     title={"sharing nft"}
     url={props.url}
    
      >
        <TelegramIcon size={32} round />
        Telegram
      </TelegramShareButton>
      <EmailShareButton
    title={"sharing nft"}
    url={props.url}
    
      >
        <EmailIcon size={32} round />
        Email
      </EmailShareButton>
      <PinterestShareButton
    title={"sharing nft"}
    url={props.url}
    media={""}
      >
        <PinterestIcon size={32} round />
        Pinterest
      </PinterestShareButton>
     <ClosePopUp onClick={() => setShowModal(false)}>X</ClosePopUp>
 </Popup>
</PopUpOverlay>
: null }
  </div> 
    )

  }
  export default Share