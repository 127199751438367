import styled from "styled-components";

export const Image = styled.img`
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
`;

export const Homebody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -5%;

  > * {
    box-sizing: border-box;
  }
`;
export const H1 = styled.h1`
  font-size: 18px;
  color: white;
  font-weight: 500;
`;

export const UL = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  padding-inline-start: 0px;
`;

export const LI = styled.div`
  position: relative;
  margin: 4px;
  width: 49%;
  height: 350px;
  @media (min-width: 1440px) {
    width: 32%;
    height: 400px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  > * {
    background: rgba(0, 0, 0, 0.5);

  }
  &:hover {
    transition: all .6s ease;  
    > * {
      display: flex;
 
    }
  }
`;
export const Infodiv = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  display: none;
  width: 100%;
  padding-left: 5%;
  padding-top: 5%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  transition: all .6s ease; 
  > H1 {
    width: 70%;
  }
`;

export const H2div = styled.div`
  display: flex;
  width: 60%;
  height: 20%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flexstart;
  border-top: 1px solid #fff;
  > * {
    width: 40%;
  }
`;
export const H2 = styled.h2`
  font-size: 11px;
  color: white;
  font-weight: 300;
  flex-wrap: wrap;
`;

export const EstimatedEarningdiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: white;
  font-weight: 600;
  margin-top: 7%;
  border: 2px dotted white;
  padding: 10px;
`;

export const Button = styled.button`
  padding: 8px 11px;
  background-color: #35f67c8c;
  color: white;
  border-radius: 2px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Span = styled.span`
  color: #35bcff;
`;
