import styled from "styled-components";



export const Borderdiv = styled.div`
border: 0.8px solid rgba(28, 33, 45, 0.12);
padding:20px;
border-radius: 5px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;

`
export const H5=styled.h3 `

font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 130%;
/* or 18px */

text-align: center;

/* grey-txt */

color: #7C8188;
  


`
export const Chaosinut=styled.input `
position: relative;
width:35% ;

margin-left:8px;
display: inline;

background: #FFFFFF;
height: 23px;
border: 1px solid rgba(162, 162, 162, 0.5);
box-sizing: border-box;
border-radius: 4px;

::-webkit-inner-spin-button {
    opacity: 0.36;
    width:120%;
    
}
:focus {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #66ccff, #be61ff) 1 1;
  outline: none;
  border-radius: 4px;
}


`

export const Scrolldivs = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
width: 48%;
flex-direction: column;
border: 0.8px solid rgba(28, 33, 45, 0.12);
padding:15px;
border-radius: 5px;
`
export const Slider =styled.input `
width:125px;
margin-top: 8%;
&::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: 15px;
    width: 10px;
    background:red;
    background-size: cover;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 150ms;
  }

&::-webkit-slider-runnable-track {
//  appearance: none;
height:62%;
border-radius: 18px; 
background: #d3d3d3;
  outline: none;

}


`

export const Card1=styled.div `
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
img {display:none}

&:hover {   
     img{
        position:absolute;
         display:inline-block;
}             
    
}
padding:7px 15px;
border: 1.2px solid rgba(162, 162, 162, 0.5);
box-sizing: border-box;
border-radius: 4px;
border-style: solid;
border-image: linear-gradient(to bottom, #66ccff, #be61ff) 1 1;      
`



