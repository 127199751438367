import React from 'react';
import {
	Homebody,
	ImageComp,
	Container,
	Infodiv,
	Hoverdiv,
	H2,
	H3,
	HoverBTN,
	Btnhover,
} from './style';
import { LineThrough } from '../ProfileManagement/style';
import { H1 } from '../ProfileManagement/style';
import history from '../history';

const Home = (props: any) => {
	const imageArray = [];
	for (let i = 1; i <= 30; i++) {
		const tempImageVal = {
			key: `image${i}`,
			title: `Title ${i}`,
			description: `Description ${i}`,
			url: `https://source.unsplash.com/random/${i}`,
		};
		imageArray.push(tempImageVal);
	}
	return (
		<Homebody style={{ marginTop: '1px' }}>
			<LineThrough>
				<span
					style={{
						backgroundColor: 'white',
						padding: '0 10px',
					}}
				>
					Marketplace
				</span>
			</LineThrough>
			<Container>
				{imageArray.map((image) => (
					<ImageComp key={image.key}>
						<img
							src={image.url}
							alt=''
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								verticalAlign: 'bottom',
							}}
						/>
						<Infodiv>
							<H1 style={{ color: 'white' }}>{image.title}</H1>
							<H1 style={{ color: 'white', fontSize: '12px' }}>
								{image.description}
							</H1>
						</Infodiv>

						<Hoverdiv>
							<H2>{image.title}</H2>
							<H3>{image.description}</H3>
							<Btnhover>
								<HoverBTN
									onClick={() => {
										localStorage.setItem(
											'galaryImageData',
											JSON.stringify(image)
										);
										history.push('/marketplace');
									}}
								>
									Build VRYNT
								</HoverBTN>
								<HoverBTN> View All NFTs</HoverBTN>
							</Btnhover>
						</Hoverdiv>
					</ImageComp>
				))}
			</Container>
		</Homebody>
	);
};

export default Home;
