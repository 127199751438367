import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import walletReducer from './reducers/wallet.reducer';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const config = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
	wallet: walletReducer,
});
const persisted = persistReducer(config, rootReducer);


const store = createStore(persisted, applyMiddleware(thunk));

export default store;