import React from 'react';
import AppleLogin from 'react-apple-login';
import { AppleButton, Image } from './style';
import apple from '../../../../assets/icons/Apple.svg';
import history from '../history';
import { gql, useMutation } from '@apollo/client';

const MyAppleSigninButton = ({ ...rest }) => {
	const Appleid = process.env.REACT_APP_APPLE_ID;

	const AppleLOGIN = gql`
		mutation AppleLogin($input: AppleLoginDto!) {
			appleLogin(input: $input) {
				token
				message
			}
		}
	`;

	const [doAppleLogin] = useMutation(AppleLOGIN);

	//console.log("data apple",data)

	return (
		<AppleLogin
			// @ts-ignore
			clientId={Appleid}
			redirectURI={'https://www.dev.vrynt.io/login'}
			responseType={'code'}
			responseMode={'form_post'}
			usePopup
			scope='name email'
			callback={(appId) => {
				console.log('appId ', appId);

				if (appId) {
					doAppleLogin({
						variables: {
							input: {
								code: appId.authorization.code,
								id_token: appId.authorization.id_token,
							},
						},
					})
						.then((res) => {
							/* @ts-ignore */
							console.log('res', res);
							// if (res.data.success) {
							// 	localStorage.setItem(
							// 		'token',
							// 		JSON.stringify(res.data.data.token)
							// 	);
							history.push('/profile');
							// }
							// Navigate the user
						})
						.catch((error) => {
							//swal(error.message);
							console.log('error ', error);
						});
				}
			}}
			render={(props) => (
				<AppleButton {...props} style={{ cursor: 'pointer' }}>
					<Image src={apple} />
					Continue with Apple
				</AppleButton>
			)}
		/>
	);
};

export default MyAppleSigninButton;
