import styled from "styled-components";


export const  Market = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
margin-top: 30px;
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
`;

export const Marketabove = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
`;
export const MarketLeft = styled.div`
display: flex;
align-items: flex-start;
flex-direction:column;
width:50%;
margin-left:4%;
`;
export const ImageText=styled.h1 `

font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 130%;
margin-top:-5%;
margin-left:5%;
color: #000000;
`


export const Image = styled.div`
width: 35%;
height: 70vh;
margin-right: 60px;
display: flex;
align-items: flex-start;
justify-content: center;
 > img{
    width: 100%;
    height: 90%;
    object-fit: cover;
}
`;

export const Marketaboveright = styled.div`
width: 45%;
height: 70vh;
overflow: hidden;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
`;

export const H1 = styled.h2`
font-size: 27px ;
font-weight: 500;
margin-bottom: 20px;
`;

export const H2 = styled.h2`
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 120%;
margin-bottom: 10px;
display: flex;
justify-content: center;
align-items: center;
top: -13px;
left: 15px;
background-color: white;
`;
export const H3 = styled.h2`
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 146%;
color: #000000;
display: inline;
margin-right: 15px;
`;

export const H4 = styled.h2`
font-size: 14px ;
font-weight: 300;
display: flex;
justify-content: flex-start;
align-items: center;
`;


export const BuyComponentdiv = styled.div`
display: flex;
box-sizing: border-box;
justify-content: space-between;
align-items: center;
border: 2px solid #66CCFF;
position: relative;
margin-bottom: 20px;
margin-top: 15px;
border-radius: 4px;
width: 100%;
height : 100px;
padding:10px 15px;
::nth-of-type(2){ transform: rotate(90deg); }
`;
export const Fieldset=styled.fieldset `
&:nth-of-type(1){ transform: rotate(90deg)
    
    ; 

}



`
export const Header=styled.h3 `
font-family: Source Sans Pro;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 120%;
/* or 22px */

letter-spacing: -0.02em;

/* text color */

color: #000000;

display:flex;
align-items:center;
justify-content:center;
`
export const Doyouknow = styled.div`
width: 100%;
`;

export const Marketbetween = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
width: 83%;
flex-direction: column;
border: 0.8px solid rgba(28, 33, 45, 0.12);
padding:15px;
border-radius: 5px;

margin-bottom:30px;
`

export const Carddiv = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-wrap: wrap;
width: 100%;
height: 150px;
//overflow-y: scroll;
margin-bottom: 1.8%;
margin-left: 0.8%;

&::-webkit-scrollbar {
    border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 4px; 
width: 10px;

}

    &::-webkit-scrollbar-thumb {
        background: #C4C4C4;
       border-radius: 4px; 

    
  }
>*{
    width: 20%;
    

}


`
export const Scrollable=styled.div `

/*display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-wrap: wrap;
width: 100%;
height: 220px;
overflow-y: scroll;

>*{
    width: 20%;
    

}



	border-radius: 10px;
	background-color: #F5F5F5;


*/
`  
export const Card = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
img {display:none}

&:hover {   
     img{
        position:absolute;
         display:inline-block;
}             
:after { backdrop-filter: blur(5px);
}
    }
    
}
padding:7px 15px;

border: 1.2px solid rgba(162, 162, 162, 0.5);
box-sizing: border-box;
border-radius: 4px;


    border-style: solid;
   border-image: linear-gradient(to bottom, #66ccff, #be61ff) 1 1;      
`

export const Marketbelow = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 83%;
flex-direction: column;
padding:20px;
margin-bottom:30px;
`

export const Mintednftdiv = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;

>*{
    width: 30%;
}

`

export const Span =  styled.span`
color: #35BCFF;
padding-left: 5px;
font-weight: 700;
font-size: 
`;

export const Flexdiv = styled.div`
display: flex;
align-items: center;
justify-content: center;


`


export const Button =styled.button `
padding: 11px 0px;

width: 100%;
height: 48px;
background: rgba(229, 231, 235, 0.5);
border-image: linear-gradient(to bottom, #66ccff, #be61ff) 1 1;      

`