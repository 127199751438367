import React, { useState } from "react";
import { Homebody, LI, UL, Infodiv } from "./style";

import { gql, useQuery } from "@apollo/client";
import { LineThrough } from "../ProfileManagement/style";
import { Spinner } from "reactstrap";

import history from "../history";
import { SpinningLoader } from "../../../../shared/components/Loader/SpinningLoader";

const Home = () => {
  const [state, setState] = React.useState({
    right: false,
  });

  const UserData = gql`
    query {
      getLoggedInUser {
        _id
      }
    }
  `;
  var loggedInuserId = "";
  const { data: Userdata } = useQuery(UserData);
  loggedInuserId = Userdata?.getLoggedInUser._id;
  const UserDetails = gql`
    query listCollections(
      $sortBy: String!
      $userId: String!
      $sortOrder: Int!
      $limit: Int!
      $offset: Int!
    ) {
      listCollections(
        input: {
          userId: $userId
          sortBy: $sortBy
          sortOrder: $sortOrder
          limit: $limit
          offset: $offset
        }
      ) {
        totalCollections
        collection {
          name
          minNumOfComponentsForNFT
          theme
          numOfNFTs
          initialPrice
          priceIncrement
          status
          type
          scheduledDate
          artPiece
          processedImgUrl
          _id
        }
      }
    }
  `;

  const {
    error,
    data: CollectionData,
    loading,
  } = useQuery(UserDetails, {
    variables: {
      sortBy: "name",
      sortOrder: -1,
      userId: loggedInuserId,
      limit: 100,
      offset: 1,
    },
  });

  const handleRoutes = (imageId: any) => {
    history.push(`/vrynt-builder/${imageId}`);
  };

  return (
    <Homebody style={{ marginTop: "1px" }}>
      <LineThrough>
        <span
          style={{
            backgroundColor: "white",
            padding: "0 10px",
          }}
        >
          My collections
        </span>
      </LineThrough>

      {CollectionData?.listCollections.collection ? (
        <>
          <UL>
            {CollectionData?.listCollections.collection.map(
              (collection: any) => (
                <LI
                  key={collection.name}
                  onClick={() => handleRoutes(collection._id)}
                >
                  <img
                    src={
                      collection.type === "CUSTOM" ? (
                        collection.processedImgUrl
                      ) : <> </> || collection.type === "SEED" ? (
                        collection.artPiece
                      ) : (
                        <> </>
                      )
                    }
                    alt=""
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                      verticalAlign: "bottom",
                    }}
                  />
                  <Infodiv></Infodiv>
                </LI>
              )
            )}
            <LI></LI>
          </UL>
        </>
      ) : (
        <>
          {error?.message === "no collections found" ? (
            <div>No Data Found</div>
          ) : (
            <SpinningLoader />
          )}
        </>
      )}
    </Homebody>
  );
};

export default Home;
