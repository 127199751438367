import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from '../../shared/styles/theme';
import Routes from './components/routes/Routes';
import bgImage from '../../assets/image/Combined-Shape.png';
require('dotenv').config();
const App = (props: any) => {
	const bgStyle = {
		// backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right bottom',
		minHeight: '100vh',
	};
	return (
		<div style={bgStyle}>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				<Routes />
			</ThemeProvider>
		</div>
	);
};

export default App;
