import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import ChaosSlider from "./ChaosSlider";
import resetBack from "./Icons/reset-back.png";
import swal from "sweetalert";
//@ts-ignore
import { SpinningLoader } from "../../../../shared/components/Loader/SpinningLoader";
import SeedSlider from "./SeedSlider";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "0 50px",
  },
  title: {
    fontWeight: "bold",
  },
  subTitle: {
    fontSize: "13px",
  },
  box: {
    border: "1px solid rgba(28, 33, 45, 0.12)",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "30px 20px",
    marginTop: "10px",
  },
});

const ArtBuilder = (props: any) => {
  const classes = useStyles();
  const [seedNumber, setSeedNumber] = useState(props.tabName === "Human" ? 1 :Math.floor((Math.random() * 99999) + 1));
  // const [seedNumber, setSeedNumber] = useState(1);
  const [seed, setSeed] = useState<any>(seedNumber);
  const [collection, setCollection] = useState<any>(()=> seedNumber > 999 ? seedNumber : seedNumber < 999 && seedNumber > 99 ? "0"+seedNumber.toString() : seedNumber < 99 && seedNumber > 9 ? "00"+seedNumber.toString() :seedNumber < 9 && seedNumber > 0 ? "000"+seedNumber.toString():null);
  const [modelId, setModelId] = useState("");
  const [chaosData, setChaosData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isResetAll, setIsResetAll] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [renderState, setRenderState] = useState(false);
  const [imageKey, setImageKey] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [genesList, setGenesList] = useState([])

  useEffect(() => {
    let geneList:any;
    if(props.tabName === "Human"){
      geneList = [
        { id: "age", name: "Age", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "nose_size", name: "Nose Size", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "gender", name: "Gender", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "eye_open", name: "Eye Open", maximum: "0.5", minimum: "-0.5",step: "0.05" },
        { id: "hair_black", name: "Hair Black", maximum: "0.5", minimum: "-0.5",step: "0.05" },
        { id: "hair_blond", name: "Hair Blonde", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "hair_gray", name: "Hair White Gray", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "face_shape", name: "Face Shape", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "mouth_open", name: "Mouth Open", maximum: "0.5", minimum: "-0.5",step: "0.05"},
        { id: "race_black", name: "Race Black", maximum: "0.5", minimum: "-0.5",step: "0.05" },
        { id: "race_white", name: "Race White", maximum: "0.5", minimum: "-0.5",step: "0.05" },
        { id: "smile", name: "Smile", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "glasses", name: "Glasses", maximum: "1.5", minimum: "-1.5",step: "0.05" },
        { id: "width", name: "Width", maximum: "1", minimum: "-1",step: "0.05"},
        { id: "angle_horizontal", name: "Angle Horizontal", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "emotion_angry", name: "Emotion Angry", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "emotion_fear", name: "Emotion Fear", maximum: "1", minimum: "-1",step: "0.05" },
        { id: "emotion_sad", name: "Emotion Sad", maximum: "1", minimum: "-1",step: "0.05" },
        // { id: "sunglasses", name: "Sunglasses", maximum: "1.5", minimum: "-1.5" },
        { id: "beard", name: "Beard", maximum: "0.7", minimum: "-0.7",step: "0.05" }
      ];
      setGenesList(geneList);
      setModelId("human")
      // 00017
    }
    if(props.tabName === "Anime"){
      geneList = [
        { id: "open_mouth", name: "Open Mouth", maximum: "2", minimum: "-2" },
        { id: "purple_filter", name: "Purple Filter", maximum: "2", minimum: "-2" },
        { id: "long_hair", name: "Long Hair", maximum: "2", minimum: "-2" },
        { id: "black_hair", name: "Black Hair", maximum: "2", minimum: "-2" },
        { id: "anime_pink_filter", name: "Anime Pink Filter", maximum: "2", minimum: "-2" },
        { id: "red_hair", name: "Red Hair", maximum: "2", minimum: "-2" },
        { id: "red_eyes", name: "Red Eyes", maximum: "2", minimum: "-2" },
        { id: "hair_between_eyes", name: "Hair Between Eyes", maximum: "2", minimum: "-2" },
        { id: "silver_hair", name: "Silver Hair",maximum: "2", minimum: "-2"},
        { id: "purple_hair", name: "Purple Eyes & Hair", maximum: "2", minimum: "-2" },
        { id: "blue_eyes", name: "Blue Eyes", maximum: "2", minimum: "-2" },
        { id: "short_hair", name: "Short Hair", maximum: "2", minimum: "-2" },
        { id: "anime_color_filter_1", name: "Anime Color Filter", maximum: "2", minimum: "-2" },
        { id: "anime_light_green_filter", name: "Anime Pink Shiny Filter", maximum: "2", minimum: "-2" },
        { id: "anime_zoom", name: "Anime Zoom", maximum: "2", minimum: "-2" },
        { id: "Face_orientation_anime", name: "Face Orientation Anime", maximum: "2", minimum: "-2" },
        { id: "anime_black_hair_yellow_eye_-ve", name: "Black Hair Yellow Eye", maximum: "2", minimum: "-2" },
        { id: "anime_golden_hair_-ve", name: "Anime Golden Hair",maximum: "2", minimum: "-2" },
        { id: "gray_hair_anime_-ve", name: "Gray Hair Anime", maximum: "2", minimum: "-2" },
        { id: "Smile_anime_-ve", name: "Smile Anime", maximum: "2", minimum: "-2" },
      ];
      setGenesList(geneList);
      setModelId("anime_512")
    }
    if(props.tabName === "Visionary Art"){
      geneList = [
        { id: "8_yellow", name: "Yellow", maximum: "1", minimum: "-1" },
        
        { id: "26_green", name: "Green", maximum: "1", minimum: "-1" },
        { id: "3_col_inc", name: "Col Inc", maximum: "1", minimum: "-1" },
        { id: "18_img_col_inc", name: "Img Col Inc", maximum: "1", minimum: "-1" },
        { id: "6_aqua", name: "Aqua", maximum: "1", minimum: "-1" },
        { id: "22_gray_lightblue", name: "Gray Lightblue", maximum: "1", minimum: "-1" },
       
        { id: "7_lime", name: "Lime", maximum: "1", minimum: "-1" },
        { id: "28_yellow_gray", name: "Yellow Gray", maximum: "1", minimum: "-1" },
        { id: "36_purple", name: "Purple", maximum: "1", minimum: "-1" },
        { id: "27_golden", name: "Golden", maximum: "1", minimum: "-1" },
        { id: "20_black", name: "Black", maximum: "1", minimum: "-1" },
        { id: "31_blue", name: "Blue", maximum: "1", minimum: "-1" },
        { id: "17_red", name: "Red", maximum: "1", minimum: "-1" },
        { id: "1_orange_circleshape", name: "Orange Circleshape", maximum: "1", minimum: "-1" },
        { id: "0_zoom", name: "Zoom", maximum: "1", minimum: "-1" },
        { id: "feature_42", name: "Chaos 1", maximum: "1", minimum: "-1" },
        { id: "feature_46", name: "Chaos 2", maximum: "1", minimum: "-1" },
        { id: "feature_48", name: "Chaos 3", maximum: "1", minimum: "-1" },
        { id: "feature_32", name: "Chaos 4", maximum: "1", minimum: "-1" },
      ];
      setGenesList(geneList);
      setModelId("visionary_art_1024")
    }
    if(props.tabName === "Landscape Oil Painting"){
      geneList = [
        { id: "feature_0_trees+ve", name: "Trees", maximum: "1.5", minimum: "-1.5" },
        { id: "feature_12_greenary+ve", name: "Greenary",maximum: "1.5", minimum: "-1.5" },
        { id: "feature_10_slope_treses", name: "Slope", maximum: "1.5", minimum: "-1.5"},
        { id: "feature_4_Mountain+ve", name: "Mountain", maximum: "1.5", minimum: "-1.5"},
        { id: "feature_20_smooth+ve", name: "Smooth",maximum: "1.5", minimum: "-1.5"},
        { id: "feature_14_grass+ve", name: "Grass", maximum: "1.5", minimum: "-1.5" },
        { id: "feature_46_long_grasses-ve", name: "Long Grasses", maximum: "1.5", minimum: "-1.5" },
        { id: "feature_16_tree_branches+ve", name: "Tree Branches", maximum: "1.5", minimum: "-1.5"},
        { id: "feature_6_flowers-ve", name: "Flowers",maximum: "1.5", minimum: "-1.5"},
        { id: "feature_1_pond-ve", name: "Pond",maximum: "1.5", minimum: "-1.5" },
        { id: "feature_23_gradients-ve", name: "Gradients",maximum: "1.5", minimum: "-1.5" },
        { id: "feature_18_white_shade-ve", name: "White Shade", maximum: "1.5", minimum: "-1.5" },
        { id: "feature_27_scenary-ve", name: "Scenary",maximum: "1.5", minimum: "-1.5"},
        { id: "feature_9_sea-ve", name: "Sea", maximum: "1.5", minimum: "-1.5" },
        { id: "feature_3_forest-ve", name: "Forest",maximum: "1.5", minimum: "-1.5"},
      ];
      setGenesList(geneList);
      setModelId("landscape_oil_painting_1024")
    }

    if(props.tabName === "Metal Art"){
      geneList = [
        { id: "25_purple", name: "Purple", maximum: "3", minimum: "-3" },
        { id: "37_darkred_brown", name: "Darkred Brown", maximum: "3", minimum: "-3" },
        { id: "47_darkpink_red", name: "Darkpink Red", maximum: "3", minimum: "-3" },
        { id: "41_purple_lightblue", name: "Purple Lightblue", maximum: "3", minimum: "-3" },
        { id: "36_blue_orange", name: "Blue Orange", maximum: "3", minimum: "-3" },
        { id: "34_darkgreen_darkred", name: "Darkgreen Darkred", maximum: "3", minimum: "-3" },
        { id: "23_white_red", name: "White Red", maximum: "3", minimum: "-3" },
        { id: "28_darkbrown", name: "Darkbrown", maximum: "3", minimum: "-3" },
        { id: "26_white_red", name: "White Red", maximum: "3", minimum: "-3" },
        { id: "30_darkred_springgreen", name: "Darkred Springgreen", maximum: "3", minimum: "-3" },
        { id: "49_red_white", name: "Red White", maximum: "3", minimum: "-3" },
        { id: "44_green_red", name: "Green Red", maximum: "3", minimum: "-3" },
        { id: "24_black_brown", name: "Black Brown", maximum: "3", minimum: "-3" },
        { id: "22_white_darkred", name: "White Darkred", maximum: "3", minimum: "-3" },
        { id: "21_orange_black", name: "Orange Black", maximum: "3", minimum: "-3" },
        { id: "31_lightblue", name: "Lightblue", maximum: "3", minimum: "-3" },
        { id: "35_blue_brown", name: "Blue Brown", maximum: "3", minimum: "-3" },
        { id: "32_blue_pink", name: "Blue Pink", maximum: "3", minimum: "-3" },
        { id: "33_springgreen", name: "Spring Green", maximum: "3", minimum: "-3" },
        { id: "50_yellow", name: "Yellow", maximum: "3", minimum: "-3" },
      ];
      setGenesList(geneList);
      setModelId("metal_album_art_512")
    }
    if(props.tabName === "SciFi"){
      geneList = [
        { id: "feature_28_orange_reconstructed_image", name: "Orange Reconstructed", maximum: "3", minimum: "-3" },
        { id: "feature_14_contrast_reshaping", name: "Contrast Reshaping", maximum: "3", minimum: "-3" },
        { id: "feature_41_dark_or_yellow_shade", name: "Dark Or Yellow Shade", maximum: "3", minimum: "-3" },
        { id: "feature_16_gradient_abstract_shade", name: "Gradient Abstract Shade", maximum: "3", minimum: "-3" },
        { id: "feature_31_blue_and_yellow_shade", name: "Blue And Yellow Shade", maximum: "3", minimum: "-3" },
        { id: "feature_35_Front_and_backgroung_reconstruction", name: "Front And Background", maximum: "3", minimum: "-3" },
        { id: "feature_4_red_or_yellow_or_green_shadow", name: "Red Or Yellow Or Green Shadow", maximum: "3", minimum: "-3" },
        { id: "feature_1_blue_shade_reconstructed_image", name: "Blue Shade Reconstructed", maximum: "3", minimum: "-3" },
        { id: "feature_11_gradient_reshaping", name: "Gradient Reshaping", maximum: "3", minimum: "-3" },
        { id: "feature_8_yellow_and_blue_reshaped_image", name: "Yellow And Blue Reshaped", maximum: "3", minimum: "-3" },
        { id: "feature_5_recoloring_and_reshaping_image", name: "Recoloring And Reshaping", maximum: "3", minimum: "-3" },
        { id: "feature_50_red_background", name: "Red Background", maximum: "3", minimum: "-3" },
        { id: "feature_23_green_shade_reconstructed_image", name: "Green Shade Reconstructed", maximum: "3", minimum: "-3" },
        { id: "feature_36_orange_shade", name: "Orange Shade", maximum: "3", minimum: "-3" },
        { id: "feature_34_noise_reshaping_image", name: "Noise Reshaping Image", maximum: "3", minimum: "-3" },
        { id: "feature_42_yellow_or_blue_shade", name: "Yellow Or Blue Shade", maximum: "3", minimum: "-3" },
        { id: "feature_6_sharp_reconstructed_image", name: "Sharp Reconstructed", maximum: "3", minimum: "-3" },
        { id: "feature_12_dull_shade_image_formation", name: "Dull Shade Image Formation", maximum: "3", minimum: "-3" },
        { id: "feature_37_bright_background_reshaped_image", name: "Bright Background Reshaped Image", maximum: "3", minimum: "-3" },
        { id: "feature_22_golden_or_green_shade", name: "Golden Or Green Shade", maximum: "3", minimum: "-3" },
        { id: "feature_0_remixed_and_reshaped_image", name: "Remixed And Reshaped Image", maximum: "3", minimum: "-3" },
        { id: "feature_33_contrast_reconstructed_image", name: "Contrast Reconstructed Image", maximum: "3", minimum: "-3" },
        { id: "feature_26_saturation_reshaped_image", name: "Saturation Reshaped Image", maximum: "3", minimum: "-3" },        
        { id: "feature_32_reconstructed_frontground", name: "Reconstructed Frontground", maximum: "3", minimum: "-3" },
      ];
      setGenesList(geneList);
      setModelId("scifi_1024")
    }
    if(props.tabName === "Abstract Art"){
      geneList = [
        { id: "feature_4_reconstructed_image", name: "Reconstructed Image", maximum: "2", minimum: "-2" },
        { id: "feature_15_background_color_changing", name: "Background Color Changing", maximum: "2", minimum: "-2" },
        { id: "feature_24_recoloring_reshaping", name: "Recoloring Reshaping", maximum: "2", minimum: "-2" },
        { id: "feature_17_color_desighn_changing", name: "Color Design Change", maximum: "2", minimum: "-2" },
        { id: "feature_9_frontground_reducing_and_reconstructed", name: "Frontground Reducing", maximum: "2", minimum: "-2" },
        { id: "feature_43sharpened_reshaping", name: "Sharpened Reshaping", maximum: "2", minimum: "-2" },
        { id: "feature_3_particles", name: "Particles", maximum: "2", minimum: "-2" },
        { id: "feature_50_gradient_reshaping", name: "Gradient Reshaping", maximum: "2", minimum: "-2" },
        { id: "feature_12_yellow_shade_reconstructed_image", name: "Yellow Shade", maximum: "2", minimum: "-2" },
        { id: "feature_0_remixing_front_and_background", name: "Remixing Background", maximum: "2", minimum: "-2" },
        { id: "feature_26_reshaped_artistic_art", name: "Reshaped Artistic Art", maximum: "2", minimum: "-2" },
        { id: "feature_40_some_frontground_changes", name: "Frontground Changes", maximum: "2", minimum: "-2" },
        { id: "feature_19_resizing_reshaped_image", name: "Resizing Reshaped", maximum: "2", minimum: "-2" },
        { id: "feature_27_value_artistic", name: "Value Artistic", maximum: "2", minimum: "-2" },
        { id: "feature_14_some_dark_reconstructed_components", name: "Dark", maximum: "2", minimum: "-2" },
        { id: "feature_38_reshaped_some_bright_colors", name: "Reshaped Colors", maximum: "2", minimum: "-2" },
        { id: "feature_48_color_shade", name: "Color Shade", maximum: "2", minimum: "-2" },
        { id: "feature_11_red_shade", name: "Red Shade", maximum: "2", minimum: "-2" },
        { id: "feature_13_sky_shade", name: "Sky Shade", maximum: "2", minimum: "-2" },
        { id: "feature_8_yellow_orange_shade", name: "Yellow Orange Shade", maximum: "2", minimum: "-2" },
      ];
      setGenesList(geneList);
      setModelId("new_abstract_art_1024")
    }

    if(props.tabName === "Night Sky"){
      geneList = [
        { id: "full_night_sky_-ve", name: "Full Night Sky", maximum: "4", minimum: "-4" },
       
        { id: "rocks_height+shape_-ve", name: "Rocks Height shape", maximum: "4", minimum: "-4" },
        { id: "trees_snow_land_-ve", name: "Trees Snow Land", maximum: "4", minimum: "-4" },
        { id: "greenish_yellow_shade_-ve", name: "Greenish Yellow Shade", maximum: "4", minimum: "-4" },
       
        { id: "tree_shape_rock_-ve", name: "Tree Shape Rock", maximum: "4", minimum: "-4" },
        { id: "snow_like_effect_+ve", name: "Snow Like Effect", maximum: "4", minimum: "-4" },
        { id: "yellow_shade_-ve", name: "Yellow Shade", maximum: "4", minimum: "-4" },
        
        { id: "dark_brown_rocks_+ve", name: "Dark Brown Rocks", maximum: "4", minimum: "-4" },
        { id: "green_shade_chaos_+ve", name: "Green Shade Chaos", maximum: "4", minimum: "-4" },
        { id: "yellow_shade_sky_-ve", name: "Yellow Shade Sky", maximum: "4", minimum: "-4" },
        { id: "increase_rocks_-ve", name: "Increase Rocks", maximum: "4", minimum: "-4" },
        { id: "purple+green_mixed_shade_flatten_rocks_+ve", name: "Purple Green Mixed", maximum: "4", minimum: "-4" },
        { id: "splits_rocks_-ve", name: "Splits Rocks", maximum: "4", minimum: "-4" },
        { id: "purple_shade+rocks_shape_change_+ve", name: "Purple Shade Rocks", maximum: "4", minimum: "-4" },
        { id: "rocks_height_-ve", name: "Rocks Height", maximum: "4", minimum: "-4" },
        { id: "remove_rocks_clear_sky_-ve", name: "Remove Rocks Clear Sky", maximum: "4", minimum: "-4" },
        { id: "chaos_7", name: "Chaos 1", maximum: "4", minimum: "-4" },
        { id: "chaos_5", name: "Chaos 2", maximum: "4", minimum: "-4" },
        { id: "chaos_11", name: "Chaos 3", maximum: "4", minimum: "-4" },
        { id: "chaos_19", name: "Chaos 4", maximum: "4", minimum: "-4" },
  
      ];
      setGenesList(geneList);
      setModelId("night_sky_1024")
    }

    if(props.tabName === "Archillect"){
      geneList = [
        { id: "feature_19_Hue", name: "Hue", maximum: "3", minimum: "-3" },
        { id: "feature_37_colorful_front_and_background", name: "Colorful Background", maximum: "3", minimum: "-3" },
        { id: "feature_41_green_shade", name: "Green Shade", maximum: "3", minimum: "-3" },
        { id: "feature_9_orange_green_yellow_shade", name: "Orange Green Yellow", maximum: "3", minimum: "-3" },
        { id: "feature_45_green_back_front_reshaped", name: "Greenback Reshaped", maximum: "3", minimum: "-3" },
        { id: "feature_49_color_fadding", name: "Color Fadding", maximum: "3", minimum: "-3" },
        { id: "feature_34_voilet_back_front_reconstructed", name: "Voilet Back Front", maximum: "3", minimum: "-3" },
        { id: "feature_7_red_shape_sharp_reconstructed_image", name: "Red Shape Sharp", maximum: "3", minimum: "-3" },
        { id: "feature_36_blackness_front_back", name: "Blackness Front Back", maximum: "3", minimum: "-3" },
        { id: "feature_13_frontground_shrinking_dark_shade", name: "Shrinking Dark", maximum: "3", minimum: "-3" },
        { id: "feature_44_reshaped_black_shade", name: "Black Shade", maximum: "3", minimum: "-3" },
        { id: "feature_12_brightness_reducing_shade", name: "Brightness Reducing", maximum: "3", minimum: "-3" },
        { id: "feature_4_reconstructed_image", name: "Reconstructed Image", maximum: "3", minimum: "-3" },
        { id: "feature_38_bright_front_reconstructed", name: "Bright Front", maximum: "3", minimum: "-3" },
        { id: "feature_21_gradient_reshaped_image", name: "Gradient Reshaped", maximum: "3", minimum: "-3" },
        { id: "feature_27_front_reshaped_background_orange", name: "Front Background Orange", maximum: "3", minimum: "-3" },
        { id: "feature_18_background_black_and_some_frontground_visible", name: "Black Frontground Visible", maximum: "3", minimum: "-3" },
        { id: "feature_15_dark_color_shade", name: "Dark Color Shade", maximum: "3", minimum: "-3" },
        { id: "feature_31_front_background_brightness", name: "Front Background Brightness", maximum: "3", minimum: "-3" },
        { id: "feature_40_yellow_or_green_or_red_background", name: "Yellow Green Red", maximum: "3", minimum: "-3" },
        { id: "feature_11_recolored_smooth_image", name: "Recolored Smooth Image", maximum: "3", minimum: "-3" },
        { id: "feature_46_brighter", name: "Brighter", maximum: "3", minimum: "-3" },
        { id: "feature_48_brighter_front_and_back", name: "Brighter Front And Back", maximum: "3", minimum: "-3" },        
        { id: "feature_42_blue_background_and_dark_front", name: "Blue Background Dark Front", maximum: "3", minimum: "-3" },
      ];
      setGenesList(geneList);
      setModelId("archillect_1024")
    }

    if(props.tabName === "New Art"){
      geneList = [
        { id: "new_art_vector_3_Gradient+ve", name: "Gradient", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_12_sharp+ve", name: "Sharp", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_15_smooth+ve", name: "Smooth", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_18_saturation+ve", name: "Saturation", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_10_hue-ve", name: "Hue", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_37_value-ve", name: "Value", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_14_reconstructed_frontground-ve", name: "Reconstructed Frontground", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_21_blue_shade-ve", name: "Blue Shade", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_25_yellow_shade_-ve", name: "Yellow Shade", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_5_abstract_art", name: "Abstract Art", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_6_painting-ve", name: "Painting", maximum: "1.5", minimum: "-1.5" },   
        { id: "new_art_vector_7", name: "Vector 1", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_13", name: "Vector 2", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_11", name: "Vector 3", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_1", name: "Vector 4", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_4", name: "Vector 5", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_16", name: "Vector 6", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_2", name: "Vector 7", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_8", name: "Vector 8", maximum: "1.5", minimum: "-1.5" },
        { id: "new_art_vector_9", name: "Vector 9", maximum: "1.5", minimum: "-1.5" },
      ];
      setGenesList(geneList);
      setModelId("new_art_1024")
    }
    
  }, [props]);
  

  const data1 = JSON.stringify({
    collection_id: collection.toString(),
    model_id: modelId,
    // type: CollectionData?.getCollection.type,
    genes: chaosData,
  });

const config = {
  method: "post",
  url: "https://gan-endpoint.rapidinnovation.tech/apiSeed",
  headers: {
    "Content-Type": "application/json",
  },
  data: data1,
};  // https://810d-34-91-159-207.ngrok.io/apiSeed
// stylegan.rapidinnovation.tech
  const keyGenerator = () => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  // useEffect(() => {
  //   // @ts-ignore
  //   // for (let i of genesList) temp[i.id] = 0.0;
  //   if (CollectionData?.getCollection.type === "CUSTOM") {
  //     setImageUrl(CollectionData?.getCollection.processedImgUrl);
  //   } else if (CollectionData?.getCollection.type === "SEED") {
  //     setImageUrl(CollectionData?.getCollection.artPiece);
  //   }
  //   setImageKey(keyGenerator());
  //   setIsResetAll(false);
  // }, [isResetAll, CollectionData]);

  // useEffect(() => {
  //   if (renderState === true) {
  //     imageShifter();
  //   }
  //   setRenderState(true);
  // }, [resetData]);


      const [seedTimer, setSeedTimer] = useState<any>(null);
    useEffect(() => {
      if(modelId && seedNumber){
      const debounce = ((fn:any, delay:any) => {

        function timeoutFunction() {
        let args = arguments;
        //@ts-ignore
        let context:any = this;
    
            if (seedTimer) {
                clearTimeout(seedTimer);
    
                setSeedTimer(setTimeout(function () {
                    fn.apply(context, args);
                }, delay));
            } else {
              setSeedTimer(setTimeout(function () {
                    fn.apply(context, args);
                }, delay));
            }
        }
        return timeoutFunction()
    })
    // const conso = (()=>{
    //   console.log("Heloooooooooooooo",chaosData)
    // })
    
    debounce(imageShifter,700)
  }
   
  }, [modelId,seedNumber]);


  useEffect(() => {
    if(isResetAll){
    imageShifter()
}
 
}, [isResetAll]);

  // @ts-ignore
  String.prototype.splice = function (idx, rem, str) {
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
  };
  const imageShifter = () => {
    const tempImage = imageUrl;
    setIsLoading(true);
    // @ts-ignore
    axios(config)
      .then(function (response) {
        if (response.data.object_url) {
          setImageUrl(response.data.object_url + "?" + new Date());
        }
        setIsLoading(false);
        setImageKey(keyGenerator());
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        swal("Error Building the Image");
      });
      if(isResetAll){
        setIsResetAll(false)
      }
  };

  
  // @ts-ignore
  const [tempPass, setTempPass] = useState(
    JSON.parse(localStorage.getItem("password") as string) || false
  );
  const [tempPassword, setTempPassword] = useState("");
  const [timer, setTimer] = useState<any>(null);
  // let timer:any   = null;
    useEffect(() => {
      if(Object.keys(chaosData).length !== 0){
      const debounce = ((fn:any, delay:any) => {

        function timeoutFunction() {
        let args = arguments;
        //@ts-ignore
        let context:any = this;
    
            if (timer) {
                clearTimeout(timer);
    
                setTimer(setTimeout(function () {
                    fn.apply(context, args);
                }, delay));
            } else {
                setTimer(setTimeout(function () {
                    fn.apply(context, args);
                }, delay));
            }
        }
        return timeoutFunction()
    })
    // const conso = (()=>{
    //   console.log("Heloooooooooooooo",chaosData)
    // })
    debounce(imageShifter,700)
  }
  }, [chaosData]);

  return (
    <>
      <Grid
        className={classes.root}
        container
        justifyContent="center"
        direction="row"
      >
        <Grid item xs={12} md={6}         style={{marginTop: "10px"}}>
          {imageUrl.length === 0 ? (
            <SpinningLoader />
          ) : 
            <div style={{ position: "relative" }}>

<SeedSlider
                              name={"Choose Art Image"}
                              key={"id"}
                              maximum={99999}
                              minimum={1}
                              // @ts-ignore
                              value={seedNumber}
                              onChange={(event: any, val: any) => {
                                setChaosData({})
                                setSeed(val)
                                if(val > 999){
                                  setCollection(val)
                                }
                                else if(val < 999 && val > 99){
                                  setCollection("0"+val.toString())
                                  }
                                  else if(val < 99 && val > 9){
                                  setCollection("00"+val.toString())
                                  }
                                  else if(val < 9 && val > 0){
                                  setCollection("000"+val.toString())
                                  }
                                setSeedNumber(val);
                              }}
                              setSeedNumber={setSeedNumber}
                              setCollection={setCollection}
                              seed={seed}
                              setSeed={setSeed}
                              setChaosData={setChaosData}
                              // }}
                              // onMouseOut={() => imageShifter()}
                              // removeGene={() => removeGene(geneName.id)}
                              // onResetClick={() => {
                              //   // @ts-ignore
                              //   if (chaosData[geneName.id] !== 0) {
                              //     //@ts-ignore

                              //     setChaosData({
                              //       ...chaosData,
                              //       [geneName.id]: 0,
                              //     });
                              //     setResetData(!resetData);
                              //   }
                              // }
                            // }
                            />
              <img
                width="90%"
                height="50%"
                src={imageUrl}
                alt="Problem In Loading GAN Generation"
                style={isLoading ? {opacity: "0.5"} : {}}
                key={new Date().getTime()}
              />
              {isLoading? <span style={{position: "absolute", bottom: "40%", left:"40%"}}><SpinningLoader/></span>: null}
              {Object.keys(chaosData).length > 0 ? 
              <div
                style={{
                  position: "absolute",
                  right: "10%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // window.location.reload();
                  // setIsResetAll(true);
                  setChaosData({})
                  setIsResetAll(true)
                }}
              >
                <img
                  src={resetBack}
                  alt="Reset"
                  height="15px"
                  style={{ marginRight: "5px" }}
                />
                Reset back
              </div>:null}
            </div>
         }
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item container md={12}>
            <Grid container className={classes.box}>
              <Grid item container spacing={2}>
                {isLoading ? (
                  <>
                    {genesList && genesList.length != 0 ? (
                      <>
                        {genesList && genesList.map((geneName: any) => (
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={3}
                            style={{ opacity: "0.5" }}
                          >
                            <ChaosSlider
                              name={geneName.name.split("_").join(" ")}
                              key={geneName.id}
                              // @ts-ignore
                              value={chaosData[geneName.id]}
                              maximum={geneName.maximum}
                              minimum={geneName.minimum}
                            />
                          </Grid>
                        ))}
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            minHeight: "300px",
                            margin: "auto",
                            paddingTop: "100px",
                          }}
                        >
                          No Gene Selected{" "}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {genesList && genesList.length != 0 ? (
                      <>
                        {genesList && genesList.map((geneName: any) => (
                          <Grid item xs={12} md={4} xl={3}>
                            <ChaosSlider
                              name={geneName.name.split("_").join(" ")}
                              key={geneName.id}
                              maximum={geneName.maximum}
                              minimum={geneName.minimum}
                              step={geneName.step ? parseFloat(geneName.step) : 0.1 }
                              // @ts-ignore
                              value={chaosData[geneName.id]}
                              onChange={(event: any, val: any) => {
                                setChaosData({
                                  ...chaosData,
                                  [geneName.id]: val,
                                });
                                
                              }}
                              onMouseOut={() => imageShifter()}
                              // removeGene={() => removeGene(geneName.id)}
                              onResetClick={() => {
                                // @ts-ignore
                                if (chaosData[geneName.id] !== 0) {
                                  //@ts-ignore

                                  setChaosData({
                                    ...chaosData,
                                    [geneName.id]: 0,
                                  });
                                  setResetData(!resetData);
                                }
                              }}
                            />
                          </Grid>
                        ))}
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            minHeight: "300px",
                            margin: "auto",
                            paddingTop: "100px",
                          }}
                        >
                          No Gene Selected{" "}
                        </div>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ArtBuilder;
