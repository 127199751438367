import styled from "styled-components";

export const Parentdiv = styled.div`
  background: linear-gradient(to right, #66ccff, #be61ff);
  width: 100%;
`;

export const Navdiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #080135, #A230BA);
  max-height: 80px;
  min-height: 80px;
  * {
  }

  @media (max-width: 900px) {
    justify-content: space-between;
    padding: 0 20px;
    max-height: 50px;
    min-height: 50px;
  }
`;

export const NavFirst = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    padding: 18px 3%;
  }
  @media (max-width: 900px) {
    width: 20%;
    display: none;
  }
`;
export const Mobileimg = styled.img`
  display: none;
  padding: 0;
  margin: 0;
  max-width: 15%;

  @media (max-width: 900px) {
    display: flex;
  }
`;

export const NavSecond = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const Mobilenavsecond = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: flex;

    img {
      cursor: pointer;
    }
  }
`;

export const Logoimg = styled.img`
  width: 11em;
  height: 100%;
  margin-right: 10px;
`;

export const NavSearchbar = styled.input`
  padding: 10px;
  background-color: white;
  color: black;
  border: 1px solid white;
  border-radius: 2px;

  @media (max-width: 960px) {
    width: 20%;
  }

  &::placeholder {
    color: placeholder;
    font-weight: 600;
    font-size: 14px;
  }
  &:focus {
    outline: none !important;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

export const PointsDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40%;
  margin-right: 10px;
`;

export const Pointsimg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5%;
`;

export const Navtext = styled.h1`
width: 25%;
  color: white;
  font-size: 16px;
`;

export const Bellimg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5%;
`;
export const Userimg = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 5%;
  border-radius: 50%;
  object-fit: contain;

`;
export const Flexdiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5%;
  max-height: 50px;
`;

export const Flexdiv2 = styled.div`
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  /* margin-right: 5%; */
  /* cursor: pointer; */

  /* > * {
    padding: 18px 0%;
  } */

  /* :hover {
    border-bottom: 2px solid white;

    > * {
      display: flex;
    }
  } */
`;

export const Dropdown = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 130%;
  left: 0;
  top: 100%;
  border: 1px solid #bd60fe;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 0px 0px 4px 4px;
  border-top: none;
  z-index: 5;
  background-color: white;
`;

export const NavBelow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 0 3%;
`;

export const BelowText = styled.div`
  display: flex;
  color: black;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  width: 25%;

  @media (max-width: 1260px) {
    width: 45%;
  }
`;

export const BelowH3 = styled.h3`
  color: black;
  font-weight: 500;
  font-size: 13px;
  margin-right: 4%;
`;

export const BelowFilter = styled.div`
  color: black;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  margin-right: 2%;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
`;

export const Burgericon = styled.img``;

export const Sidebar = styled.div`
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  width: 70%;
  height: 100%;
  background-color: white;
  z-index: 2;
  top: 0;
  right: 0;
`;

export const Sidebaroverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3;
`;

export const Sidebarclose = styled.img`
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 2;
`;
