//import React from "react";
import React, { Component, useState, useEffect } from "react";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { createCollection } from "../../../../logic/actions/wallet";
import { object, string, number, mixed, date } from "yup";
import swal from "sweetalert";
import {
  InactiveBTN,
  Profilepicinput,
  TelegramDiv,
  InputImg,
} from "../ProfileManagement/style";
import {
  UseProccessedImage,
  processingLibrarySelectedImage,
} from "../../../../logic/actions/wallet";
import earningInactive from "../../../../assets/icons/earning-inactive.svg";
import Squared from "../../../../assets/image/Squared.png";
import upload from "../../../../assets/icons/upload.svg";
import Scheduleicon from "../../../../assets/icons/Scheduleicon.svg";
import MakeOfferVector from "../../../../assets/icons/MakeOfferVector.svg";
import HandsIcon from "../../../../assets/icons/Hands.png";
import PublishIcon from "../../../../assets/icons/PublishIcon.png";

import DateIcon from "../../../../assets/icons/31.svg";
/* @ts-ignore */
import Checked from "../../../../assets/icons/Checked.svg";
// import DayPicker from 'react-day-picker';
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import DateFrame from "../../../../assets/icons/DateFrame.png";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useHistory } from "react-router-dom";

import UploadImagePopUp from "./UploadImagePopup";

import {
  CollectionPage,
  UploadSection,
  LeftSide,
  H3,
  H4,
  RightSide,
  IMG,
  BTN,
  BTN2,
  Row3,
  Input,
  Span,
  InfoSection,
  Title,
  EarningBox,
  EarningBelow,
  Modal,
  BTNimg,
  ScheduleDiv,
  DatePickerStyled,
  Label,
  LineThrough,
} from "./style";

import { PopUpContent, LineHR } from "../Popups/Bid";

import { H1, StatusInput, UploadButton } from "../ProfileManagement/style";
import { ClosePopUp, Popup, PopUpOverlay } from "../Login/style";
import axios from "axios";
import { create } from "yup/lib/Reference";
import PopUp from "./PopUp";

interface collectionDataType {
  collectionName: string;
  selectedFile: File | undefined;
  themename: string;
  nftnumber: number;
  componentsnumber: number;
  initialprice: number;
  incrementprice: number;
}
enum status {
  publish = 2,
  schedule = 1,
  draft = 0,
}

function Createcollection() {
  const [collectionData, setCollectionData] = useState<collectionDataType>({
    collectionName: "",
    selectedFile: undefined,
    themename: "",
    nftnumber: 5000,
    componentsnumber: 15,
    initialprice: 0.0001,
    incrementprice: 0.0001,
  });

  const [scheuleDate, setScheudleDate] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [showButton, setShowButton] = useState("block");
  const createScheduleValidationSchema = object().shape({
    scheuleDate: date().required(),
  });
  const checkDataeValid = (Date: any) => {
    const isFulfiled = (): boolean => {
      createScheduleValidationSchema
        .isValid(Date)
        .then((valid) => {
          return valid;
        })
        .catch((err) => err);
      return false;
    };
    if (!isFulfiled()) {
      createScheduleValidationSchema.validate(scheuleDate).catch((err) => {
        swal({
          title: err.name,
          text: err.errors[0],
          icon: "warning",
          dangerMode: true,
        });
      });
    }
  };

  const history = useHistory();

  const SUPPORTED_IMAGE_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];

  const createCollectionValidationSchema = object().shape({
    collectionName: string().required("Name of the collection required"),
    selectedFile: mixed()
      .required("A Image is required")
      .test(
        "imageFileFormat",
        "Unsupported Format",
        (value: any) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
      ),
    themename: string().required("Name of theme required"),
    nftnumber: number().default(5000),
    componentsnumber: number().default(15),
    initialprice: number().default(1).min(1),
    incrementprice: number().default(1),
  });

  const checkValid = (formData: collectionDataType, event: any) => {
    const isFulfiled = (): boolean => {
      createCollectionValidationSchema
        .isValid(formData)
        .then((valid) => {
          if (valid) {
            setFormValid(true);
            const FormValidity = "true";
            // SubmitData(event,"","",FormValidity)
          }
          return valid;
          // Handle true condition here
        })
        .catch((err) => err);
      return false;
    };
    if (!isFulfiled()) {
      createCollectionValidationSchema.validate(collectionData).catch((err) => {
        swal({
          title: err.name,
          text: err.errors[0],
          icon: "warning",
          dangerMode: true,
        });
        setFormValid(false);
      });
    }
  };

  const [show, setShow] = useState<boolean>(false);
  const [Publishable, setPublishable] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [showTotalComponent, setShowTotalComponent] = useState<boolean>(false);
  const [finalComponentPrice, setFinalComponentPrice] = useState<number>(0);
  const [totalComponents, setTotalComponents] = useState<number>(0);
  const [secondTerm, setSecondTerm] = useState<number>(0);
  const [collectionImage, setCollectionImage] = useState<any>("");
  const [ShowEarnings, setShowEarnings] = useState<string>("none");
  const [showModal, setshowModal] = useState<string>("none");
  const [showOrNot, setShowOrNot] = useState("none");
  const [isScheduled, setisScheduled] = useState<boolean>(false);
  const [collectionMessage, setCollectionMessage] = useState<string>("");
  const [showPublish, setShowPublish] = useState<boolean>(false);
  // const [scheuleDate, setScheudleDate] = useState<string>("")
  const [showDraftModal, setShowDraftModal] = useState(false);
  const dispatch = useDispatch();
  const [toggleDisplay, setToggleDisplay] = useState(false);

  const {
    createCollectionData,
    canCreateCollection,
    loginData,
    processedImage,
    LibraryImageData,
  } = useSelector((state: any) => state.wallet);
  const [formValid, setFormValid] = useState(false);
  const [CollectionImage, setImage] = useState();

  useEffect(() => {
    if (LibraryImageData) {
      setImage(LibraryImageData && LibraryImageData[0]?.imageUrl);
    }
  }, [LibraryImageData]);

  useEffect(() => {
    if (processedImage && processedImage[0].Pimageurl) {
      setImage(processedImage[0].Pimageurl);
    }
  }, [processedImage]);

  const CreateCollection = gql`
    mutation CreateCollectionNew($input: CreateCollectionDto!) {
      createCollection(input: $input) {
        name
      }
    }
  `;

  const UpdatingLibraryImageStatus = gql`
    mutation UpdateLibraryImageStatus($input: UpdateLibraryImageStatusDto!) {
      updateLibraryImageStatus(input: $input) {
        message
      }
    }
  `;

  const [collectionImageURL, setImageURL] = useState("");
  //handling image upload
  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;
    if (!fileList) return;
    const image = URL.createObjectURL(fileList[0]);
    setCollectionImage(image);
    setCollectionData({
      ...collectionData,
      selectedFile: fileList[0],
    });

    /* @ts-ignore */
    const userData: string = JSON.parse(localStorage.getItem("token"));
    var formData = new FormData();
    /* @ts-ignore */
    formData.append("image", fileList[0]);
    axios
      .post(process.env.REACT_APP_BaseURl + "/upload/image", formData, {
        headers: {
          Authorization: `Token ${userData}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status == 201) {
          swal("collection image updated");
          setImageURL(response.data.data.profileImageUrl);
        }
      })
      .catch((error) => {
        swal("error in image upload");
      });

    setTimeout(() => setMessage(""), 3000);
  };

  useEffect(() => {
    if (collectionData.incrementprice !== 1) {
      setPublishable(true);
    }
  }, [collectionData.incrementprice]);

  const [doCreateCollection, { data }] = useMutation(CreateCollection);
  const [doUpdateLibImageStatus, { data: libImageData }] = useMutation(
    UpdatingLibraryImageStatus
  );

  const handleLibImageStatus = (event: any) => {
    if (LibraryImageData) {
      event.preventDefault();
      doUpdateLibImageStatus({
        variables: {
          input: {
            id: LibraryImageData && LibraryImageData[0].id,
            status: "available",
          },
        },
      })
        .then((res) => {
          return true;
        })
        .catch((error) => {
          swal(error.message);
          console.log("error ", error);
          return false;
        });
    }
  };

  //submitting the create collection data
  const SubmitData = (
    event: any,
    scheduledOn?: any,
    saveAsdraft?: any,
    formCheck?: any
  ) => {
    event.preventDefault();
    var Newstatus = 1;
    if (scheduledOn) {
      Newstatus = status.schedule;
    } else {
      if (saveAsdraft) {
        Newstatus = status.draft;
      } else {
        Newstatus = status.publish;
      }
    }
    var imageurl = "";
    var seed;
    var type = " ";
    var processedImageURL = "";
    var modalId = "";
    if (LibraryImageData) {
      imageurl = LibraryImageData && LibraryImageData[0].imageUrl;
      seed = LibraryImageData && LibraryImageData[0].seed;
      type = "SEED";
      modalId = LibraryImageData && LibraryImageData[0].modalid;
    }
    if (processedImage) {
      processedImageURL = processedImage && processedImage[0].Pimageurl;
      type = "CUSTOM";
      seed = processedImage && processedImage[0].seedId;
      imageurl = processedImage && processedImage[0].UploadedImageUrl;
      modalId = processedImage && processedImage[0].modalId;
    }

    doCreateCollection({
      variables: {
        input: {
          name: collectionData.collectionName,
          theme: collectionData.themename,
          scheduledDate: scheuleDate || null,
          numOfNFTs: collectionData.nftnumber || 5000,
          minNumOfComponentsForNFT: collectionData.componentsnumber || 15,
          initialPrice: collectionData.initialprice || 0.0001,
          priceIncrement: collectionData.incrementprice || 0.0001,
          status: Newstatus,
          //artPiece: collectionImageURL,
          processedImgUrl: processedImageURL || null,
          modelId: modalId,
          type: type,
          seed: seed,
          artPiece: imageurl,
        },
      },
    })
      .then((res) => {
        setShowOrNot("none");

        if (!LibraryImageData) {
          swal("Collection Created Successfully");
          // setImage(undefined)
          setCollectionData({
            ...collectionData,
            collectionName: "",
          });
          setCollectionData({
            ...collectionData,
            themename: "",
          });

          // setTimeout(() => history.push("/gallery"), 3000);
          setTimeout(() => window.location.href="/gallery", 3000);
        }

        if (LibraryImageData) {
          doUpdateLibImageStatus({
            variables: {
              input: {
                id: LibraryImageData && LibraryImageData[0].id,
                status: "reserved",
              },
            },
          })
            .then((res) => {
              swal("Collection Created Successfully");
              setCollectionData({
                ...collectionData,
                collectionName: "",
                themename: "",
              });
              // setCollectionData({
              //   ...collectionData,
              //   themename: "",
              // })
              // setImage(undefined)
              // setTimeout(() => history.push("/gallery"), 3000);
              setTimeout(() => window.location.href="/gallery", 3000);
            })
            .catch((error) => {
              swal(error.message);
              console.log("error ", error);
            });
        }
      })
      .catch((error) => {
        swal(error.message);
        console.log("error ", error);
      });
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // const validity = checkValid(collectionData, event);
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  };
  //useEffect for calculating estimated earning
  useEffect(() => {
    const totalcomponents =
      collectionData.nftnumber * collectionData.componentsnumber - 1;
    const secondterm =
      collectionData.initialprice + collectionData.incrementprice;
    const secondComponentPrice = secondterm.toFixed(6);
    setSecondTerm(secondterm);
    const totalincrementprice = totalcomponents * collectionData.incrementprice;
    const finalprice = collectionData.initialprice + totalincrementprice;
    setFinalComponentPrice(finalprice);
    setToggleDisplay(false);
  }, [
    collectionData.incrementprice,
    collectionData.componentsnumber,
    collectionData.initialprice,
    collectionData.nftnumber,
  ]);

  //for the calculation of estimated earning
  useEffect(() => {
    const numberOfcomponents =
      collectionData.nftnumber * collectionData.componentsnumber;
    setTotalComponents(numberOfcomponents);
  }, [collectionData.nftnumber, collectionData.componentsnumber]);

  //handle schedule date
  const handleDateChange = (day: any) => {
    var dateString = day.getTime();
    //var DateInMilliSecond= dateString.getTime();
    setScheudleDate(dateString);
    var todate = new Date(dateString).getDate();
    var tomonth = new Date(dateString).getMonth() + 1;
    var toyear = new Date(dateString).getFullYear();
    var original_date = tomonth + "/" + todate + "/" + toyear;
  };

  const handleDraftModal = (event: any) => {
    event?.preventDefault();
    if (
      collectionData.collectionName !== "" &&
      collectionData.themename !== "" &&
      CollectionImage
    ) {
      const saveAsDraft = true;
      SubmitData(event, "", saveAsDraft);
    } else if (CollectionImage === undefined) {
      swal("Please upload an art piece or choose from library.");
    } else {
      swal("Name and Theme Field is required");
    }
  };
  //showing successfull modal for date scheduled
  const handleSetSchedule = (event: any) => {
    event.preventDefault();

    setisScheduled(true);
    const scheduledOn = true;
    SubmitData(event, scheduledOn);
  };
  //onsubmit method of form
  const SubmitForm = (event: any) => {
    // event.preventDefault()
    if (collectionImage && collectionImage.length < 0) {
      event?.preventDefault();
    }
  };

  const [isImagePopUpOpen, setIsImagePopUpOpen] = useState(false);
  const [order, setOrder] = useState(1);
  const setIsOpen = () => {
    setIsImagePopUpOpen(!isImagePopUpOpen);
  };

  return (
    <CollectionPage onClick={SubmitForm}>
      {
        // loginData?.canCreateCollection ? (
        <>
          {isImagePopUpOpen && (
            <PopUp
              setIsOpen={setIsOpen}
              styles={{ width: "70%", textAlign: "center" }}
            >
              <UploadImagePopUp
                order={order}
                setOrder={setOrder}
                setIsOpen={setIsOpen}
              />
            </PopUp>
          )}
          {/* Submit Now Modal Starts */}
          <Modal style={{ display: `${showOrNot}` }}>
            <PopUpOverlay>
              <Popup>
                <BTN
                  onClick={(event) => {
                    event.preventDefault();
                    setShowOrNot("none");
                  }}
                  style={{
                    width: "20%",
                    margin: "2%",
                    border: "none",
                    marginLeft: "100%",
                  }}
                >
                  <h2>X</h2>
                </BTN>
                <img
                  style={{ width: "70px", height: "70px" }}
                  src={PublishIcon}
                  alt=""
                />
                <h3 style={{ marginLeft: "-10%", marginTop: "4%" }}>
                  Publish{" "}
                </h3>

                <PopUpContent style={{ marginTop: "1%" }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur amet labore.{" "}
                </PopUpContent>
                <LineHR
                  style={{
                    border: "1px solid #DEE5EF",
                    width: "100%",
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <BTN2
                    onClick={(event) => SubmitData(event)}
                    style={{
                      background: "#35BCFF",
                      height: "40px",
                      width: "110%",
                      margin: "2%",
                    }}
                  >
                    Submit Now
                  </BTN2>
                </div>
                <ClosePopUp></ClosePopUp>
              </Popup>
            </PopUpOverlay>
          </Modal>
          {/* Submit Now Modal Ends */}

          {/* Schedule Later Modal Starts */}
          <Modal style={{ display: `${showModal}` }}>
            {isScheduled === false ? (
              <ScheduleDiv style={{ borderRadius: "4px", height: "400px" }}>
                <img
                  style={{ height: "50px", width: "50px" }}
                  src={Scheduleicon}
                  alt=""
                />
                <H1 style={{ fontSize: "27px" }}>Schedule a Publish date</H1>
                <DatePickerStyled>
                  Date
                  <br />
                  <DayPickerInput
                    dayPickerProps={{
                      disabledDays: {
                        before: new Date(),
                      },
                    }}
                    onDayChange={(day) => handleDateChange(day)}
                    placeholder="Select date"
                    format="DD/MM/YYYY"
                  />
                </DatePickerStyled>
                <br />
                <DatePickerStyled>
                  Time
                  <br />
                  <DayPickerInput
                    dayPickerProps={{
                      disabledDays: {
                        before: new Date(),
                      },
                    }}
                    onDayChange={(day) => handleDateChange(day)}
                    placeholder="Select time"
                    format="DD/MM/YYYY"
                  />
                </DatePickerStyled>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    alignItems: "center",
                    width: "90%",
                    justifyContent: "space-between",
                    padding: "0px 20%",
                  }}
                >
                  <BTN
                    style={{ height: "40px", width: "45%" }}
                    onClick={(e) => {
                      e.preventDefault();

                      setshowModal("none");
                    }}
                  >
                    Cancel
                  </BTN>
                  <BTN2
                    style={{
                      background: "#35BCFF",
                      height: "40px",
                      width: "45%",
                    }}
                    onClick={(event) => handleSetSchedule(event)}
                  >
                    Submit
                  </BTN2>
                </div>
              </ScheduleDiv>
            ) : (
              <ScheduleDiv style={{ borderRadius: "4px" }}>
                <img
                  style={{ height: "50px", width: "50px" }}
                  src={Scheduleicon}
                  alt=""
                />
                <H1 style={{ fontSize: "27px" }}>Date Scheduling successful</H1>
                {scheuleDate}
                <H4 style={{ width: "80%", textAlign: "center" }}></H4>
                <img src={Checked} style={{ marginTop: "16px" }} alt="" />
              </ScheduleDiv>
            )}
          </Modal>
          {/* Schedule Later Modal Ends */}

          {/* <UploadSection>
				<LeftSide style={{ position: 'relative' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flexStart',
							alignItems: 'center',
							height: '7%',
							marginBottom: '1%',
						}}
					>
						<H1 style={{ marginRight: '7px' }}>Upload art piece</H1>
						<H3 style={{ fontSize: '11px' }}>File types supported:JPG,PNG</H3>
					</div>
					<IMG src={collectionImage || Squared} />
					<Profilepicinput
						type='file'
						id='file'
						accept='image/*'
						onChange={handleImageChange}
					/>
					<Label htmlFor='file'>
						<BTNimg src={upload} />
						Upload
					</Label>
				</LeftSide>
				<RightSide>
					<H1>What is the name of your collection?</H1>
					<Input
						style={{ marginBottom: '5%' }}
						placeholder='Title'
						value={collectionData.collectionName}
						onChange={(e) => {
							setCollectionData({
								...collectionData,
								collectionName: e.target.value,
							});
						}}
					/>
					<H1>What is the theme to follow for this collection?</H1>
					<StatusInput
						style={{ height: '100%', width: '89%', paddingTop: '10px', resize: 'none' }}
						placeholder='Theme-Portrait'
						value={collectionData.themename}
						onChange={(e) =>
							setCollectionData({
								...collectionData,
								themename: e.target.value,
							})
						}
					/>
				</RightSide>
			</UploadSection> */}
          <UploadSection>
            <LeftSide
              style={{ position: "relative" }}
              onClick={() => setIsImagePopUpOpen(!isImagePopUpOpen)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flexStart",
                  alignItems: "center",
                  height: "7%",
                  marginBottom: "1%",
                }}
              >
                <H1 style={{ marginRight: "7px" }}>Upload art piece</H1>
                <H3 style={{ fontSize: "11px" }}>
                  File types supported: JPG, PNG
                </H3>
              </div>
              <IMG src={CollectionImage || Squared} />
              <Label>
                <BTNimg src={upload} />
                Upload
              </Label>
            </LeftSide>
            <RightSide>
              <H1>What is the name of your collection? *</H1>
              <Input
                style={{ marginBottom: "5%" }}
                placeholder="Title"
                value={collectionData.collectionName}
                onChange={(e) => {
                  setCollectionData({
                    ...collectionData,
                    collectionName: e.target.value,
                  });
                }}
              />
              <H1>What is the theme to follow for this collection? *</H1>
              <StatusInput
                style={{
                  height: "100%",
                  width: "89%",
                  paddingTop: "10px",
                  resize: "none",
                }}
                placeholder="Theme-Portrait"
                value={collectionData.themename}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    themename: e.target.value,
                  })
                }
              />
            </RightSide>
          </UploadSection>
          <LineThrough>
            <span>Collection Parameter</span>
          </LineThrough>
          <InfoSection>
            <div>
              <div style={{ marginBottom: "10%" }}>
                <H1>How many NFTs to be created under this collection?</H1>
                <H4>Default - 5000 | Min - 100</H4>
                <Input
                  placeholder="100"
                  type="number"
                  min="100"
                  onChange={(e) =>
                    setCollectionData({
                      ...collectionData,
                      nftnumber: parseInt(e.target.value),
                    })
                  }
                  defaultValue={collectionData.nftnumber}
                />
              </div>
              <div>
                <H1>What is the price of the 1st component in USD</H1>
                <H4>
                  Default - 0.0001 | Max -
                  <span
                    style={{
                      display: "inline-flex",
                      verticalAlign: "middle",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    ∞{" "}
                  </span>
                </H4>
                <Input
                  placeholder="0.025"
                  defaultValue={collectionData.initialprice}
                  type="number"
                  min="0.0001"
                  onChange={(e) =>
                    setCollectionData({
                      ...collectionData,
                      initialprice: parseFloat(e.target.value),
                    })
                  }
                />
              </div>
            </div>
            <div>
              <div style={{ marginBottom: "10%" }}>
                <H1>
                  What is the minimum number of components to create a NFT?
                </H1>
                <H4>Default - 15 | Min - 5 </H4>
                <Input
                  // placeholder="15"
                  defaultValue={collectionData.componentsnumber}
                  type="number"
                  min="5"
                  onChange={(e) => (
                    setCollectionData({
                      ...collectionData,
                      componentsnumber: parseInt(e.target.value),
                    }),
                    setShowTotalComponent(true)
                  )}
                />
              </div>
              <div>
                <H1>
                  What is the price increment for subsequent components in USD
                </H1>
                <H4>
                  Default - 0.0001 | Max -
                  <span
                    style={{
                      display: "inline-flex",
                      verticalAlign: "middle",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    ∞{" "}
                  </span>
                </H4>
                <Input
                  placeholder="0.025"
                  min="0.0001"
                  defaultValue={collectionData.incrementprice}
                  type="number"
                  onChange={(e) =>
                    setCollectionData({
                      ...collectionData,
                      incrementprice: parseFloat(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          </InfoSection>

          {/* <DayPicker   /> */}
          <Row3>
            {Publishable === true ? (
              <BTN2 onClick={(e) => handleLibImageStatus(e)}>Cancel</BTN2>
            ) : (
              <InactiveBTN
                style={{ border: "1px solid #DEE5EF", marginRight: "15px" }}
              >
                Cancel
              </InactiveBTN>
            )}
            {/* {Publishable === true ? (
              <BTN2 onClick={handleDraftModal} style={{ display: showButton }}>
                Save as draft
              </BTN2>
            ) : (
              <InactiveBTN
                style={{ border: "1px solid #DEE5EF", marginRight: "15px" }}
              >
                save as draft
              </InactiveBTN>
            )} */}

            {/* Publishable === true ? (
          <BTN2 
          //onClick={(event) =>{event.preventDefault(); setshowModal("flex")}}
          
          >Schedule</BTN2>
        ) : (
          <InactiveBTN
            style={{ border: "1px solid #DEE5EF", marginRight: "15px" }}
          >
            Schedule
          </InactiveBTN>
        ) */}
            {Publishable === true ? (
              <BTN2
                // onClick={(event) => SubmitData(event)}
                onClick={(event) => {
                  event.preventDefault();
                  if (
                    collectionData.collectionName !== "" &&
                    collectionData.themename !== "" &&
                    CollectionImage
                  ) {
                    setShowOrNot("flex");
                  } else if (CollectionImage === undefined) {
                    swal("Please upload an art peice or choose from library.");
                  } else {
                    swal("Name and Theme Field is required");
                  }
                }}
                style={{ display: showButton }}
              >
                Publish
              </BTN2>
            ) : (
              <InactiveBTN>Publish</InactiveBTN>
            )}
            {collectionMessage}
          </Row3>
        </>
        //	) : (
        //		swal('Sorry Collection Creation is not available at this time')
        //
        //)
      }
    </CollectionPage>
  );
}
export default Createcollection;
