import styled from "styled-components";

export const Homebody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -5%;
`;
export const H2 = styled.h2`
  font-size: 16px;
  font-weight: 500;
  padding-left: 20px;
  padding-top: 7px;
  color: #fff;
`;
export const H3 = styled.h3`
  font-size: 12px;
  font-weight: 500;
  padding-left: 20px;
  color: #fff;
  margin-top: -2px;
`;

export const Container = styled.div`
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  margin-bottom: 5vh;
  text-transform: uppercase;
  width: 90vw;
`;
export const Infodiv = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
`;
export const Btnhover = styled.div`
padding-top:10px;
@media (min-width: 1440px) {
  padding-top:50px;
}
`;
export const Hoverdiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(0, 0, 0, 0.7);
  width: 100%;
  heigth: 100%;
  opacity: 0;
  transition: all .6s ease;  
`;
export const HoverBTN = styled.button`
border-radius: 2px;
padding: 11px 16px;
color: #ffffff;
font-weight: 600;
cursor: pointer;
background-color: rgba(162, 162, 162, 0.5);
font-size: 14px;
box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
border: solid 1.6px transparent;
justify-content: center;
align-items: center;
background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ),
  linear-gradient(101deg, #66ccff, #be61ff);
background-origin: border-box;
background-clip: content-box, border-box;
width: 70%;
display: flex;
margin:10px auto ;
`;

export const ImageComp = styled.div`
  align-items: center;
  background: white;
  background-size: cover;
  background-position: center;
  border: 5px solid white;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-grow: 0;
  font-family: sans-serif;
  font-size: 30px;
  font-weight: bold;
  width: 33%;
  height: 280px;
  justify-content: center;
  outline: 5px solid white;
  text-shadow: 0 0 5px black;
  position: relative;  
  @media (min-width: 1440px) {
    height: 400px;
  }
  &:hover {
    ${Hoverdiv} {
      opacity: 1;
    }
    ${Infodiv} {
      display: none;
    }
  }

 
`;
