import React, { useState, useEffect } from 'react';
import {
	Market,
	Marketabove,
	Image,
	Marketaboveright,
	H1,
	H2,
	H3,
	H4,
	Flexdiv,
	Marketbetween,
	Card,
	Carddiv,
	Marketbelow,
	Mintednftdiv,
	Span,
	Header,
} from '../Marketplace/style';
import Squared from '../../../../assets/image/Squared.png';
import { BTN, BTN2 } from '../Collection2/style';
import { LineThrough } from '../ProfileManagement/style';
// import MUIDataTable from 'mui-datatables';
//import MaterialTable, { MTableToolbar } from 'material-table';
import { Borderdiv, Card1, Chaosinut, H5, Scrolldivs, Slider } from './style';
import mintednft1 from '../../../../assets/image/mintednft1.png';
import mintednft2 from '../../../../assets/image/mintednft2.png';
import mintednft3 from '../../../../assets/image/mintednft3.png';
import settings from '../../../../assets/icons/settings.svg';
import clock from '../../../../assets/icons/clock.svg';
import ether from '../../../../assets/icons/ether.svg';
import questionmark from '../../../../assets/icons/questionmark.svg';
import dollar from '../../../../assets/icons/dollar.svg';
import shopcart from '../../../../assets/icons/shopcart.svg';
import transfer from '../../../../assets/icons/transfer.svg';
import ether2 from '../../../../assets/icons/ether2.svg';
import updown from '../../../../assets/icons/updown.svg';
import Makeoffer from '../Popups/Makeoffer';
import Setting from '../../../../assets/icons/setting.png';
import AddSign from '../../../../assets/icons/AddSign.png';

import Component from '../../../../assets/icons/component.png';
import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextField } from '@material-ui/core';
import {
	Button,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';

import Star from '../../../../assets/icons/Star.png';
import Correct from '../../../../assets/icons/correct.png';
import { Link } from 'react-router-dom';

function ModalBox(props: any) {
	const [open, setOpen] = useState(false);
	const [state, setState] = useState({
		dExpiration: '',
		dUsdPrice: '',
		dprice: '',
		dFrom: '',
	});

	const handleChange = (name: any) => (e: any) => {
		setState({
			...state,
			[name]: e.target.value,
		});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button variant='contained' color='primary' onClick={handleClickOpen}>
				Create New
			</Button>
			<Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
				<DialogTitle id='form-dialog-title'>Subscribe</DialogTitle>
				<DialogContent>
					<DialogContentText>
						To subscribe to this website, please enter your email address here. We will
						send updates occasionally.
					</DialogContentText>
					<TextField
						autoFocus
						margin='dense'
						id='price'
						label='Price'
						type='text'
						value={state.dprice || ''}
						onChange={handleChange('dprice')}
						fullWidth
					/>
					<TextField
						autoFocus
						margin='dense'
						id='usdprice'
						label='USD Price'
						type='text'
						value={state.dUsdPrice || ''}
						onChange={handleChange('dUsdPrice')}
						fullWidth
					/>
					<TextField
						autoFocus
						margin='dense'
						id='Expiration'
						label='Expiration'
						type='text'
						value={state.dExpiration || ''}
						onChange={handleChange('dExpiration')}
						fullWidth
					/>
					<TextField
						autoFocus
						margin='dense'
						id='From'
						label='From'
						type='text'
						value={state.dFrom || ''}
						onChange={handleChange('dFrom')}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						Cancel
					</Button>
					<Button
						onClick={() => {
							props.addDataSource(
								state.dprice,
								state.dUsdPrice,
								state.dExpiration,
								state.dFrom
							);
							setOpen(false);
						}}
						color='primary'
					>
						Add
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function Chaos(props: any) {
	const [NewListingdata, setData] = useState([]);
	useEffect(() => {
		const data = [
			createData('3USDC', '$8USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$9USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$10USD', '3DAYS', 'JOHN'),
			createData('3USDC', '$8USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$9USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$8USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$9USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$10USD', '3DAYS', 'JOHN'),
			createData('3USDC', '$8USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$9USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$8USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$9USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$10USD', '3DAYS', 'JOHN'),
			createData('3USDC', '$8USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$9USD', '2DAYS', 'JOHN'),
			createData('3USDC', '$10USD', '3DAYS', 'JOHN'),
		];
		// @ts-ignore
		setData(data);
	}, []);

	let id = 0;
	function createData(price: any, USDPrice: any, Expiration: any, From: any) {
		id += 1;
		return [price, USDPrice, Expiration, From];
	}
	const addDataSource = (dprice: any, dUsdPrice: any, dExpiration: any, dFrom: any) => {
		const updated = [...NewListingdata];
		// @ts-ignore
		updated.push(createData(dprice, dUsdPrice, dExpiration, dFrom));
		// @ts-ignore
		setData(updated);
	};

	const getMuiTheme = () =>
		createTheme({
			overrides: {
				// @ts-ignore
				MUIDataTableBodyCell: {
					root: {
						width: '190px',
					},
				},
			},
		});

	const ListingTheme = () =>
		createTheme({
			overrides: {
				MuiTypography: {
					h3: {
						fontSize: '16px',
						fontWeight: 500,
					},
				},
				// @ts-ignore
				MUIDataTableBodyCell: {
					root: {
						width: '70px',
						height: '10px',
						fontSize: '12px',
						fontWeight: 500,
					},
				},
			},
		});
	const [Forvar, setForvar] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

	//   const ListingColumns=["Price", "USD price", "Expiration","From"]
	const [tradingData, setTradingData] = useState([
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '3.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '5.2400', '97CC1', 'Alias', '5 Days ago'],
		['Transfer', '6.8500', '77CC1', 'Alias', '1 Days ago'],
		['Sale', '7.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '5.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '1.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '5.2400', '97CC1', 'Alias', '5 Days ago'],
		['Transfer', '6.8500', '77CC1', 'Alias', '1 Days ago'],
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '1.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '1.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '5.2400', '97CC1', 'Alias', '5 Days ago'],
		['Transfer', '6.8500', '77CC1', 'Alias', '1 Days ago'],
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '10.2400', '857CC1', 'Alias', '3 Days ago'],
	]);

	const tradingColumns = [
		{
			name: 'Event',

			options: {
				customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
					return (
						<div>
							{dataIndex % 2 == 0 || dataIndex === 0 ? (
								<img src={transfer} style={{ marginRight: '10px' }} />
							) : (
								<img
									src={shopcart}
									style={{ marginRight: '5px', marginLeft: '-2%' }}
								/>
							)}
							{tradingData[rowIndex][0]}
						</div>
					);
				},
			},
		},
		{
			name: 'Price',
			options: {
				sort: true,
				filter: true,
				customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
					return (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<img src={ether2} style={{ marginRight: '10px' }} />
							{tradingData[rowIndex][1]}
						</div>
					);
				},
			},
		},
		{ name: 'From' },
		{ name: 'To' },
		{ name: 'Date' },
	];

	const options = {
		checkbox: false,
		filter: false,
		search: true,
		download: false,
		print: false,
		columns: false,
		pagination: true,
		resizableColumns: false,
		selectableRowsHideCheckboxes: true,
		viewColumns: false,
		rowsPerPage: [7],
		rowsPerPageOptions: [5, 10, 15, 20],
	};

	const ListingOptions = {
		responsive: 'scrollMaxHeight',
		filter: true,
		filterType: 'dropdown',
		// @ts-ignore
		/*    customSort: (data, colIndex, order) => {
     // @ts-ignore
        return data.sort((a, b) => {
        return (
          (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) *
          (order === "desc" ? 1 : -1)
        );
      });
    }, */
		//@ts-ignore

		customHeadRender: ({ index, ...column }) => {
			return (
				<div>
					<img src={questionmark} style={{ marginRight: '10px', marginLeft: '-1%' }} />
				</div>
			);
		},

		search: false,
		download: false,
		print: false,
		pagination: false,
		resizableColumns: false,
		selectableRowsHideCheckboxes: true,
		viewColumns: false,
	};
	const [listingDetailsData, setListingDetailsData] = useState([
		['4USDC', '$8USD', '1DAYS', 'JOHN'],
		['13USDC', '$8USD', '12DAYS', 'JOHN'],
		['14USDC', '$8USD', '15DAYS', 'JOHN'],
		['15USDC', '$8USD', '2DAYS', 'JOHN'],
		['10USDC', '$8USD', '30DAYS', 'JOHN'],
		['3USDC', '$8USD', '2DAYS', 'JOHN'],
		['7USDC', '$8USD', '3DAYS', 'JOHN'],
		['3USDC', '$8USD', '4DAYS', 'JOHN'],
		['6USDC', '$8USD', '5DAYS', 'JOHN'],
		['3USDC', '$8USD', '2DAYS', 'JOHN'],
		['3USDC', '$8USD', '7DAYS', 'NYN'],
		['5USDC', '$8USD', '6DAYS', 'CDF'],
		['3USDC', '$8USD', '20DAYS', 'XYZ'],
	]);

	const ListingColumns = [
		{
			name: 'Price',
			options: {
				sort: true,
				customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
					return (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<img src={dollar} style={{ marginRight: '10px', marginLeft: '-1%' }} />
							{listingDetailsData[rowIndex][0]}
						</div>
					);
				},
			},
		},
		{ name: 'USD price', options: { sort: true } },
		{ name: 'Expiration', options: { sort: true } },
		{ name: 'From', options: { sort: true } },
		{
			name: '',
			options: {
				filter: false,
				customBodyRenderLite: (dataIndex: any) => {
					return (
						<BTN2
							style={{ padding: '3px 10px', background: '#35BCFF' }}
							onClick={handleClick}
						>
							Buy
						</BTN2>
					);
				},
			},
		},
	];
	const handleClick = () => {};
	return (
		<>
			<Market>
				<Marketabove>
					<Image>
						<img src={Squared} alt='' />
					</Image>
					<Marketaboveright>
						<H1>Day in a Chinese Shop</H1>
						<Flexdiv style={{ marginBottom: '25px' }}>
							<img src={settings} style={{ marginRight: '10px' }} />
							<H2 style={{ marginBottom: '0', fontSize: '25px' }}>Chaos </H2>
							<H5 style={{ padding: '4px' }}>(3% have this trait)</H5>
						</Flexdiv>
						<Borderdiv style={{ width: '90%' }}>
							<H3 style={{ fontSize: '14px' }}>
								<img src={clock} style={{ marginRight: '10px' }} />
								Sale ends in 28 days (october 5th,2021 at 8.05 timezone)
							</H3>
							<Borderdiv style={{ width: '100%', marginTop: '3%' }}>
								<H3 style={{ marginTop: '-15px' }}>Minimum bid</H3>
								<Flexdiv style={{ marginTop: '10px', marginBottom: '20px' }}>
									<img src={ether} style={{ marginRight: '10px' }} />
									<H1 style={{ marginBottom: '0' }}>0.6</H1>
									<H4>($2,253.68)</H4>
								</Flexdiv>
								<Flexdiv>
									<BTN2
										style={{
											padding: '10px 20px',
											fontSize: '15px',
											marginRight: '16px',
										}}
									>
										Buy Now
									</BTN2>
									<BTN2
										style={{
											padding: '10px 20px',
											fontSize: '15px',
											background: '#35BCFF',
										}}
									>
										Make offer
									</BTN2>
								</Flexdiv>
							</Borderdiv>
						</Borderdiv>
					</Marketaboveright>
				</Marketabove>
				<Marketbetween style={{ border: 'none', flexDirection: 'row', width: '87%' }}>
					<div style={{ marginLeft: '-5%', display: 'flex' }}>
						<ModalBox
							addDataSource={(
								dprice: any,
								dUsdPrice: any,
								dExpiration: any,
								dFrom: any
							) => addDataSource(dprice, dUsdPrice, dExpiration, dFrom)}
						/>
						{/* <MaterialTable /> */}
						<MuiThemeProvider theme={ListingTheme()}>
							{/* <MUIDataTable
								title={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<h2>Listing Data </h2>
										<BTN2
											style={{ padding: '3px 10px', background: '#35BCFF' }}
										>
											Counter Offer
										</BTN2>
									</div>
								}
								data={listingDetailsData}
								columns={ListingColumns}
								options={ListingOptions}
							/> */}
						</MuiThemeProvider>
						<div style={{ marginLeft: '5%' }}>
							<MuiThemeProvider theme={ListingTheme()}>
								{/* <MUIDataTable
									title={
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
											}}
										>
											<h2>Listing Data </h2>
											<BTN2
												style={{
													padding: '3px 10px',
													background: '#35BCFF',
												}} // @ts-ignore
											>
												Counter Offer
											</BTN2>
										</div>
									}
									data={listingDetailsData}
									columns={ListingColumns}
									options={ListingOptions}
								/> */}
							</MuiThemeProvider>
						</div>
					</div>
				</Marketbetween>
				<Marketbetween>
					<div
						style={{
							display: 'flex',
							width: '100%',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div>
							<H2 style={{ marginTop: '-3%' }}>
								<img style={{ margin: '8px' }} src={Component} alt='' />
								Component's Inventory{' '}
								<img style={{ marginLeft: '5px' }} src={questionmark} alt='' />
							</H2>
						</div>
					</div>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Header>
								{' '}
								<img style={{ marginRight: '5px' }} src={Setting} alt='st' />
								All{' '}
							</Header>
						</AccordionSummary>
						<AccordionDetails
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							<Header style={{ fontSize: '15px', marginBottom: '2%' }}>
								<img
									style={{ width: '15px', marginRight: '5px' }}
									src={Setting}
									alt='st'
								/>
								Available{' '}
							</Header>
							<Carddiv>
								{Forvar.map((For) => (
									<Link to='/chaos' key={For} style={{ textDecoration: 'none' }}>
										<Card style={{ marginBottom: '1px', marginRight: '10px' }}>
											<H3 style={{ marginBottom: '5px' }}>Chaos</H3>
											<H4 style={{ marginBottom: '5px' }}>
												{For}% have this trait
											</H4>
										</Card>
									</Link>
								))}
							</Carddiv>
							<Header style={{ fontSize: '15px', marginBottom: '2%' }}>
								<img
									style={{ width: '15px', marginRight: '5px' }}
									src={Setting}
									alt='st'
								/>
								Sold out{' '}
							</Header>
							<Carddiv>
								{Forvar.map((For) => (
									<Link to='/chaos' key={For} style={{ textDecoration: 'none' }}>
										<Card style={{ marginBottom: '1px', marginRight: '10px' }}>
											<H3 style={{ marginBottom: '5px' }}>Chaos</H3>
											<H4 style={{ marginBottom: '5px' }}>
												{For}% have this trait
											</H4>
										</Card>
									</Link>
								))}
							</Carddiv>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ marginTop: '15px' }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Header>
								{' '}
								<img style={{ marginRight: '5px' }} src={Setting} alt='st' />
								Owned{' '}
							</Header>
						</AccordionSummary>
						<AccordionDetails
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							<Carddiv>
								{Forvar.map((For) => (
									<Link to='/chaos' key={For} style={{ textDecoration: 'none' }}>
										<Card1 style={{ marginBottom: '1px', marginRight: '10px' }}>
											<img
												src={AddSign}
												style={{ width: '50px', background: 'none' }}
												alt='icon'
											/>
											<H3 style={{ marginBottom: '5px' }}>Chaos</H3>
											<H4 style={{ marginBottom: '5px' }}>
												{For}% have this trait
											</H4>
										</Card1>
									</Link>
								))}
							</Carddiv>
						</AccordionDetails>
					</Accordion>
				</Marketbetween>
				<MuiThemeProvider theme={getMuiTheme()}>
					{/* <MUIDataTable
						title={'Trading history'}
						data={tradingData}
						columns={tradingColumns}
						options={options}
					/> */}
				</MuiThemeProvider>
				<Marketbelow>
					<LineThrough style={{ margin: '20px 0' }}>
						<span
							style={{
								backgroundColor: 'white',
								color: 'black',
							}}
						>
							Minted NFT's in this collection
						</span>
					</LineThrough>
					<Mintednftdiv>
						<img src={mintednft1} alt='' />
						<img src={mintednft2} alt='' />
						<img src={mintednft3} alt='' />
					</Mintednftdiv>
				</Marketbelow>

				{
					//   <Makeoffer Showornot={offeropenornot} statechanger={setofferopenornot} />
				}
			</Market>
		</>
	);
}

export default Chaos;
