import React, { useState } from 'react';
import {
	Market,
	Marketabove,
	Image,
	Marketaboveright,
	H1,
	H2,
	H3,
	H4,
	Flexdiv,
	Marketbetween,
	Card,
	Carddiv,
	Marketbelow,
	Mintednftdiv,
} from '../Marketplace/style';
import { Borderdiv } from '../Chaos/style';
import { LineThrough } from '../ProfileManagement/style';
import mintednft1 from '../../../../assets/image/mintednft1.png';
import mintednft2 from '../../../../assets/image/mintednft2.png';
import mintednft3 from '../../../../assets/image/mintednft3.png';
import { BTN2 } from '../Collection2/style';
import settings from '../../../../assets/icons/settings.svg';
import clock from '../../../../assets/icons/clock.svg';
import Squared from '../../../../assets/image/Squared.png';
import ether from '../../../../assets/icons/ether.svg';
import questionmark from '../../../../assets/icons/questionmark.svg';
import dollar from '../../../../assets/icons/dollar.svg';
import shopcart from '../../../../assets/icons/shopcart.svg';
import transfer from '../../../../assets/icons/transfer.svg';
import creator from '../../../../assets/icons/creator.png';
import More from '../../../../assets/icons/More.png';
import ether2 from '../../../../assets/icons/ether2.svg';
import Reward from '../../../../assets/icons/Reward.png';
//@ts-ignore

import Card1 from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

//@ts-ignore
// import MUIDataTable from 'mui-datatables';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Upload from '../../../../assets/icons/Upload1.png';
import Upload1 from '../../../../assets/icons/Upload2.png';
import Share from '../../../../shared/components/Share';
import { ClaimButton, RewardText, RewardValue } from './style';
function Themepage() {
	const [readMore, setReadMore] = useState<boolean>(false);
	const [share, setShare] = useState(false);

	const getMuiTheme = () =>
		createTheme({
			overrides: {
				MuiTypography: {
					h3: {
						fontSize: '16px',
						fontWeight: 500,
					},
				},
				// @ts-ignore
				MUIDataTableBodyCell: {
					root: {
						width: '190px',
						marginRight: '10px',
						fontSize: '14px',
						fontWeight: 500,
					},
				},
			},
		});
	// });

	const ListingTheme = () =>
		createTheme({
			overrides: {
				MuiTypography: {
					h3: {
						fontSize: '16px',
						fontWeight: 500,
					},
				},
				// @ts-ignore
				MUIDataTableBodyCell: {
					root: {
						width: '90px',
						height: '10px',
						fontSize: '12px',
						fontWeight: 500,
					},
				},
			},
		});
	const [Forvar, setForvar] = useState<number[]>([
		1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
	]);
	const [tradingData, setTradingData] = useState([
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '3.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '5.2400', '97CC1', 'Alias', '5 Days ago'],
		['Transfer', '6.8500', '77CC1', 'Alias', '1 Days ago'],
		['Sale', '7.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '5.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '1.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '5.2400', '97CC1', 'Alias', '5 Days ago'],
		['Transfer', '6.8500', '77CC1', 'Alias', '1 Days ago'],
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '1.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '1.2400', '857CC1', 'Alias', '3 Days ago'],
		['Sale', '5.2400', '97CC1', 'Alias', '5 Days ago'],
		['Transfer', '6.8500', '77CC1', 'Alias', '1 Days ago'],
		['Sale', '1.2400', '97CC1', 'Alias', '3 Days ago'],
		['Transfer', '10.2400', '857CC1', 'Alias', '3 Days ago'],
	]);

	const tradingColumns = [
		{
			name: 'Event',

			options: {
				customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
					return (
						<div>
							{dataIndex % 2 == 0 || dataIndex === 0 ? (
								<img src={transfer} style={{ marginRight: '10px' }} />
							) : (
								<img
									src={shopcart}
									style={{ marginRight: '5px', marginLeft: '-2%' }}
								/>
							)}
							{tradingData[rowIndex][0]}
						</div>
					);
				},
			},
		},
		{
			name: 'Price',
			options: {
				sort: true,
				filter: true,
				customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
					return (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<img src={ether2} style={{ marginRight: '10px' }} />
							{tradingData[rowIndex][1]}
						</div>
					);
				},
			},
		},
		{ name: 'From' },
		{ name: 'To' },
		{ name: 'Date' },
		/* {name:"",
                            options:{
                              customBodyRenderLite: (dataIndex:any) => {
                                return (
                                <img src={ether} style={{marginRight:'10px'}}
                                        
                                )}},},*/
	];

	const options = {
		checkbox: false,
		filter: false,
		search: true,
		download: false,
		print: false,
		columns: false,
		pagination: true,
		resizableColumns: false,
		selectableRowsHideCheckboxes: true,
		viewColumns: false,
		rowsPerPage: [7],
		rowsPerPageOptions: [5, 10, 15, 20],
	};

	const [listingDetailsData, setListingDetailsData] = useState([
		['4USDC', '$8USD', '1DAYS', 'JOHN'],
		['13USDC', '$8USD', '12DAYS', 'JOHN'],
		['14USDC', '$8USD', '15DAYS', 'JOHN'],
		['15USDC', '$8USD', '2DAYS', 'JOHN'],
		['10USDC', '$8USD', '30DAYS', 'JOHN'],
		['3USDC', '$8USD', '2DAYS', 'JOHN'],
		['7USDC', '$8USD', '3DAYS', 'JOHN'],
		['3USDC', '$8USD', '4DAYS', 'JOHN'],
		['6USDC', '$8USD', '5DAYS', 'JOHN'],
		['3USDC', '$8USD', '2DAYS', 'JOHN'],
		['3USDC', '$8USD', '7DAYS', 'JOHN'],
		['5USDC', '$8USD', '6DAYS', 'JOHN'],
		['3USDC', '$8USD', '20DAYS', 'JOHN'],
	]);
	const ListingColumns = [
		{
			name: 'Price',

			options: {
				sort: true,
				customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
					return (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<img src={dollar} style={{ marginRight: '10px' }} />
							{listingDetailsData[rowIndex][0]}
						</div>
					);
				},
			},
		},
		{ name: 'USD price', options: { sort: true } },
		{ name: 'Expiration', options: { sort: true } },
		{ name: 'From', options: { sort: true } },
		{
			name: '',
			options: {
				customBodyRenderLite: (dataIndex: any) => {
					return (
						<BTN2
							style={{ padding: '3px 10px', background: '#35BCFF' }}
							onClick={handleClick}
						>
							Buy
						</BTN2>
					);
				},
			},
		},
	];
	const handleClick = () => {
		console.log('button clicked ');
	};
	return (
		<Market>
			<Marketabove style={{ alignItems: 'flex-start' }}>
				<Image style={{ height: '100%', flexDirection: 'column' }}>
					<img style={{ height: '70vh', marginBottom: '20px' }} src={Squared} alt='' />
					<Borderdiv
						style={{ width: '400px', background: '#FBFBFB', marginBottom: '20px' }}
					>
						<div style={{ marginTop: '-2%', marginBottom: '1%' }}>
							<H3 style={{ fontSize: '16px' }}>
								<img src={creator} style={{ marginRight: '5px' }} />
								Creator
								<span style={{ color: '#35BCFF', marginLeft: '3px' }}>
									{' '}
									John Doe
								</span>
							</H3>
						</div>
						<Borderdiv
							style={{ width: '320px', background: '#FFFFFF', marginTop: '2%' }}
						>
							<Flexdiv style={{ marginBottom: '2px' }}></Flexdiv>
							<Flexdiv>
								<div style={{ marginTop: '-4%' }}>
									<p
										style={{
											//@ts-ignore
											fontWeight: '400',
											font: 'Roboto',
											fontSize: '14px',
										}}
									>
										In publishing and graphic design, Lorem ipsum is a
										placeholder text commonly used to demonstrate Lorem ipsum is
										a placeholder text commonly used to demonstrate
									</p>
									{readMore && (
										<p
											style={{
												//@ts-ignore
												fontWeight: '400',
												font: 'Roboto',
												fontSize: '14px',
											}}
										>
											In publishing and graphic design, Lorem ipsum is a
											placeholder text commonly used to demonstrate the visual
											form of a document or1 the visual form of a document or1
										</p>
									)}
									{readMore ? (
										<button
											style={{
												border: 'none',
												padding: '0',
												fontSize: '14px',
												background: 'none',
												whiteSpace: 'nowrap',
												color: '#BD60FE',
												marginTop: '2%',
											}}
											onClick={() => setReadMore(false)}
										>
											Read Less
										</button>
									) : (
										<>
											<img src={More} />
											<button
												style={{
													border: 'none',
													padding: '0',
													fontSize: '14px',
													background: 'none',
													whiteSpace: 'nowrap',
													color: '#BD60FE',
													margin: '1%',
												}}
												onClick={() => setReadMore(true)}
											>
												Read More
											</button>
										</>
									)}
								</div>
							</Flexdiv>
						</Borderdiv>
					</Borderdiv>

					<Marketbetween style={{ width: '100%' }}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginBottom: '5px',
								marginLeft: '5px',
							}}
						>
							<img
								src={settings}
								style={{ marginRight: '10px', marginTop: '-10px' }}
								alt='Settings'
							/>
							<H2 style={{ fontSize: '16px' }}>
								Components
								<img style={{ marginLeft: '5px' }} src={questionmark} alt='' />
							</H2>
						</div>
						<Carddiv style={{ height: '300px', overflowY: 'scroll' }}>
							{Forvar.map((For) => (
								<Card
									key={For}
									style={{
										marginBottom: '10px',
										marginRight: '10px',
										width: '29%',
									}}
								>
									<H3 style={{ marginBottom: '5px' }}>Chaos</H3>
									<H4 style={{ marginBottom: '5px' }}>{For}% have this trait</H4>
								</Card>
							))}
						</Carddiv>
					</Marketbetween>
				</Image>
				<Marketaboveright style={{ width: '50%', height: '100%' }}>
					<H1>Day in a Chinese Shop</H1>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							position: 'relative',
							marginBottom: '20px',
						}}
					>
						<img
							src={Upload1}
							style={{ position: 'relative', marginTop: '-18%', marginLeft: '8%' }}
						/>
						<img
							src={Upload}
							style={{ position: 'absolute' }}
							onClick={() => setShare(true)}
						/>
						<h4 style={{ marginLeft: '20%', marginTop: '-5%' }}>Share</h4>
					</div>
					<H2 style={{ marginBottom: '10px' }}>Theme </H2>
					<H3 style={{ fontSize: '14px', width: '90%' }}>
						Lorem Ipsum has been the industry's standard since the 1500s, when an
						unknown printer took a galley of type and scrambled it to make a type
						specimen book.
					</H3>
					<Borderdiv style={{ width: '90%', marginTop: '2%' }}>
						<H3 style={{ fontSize: '14px', marginTop: '-1.5%' }}>
							<img src={clock} style={{ marginRight: '10px' }} />
							Sale ends in 28 days (october 5th,2021 at 8.05 timezone)
						</H3>
						<Borderdiv style={{ width: '90%', marginTop: '2%' }}>
							<H3 style={{ marginTop: '-10px', marginLeft: '10px' }}>Minimum bid</H3>
							<Flexdiv
								style={{
									marginBottom: '20px',
									marginLeft: '10px',
									marginTop: '5px',
								}}
							>
								<img src={ether} style={{ marginRight: '10px' }} />
								<H1 style={{ marginBottom: '0' }}>0.6</H1>
								<H4>($2,253.68)</H4>
							</Flexdiv>
							<Flexdiv style={{ margin: '10px' }}>
								<BTN2
									style={{
										padding: '10px 20px',
										fontSize: '15px',
										marginRight: '16px',
									}}
								>
									Buy Now
								</BTN2>
								<BTN2
									style={{
										padding: '10px 20px',
										fontSize: '15px',
										background: '#35BCFF',
									}}
								>
									Make offer
								</BTN2>
							</Flexdiv>
						</Borderdiv>
					</Borderdiv>
					<div
						style={{
							marginTop: '3%',
							marginLeft: '.5%',
							marginBottom: '3%',
							marginRight: '3%',
						}}
					>
						<MuiThemeProvider theme={ListingTheme()}>
							{/* <MUIDataTable
								title={'Listing Details'}
								data={listingDetailsData}
								columns={ListingColumns}
								options={options}
							/> */}
						</MuiThemeProvider>
					</div>
					<Borderdiv
						style={{
							backgroundColor: '#FBFBFB',
							borderRadius: '4px',
							boxSizing: 'border-box',
							border: '1px solid rgba(28, 33, 45, 0.12)',
							width: '100%',
							height: '200px',
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-around',
								marginTop: '1%',
								marginBottom: '2.5%',
							}}
						>
							<img src={Reward} style={{ marginRight: '10px' }} />
							<h4>Gallery Reward</h4>
						</div>
						<Borderdiv
							style={{ backgroundColor: '#FBFBFB', width: '550px', height: '120px' }}
						>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<RewardValue>500</RewardValue>
									<RewardText style={{ marginTop: '25%' }}>
										Accured balance
									</RewardText>
								</div>
								<div>
									<ClaimButton style={{ marginLeft: '85%' }}>
										Claim Now
									</ClaimButton>
								</div>
							</div>
						</Borderdiv>
					</Borderdiv>
				</Marketaboveright>
			</Marketabove>

			<MuiThemeProvider theme={getMuiTheme()}>
				{/* <MUIDataTable
					title={'Trading History'}
					data={tradingData}
					columns={tradingColumns}
					options={options}
				/> */}
			</MuiThemeProvider>
			<Marketbelow style={{ width: '87%' }}>
				<LineThrough style={{ margin: '20px 0' }}>
					<span
						style={{
							backgroundColor: 'white',
							color: 'black',
						}}
					>
						Minted NFT's in this collection
					</span>
				</LineThrough>
				<Mintednftdiv>
					<img src={mintednft1} alt='' />
					<img src={mintednft2} alt='' />
					<img src={mintednft3} alt='' />
				</Mintednftdiv>
			</Marketbelow>
			{console.log('share', share)}
			{share ? <Share url={window.location.href} shareState={share} /> : null}
		</Market>
	);
}

export default Themepage;
