export const GET_BALANCE = 'GET_BALANCE';
export const LOGIN = 'LOGIN';
export const WALLET_CONNECT_CHECK = 'WALLET_CONNECT_CHECK';
export const SET_CHAINID = 'SET_CHAINID';
export const WALLET_BALANCE = 'WALLET_BALANCE';
export const LOGIN_CHECK="LOGIN_CHECK";
export const SIGNUP_CHECK="SIGNUP_CHECK";
export const CREATE_COLLECTION="CREATE_COLLECTION";
export const PROFILE_EDIT="PROFILE_EDIT";
export const AUTH_TOKEN="AUTH_TOKEN";
export const SEND_EMAIL_OTP="SEND_EMAIL_OTP";
export const OTP_VERIFICATION="OTP_VERIFICATION";
export const CHANGE_EMAIL="CHANGE_EMAIL";
export const GOOGLE_LOGIN_INFO="GOOGLE_LOGIN_INFO";
export const ERROR_MESSAGE="ERROR_MESSAGE";
export const CREATE_COLLECTION_STATE="CREATE_COLLECTION_STATE";
export const PROCESSED_IMAGE_URL="PROCESSED_IMAGE_URL";
export const LIBRARY_IMAGE_DATA="LIBRARY_IMAGE_DATA";