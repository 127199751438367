import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ProfileSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;

  @media (max-width: 1200px) {
    width: 100%;
  }


`;
export const UploadSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ChangePasswordSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80%;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const UploadDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  height: 40vh;
`;
export const Toggleside = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
  height: 40vh;
`;
export const InputsDiv = styled.div`
  width: 100%;
`;

export const BTN = styled.button`
  border: 1px solid #dee5ef;
  border-radius: 4px;
  padding: 8px 12px;
  color: #394a64;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  position: absolute;
  right: 10%;
  top: 38px;
`;

export const Div = styled.div`
  border: 1px solid #dee5ef;
  border-radius: 4px;
  padding: 8px 12px;
  color: #394a64;
  font-weight: 600;
  background-color: white;
  position: absolute;
  right: 10%;
  top: 38px;
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 16px;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
 
`;
export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
//  height:100%;
 // 
`;
export const TelegramDiv = styled.div`
  position: relative;
  width: 100%;

  
`;

export const Togglediv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`;

export const Availaiblemsg = styled.h1`
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  left: 3%;
  bottom: 18%;
  color: ${(props) => props.color};
`;

export const Profilepicinput = styled.input`
  display: none;
`;

export const Label = styled.label`
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  color: #394a64;
  border-radius: 4px;
  border: 1px solid #dee5ef;
  cursor: pointer;
`;

export const Usermsg = styled.h1`
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  left: 3%;
  bottom: 0;
  color: ${(props) => props.color};
`;

export const H1 = styled.h1`
  font-size: 16px;
  color: black;
  font-weight: 500;
`;

export const LineThrough = styled.h1`
  font-size: 27px;
  margin: 60px 0 ;
  color: #7c8188;
  font-weight: 500;
  position: relative;
  padding: 0 27%;
  @media (max-width: 768px) {
    font-size: 12px;
    margin: 0;
   }

  ::before {
    content: "";
    display: block;
    width: 38%;
    height: 2px;
    background: #e5e7eb;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -2;
  }
  ::after {
    content: "";
    display: block;
    width: 38%;
    height: 2px;
    background: #e5e7eb;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -2;
  }
`;

export const Input = styled.input`
  width: 90%;
  box-sizing: border-box;
  height: 50px;
  background-color: #fff;
  border-radius: 4px;
  margin: 10px 0;
  padding: 8px 3%;
  border: 1px solid rgba(162, 162, 162, 0.5);
  position: relative;
  :focus {
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to bottom, #66ccff, #be61ff) 1 1;
    outline: none;
    border-radius: 4px;
  }
  ::placeholder {
    color: #a2a2a280;
    font-weight: 400;
    font-size: 14px;
  }
`;
//@ts-ignore
export const StatusInput = styled.textarea`
  width: 83%;
  height: 30px;
  background-color: #fff;
  border-radius: 4px;
  margin: 10px 0;
  padding: 8px 3%;
  border: 1px solid rgba(162, 162, 162, 0.5);
  position: relative;
  font-family: Roboto;

  :focus {
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to bottom, #66ccff, #be61ff) 1 1;
    outline: none;
    border-radius: 4px;
  }
  ::placeholder {
    color: #a2a2a280;
    font-weight: 400;
    font-size: 14px;
    
  }
`;

export const Profileinput = styled.button`
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  color: #7d7d7d;
  border-radius: 4px;
  border: 1px solid #dee5ef;
  cursor: pointer;
`;
export const BTNimg = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 4px;
`;
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 16px;
  width: 30%;
  height: 40px;
  background: linear-gradient(to right, #66ccff, #be61ff);
  color: white;
  border-radius: 4px;
  font-size: 15px;
  border: none;
  margin: 5vh 0;
  cursor: pointer;
`;
export const UploadButton = styled.button`
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #f6f6f6;
  color: #394a64;
  border-radius: 4px;
  border: 1px solid #dee5ef;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;
export const Row = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4%;
  border-top: 1px solid #e5e7eb;
`;
export const BtnDiv = styled.div`
  display: flex;
  width: 76%;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 30px;
`;
export const InputImg = styled.img`
  width: 35px;
  height: 35px;
  background-size: contain;
`;

export const InactiveBTN = styled.button`
  border-radius: 2px;
  padding: 11px 16px;
  color: #a2a2a280;
  font-weight: 600;
  background-color: rgba(162, 162, 162, 0.5);
  margin-right: 4%;
  font-size: 14px;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 1.6px transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #66ccff, #be61ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;


  @media (max-width: 810px) {
    padding: 8px;
  }
`;


export const ProfileImageContainer = styled.div`
  position: relative;
  width: 280px;
  height: 280px;
  background: no-repeat center;
`;
export const ProfileImage = styled.img`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 220px;
  height: 220px;
  background-size: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-right: "3%";
`;
