import React from 'react';
import { ClosePopUp, Popup, PopUpOverlay } from '../Login/style';
import { Modal } from './style';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface Props {
	setIsOpen?: () => void;
	children: JSX.Element;
	styles?: {};
}

const PopUp: React.FC<Props> = (props) => {
	const { setIsOpen, children, styles } = props;
	return (
		<Modal>
			<PopUpOverlay>
				{/* @ts-ignore */}
				<ClickAwayListener onClickAway={setIsOpen}>
					<Popup style={styles}>
						{children}
						<ClosePopUp />
					</Popup>
				</ClickAwayListener>
			</PopUpOverlay>
		</Modal>
	);
};

export default PopUp;
