import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  @media (max-width: 960px) {
    padding: 0 6%;
    justify-content: center;
  }
  @media (max-width: 810px) {
    padding: 0%;
    align-items: center;
    width: 100vw;
    height: 90vh;
  }
`;
export const LoginText = styled.h1`
  font-size: 30px;
  font-weight: 600;
  @media (max-width: 810px) {
    font-size: 18px;
  }
`;

export const Img = styled.img`
  width: 100%;
  background-size: contain;
  object-fit: contain;
  transition: all 0.5s;
  @media (max-width: 960px) {
    display: none;
  }
`;

export const ImageText = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  opacity: 0;
  ${LoginText} {
    background: linear-gradient(to right, #66ccff, #be61ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const LoginFormLeft = styled.div`
  width: 45%;
  height: 80vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-right: 50px;
  border-right: 1px solid black;
  &:hover ${Img} {
    transition: all ease;
  }
  &:hover ${ImageText} {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @media (max-width: 960px) {
    display: none;
  }
`;
export const LoginFormRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  height: 80vh;
  @media (max-width: 810px) {
    width: 65%;
  }
`;

export const Input = styled.input`
  width: 83%;
  height: 30px;
  background-color: #fff;
  border-radius: 4px;
  margin: 15px 0;
  padding: 5px 3%;
  border: 1px solid rgba(162, 162, 162, 0.5);
  :focus {
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to bottom, #66ccff, #be61ff) 1 1;
    outline: none;
    border-radius: 4px;
  }
  ::placeholder {
    color: #a2a2a2;
    font-size: 14px;
  }

  @media (max-width: 810px) {
    width: 84%;
    padding: 3px 10px;

    ::placeholder {
      font-size: 10px;
    }
  }
`;

export const Label = styled.label`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000;
  margin: 5px 0;
`;
export const Checkalign = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
export const CheckBox = styled.input`
  padding: 20px;
`;
export const ForgotPassLink = styled.a`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #bd60fe;

  @media (max-width: 810px) {
    font-size: 10px;
  }
`;

export const RememberLabel = styled.label`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000;

  @media (max-width: 810px) {
    font-size: 12px;
  }
`;
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  width: 90%;
  height: 40px;
  background: linear-gradient(to right, #66ccff, #be61ff);
  color: white;
  border-radius: 4px;
  font-size: 15px;
  border: none;
  cursor: pointer;

  @media (max-width: 810px) {
    padding: 8px;
    width: 80%;
  }
`;
export const ExtraLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 20px 0;
`;
export const NotaMember = styled.a`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #394a64;

  @media (max-width: 810px) {
    font-size: 10px;
  }
`;
export const Icon = styled.image`
  background: url("/identity/sign-in/g-normal.png") transparent 5px 50%
    no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
`;
export const Image = styled.img`
  padding-right: 10px;
  color: white;
`;
export const Auth = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
`;
export const OR = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 13px;
  margin: 12px 0;
  color: #000;
  /* or 18px */

  @media (max-width: 810px) {
    margin: 10px;
    font-size: 10px;
  }
`;
export const ButtonAuth = styled.button`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid #000000;
  border-radius: 4px;
  color: #5e2eba;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  margin-bottom: 20px;
  @media (max-width: 810px) {
    width: 100%;
  }
`;

export const AppleButton = styled.button`
  display: flex;
  padding: 4px 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #000;
  border-radius: 4px;
  border: none;

  @media (max-width: 810px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const PopUpOverlay = styled.div`
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Popup = styled.div`
  padding: 2% 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 70px auto;
  background: #fff;
  border-radius: 6px;
  width: 33%;
  position: relative;
  transition: all 5s ease-in-out;
`;

export const ClosePopUp = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  cursor: pointer;
`;

export const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
