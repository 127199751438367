import { GET_BALANCE,LOGIN ,
LOGIN_CHECK,
SIGNUP_CHECK,
CREATE_COLLECTION,
PROFILE_EDIT,
WALLET_CONNECT_CHECK,SET_CHAINID,WALLET_BALANCE, AUTH_TOKEN, SEND_EMAIL_OTP, OTP_VERIFICATION, CHANGE_EMAIL, GOOGLE_LOGIN_INFO, ERROR_MESSAGE, CREATE_COLLECTION_STATE, PROCESSED_IMAGE_URL, LIBRARY_IMAGE_DATA} from "./constants";
import wallet from "../../utils/wallet";
import { BaseURl } from "../../utils/AccessPoints/Endpoints";
import axios from 'axios';
import history from "../../modules/app/components/history";

export const getLPBalance = (amount: any) => {
	return {
		type: GET_BALANCE,
		lp_Balance: amount,
	};
};

export const setCreateCollection = (collection: any) => {
	return {
		type: CREATE_COLLECTION_STATE,
		payload: collection,
	};
};

export const UseProccessedImage = (ImageData: any) => {
		return {
			type: PROCESSED_IMAGE_URL,
			payload: ImageData,
		};
	};
	
	export const processingLibrarySelectedImage = (ImageData: any) => {
			return {
				type:LIBRARY_IMAGE_DATA ,
				payload: ImageData,
			};
		};
	

export const Login = (userAddress: String) => {
	return {
		type: LOGIN,
		address: userAddress,
	};
};


export const walletConnectCheck = (value: any) => {
	return {
		type: WALLET_CONNECT_CHECK,
		value: value,
	};
};
export const setChainIdValue = (val: any) => {
	return {
		type: SET_CHAINID,
		value: val,
	};
};
export const getWalletBalance = (balance: String) => {
	return {
		type: WALLET_BALANCE,
		balance: balance,
	};
};

export const getBalance = (address: any) => async (dispatch: any) => {
	if (address) {
		const balance = await wallet.web3.eth.getBalance(address);
		dispatch(getWalletBalance(balance));
		dispatch(getLPBalance(address));
	}
};

export const authtoken = (token:any) => {
	return {
		type: AUTH_TOKEN,
		payload:token
	};
};


export const Logincheck = (loginInfo:object) => {
	return {
		type: LOGIN_CHECK,
		payload:loginInfo
	};
};

export const signupcheck = (signUpInfo:object) => {
	console.log("info",signUpInfo)
	return {
		type: SIGNUP_CHECK,
		payload:signUpInfo
	};
};
export const createCollection = (createCollectionData:object) => {
	
	return {
		type: CREATE_COLLECTION,
		payload:createCollectionData
	};
};

export const editProfile = (profileData:object) => {
	console.log("profile data",profileData)
	return {
		type: PROFILE_EDIT,
		payload:profileData
	};
};

export const EditingProfile=(ProfileData:object) =>async (dispatch:any) => {
	
	   /* @ts-ignore */
	   const userData:string= JSON.parse(localStorage.getItem('token'));
       fetch(process.env.REACT_APP_BaseURl+"user/update-profile", {
		method: "post",
		headers: { "Content-Type": "application/json",
		"Authorization":`Token ${userData}` },
		body: JSON.stringify(ProfileData),
	  })
		.then((response) => response.json())
		.then((responseJson) => {
		  if (responseJson.success) {
			dispatch(editProfile(ProfileData))	  
              history.push("/builder") 
		} else if(responseJson.statusCode===401){
			
			dispatch(SentErrorMessage("current password is incorrect"))
	          		
		  }
		  else if(responseJson.statusCode===400)
		{
            dispatch(SentErrorMessage("bad request please try again"))

		}
		});
}


export const saveGoogleData = (GoogleLoginData:object) => {
	console.log("google profile data",GoogleLoginData)
	return {
		type: GOOGLE_LOGIN_INFO,
		payload:GoogleLoginData
	};
}

export const SentErrorMessage=(ErrorMessage:any) =>
{
	console.log("errr reducer",ErrorMessage)
  return{
    type:ERROR_MESSAGE,
	payload:ErrorMessage

  }



}