import React from 'react'
import {H1,H2,H3,Vryntmodal,Tokencard,Flexspacebetween,Gradientborder,Flex,Flexcolumn} from './style';
import {Borderdiv} from '../Chaos/style';
import {BTN2 } from '../Collection2/style';
import questionmark from '../../../../assets/icons/questionmark.svg';
import dollar from '../../../../assets/icons/Dollar1.png';
import Shape from '../../../../assets/icons/CombinedShape.png';
import downarrow from '../../../../assets/icons/downarrow.svg';
import { Input } from '../ProfileManagement/style';


function Claimvrynt(props:any) {
    return (
        <Vryntmodal>
            <Tokencard>
            <H1 style={{marginBottom:'10px'}}>Claim VRYNT Token</H1>
            <H2>Lorem ipsum sit amet consecuter working</H2>
{props.InUserInfo ?  (<> </>) :
            <Flexspacebetween><H3>Available VRYNT Credit <span style={{color:'#35BCFF'}}>9,263,00</span></H3><H3>VRYNT Credit = VRYNT</H3></Flexspacebetween>
 }
            <Borderdiv style={{width:'100%',padding:'15px',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}><div><H3>Enter Amount</H3><Input type="text" style={{margin:'0',paddingLeft:'10px'}}/></div> <H2><img src={dollar} style={{padding:'0px',height:'18px',marginRight:'5px'}}/>VRYNT Credit</H2></Borderdiv>

            <Gradientborder><H2>99.9872</H2><H2>
                <img src={Shape} style={{padding:'0px',height:'18px',marginRight:'5px'}}/>
                 VRYNT</H2></Gradientborder>

            <img src={downarrow} alt="" style={{height:'20px',width:'20px',margin:'20px 0'}}/>

            <BTN2 style={{width:'100%',marginRight:'0',padding:'20px',fontSize:'16px'}}>Proceed</BTN2>

            <Flex>
                <Flexcolumn><H3>Gas fees <img src={questionmark} width='12px' height='12px' alt="" /></H3><H3>Amount received <img src={questionmark} width='12px' height='12px' alt="" /></H3></Flexcolumn>
                <Flexcolumn style={{alignItems:'flex-end'}}><H3>0.699 VRYNT</H3><H3>1.5 VRYNT</H3></Flexcolumn>

                
            </Flex>

            


            
            </Tokencard>
        </Vryntmodal>
    )
}

export default Claimvrynt
