import history from "../history";

export enum Paths {
  root = "/home",
  home = "/home",
  login = "/login",
  signup = "/signup",
  createCollection = "/createCollection",
  profileManagement = "/profileManagement",
  gallery = "/gallery",
  marketplace = "/markeyplace",
  gallery2 = "/gallery",
  chaos = "/chaos",
  themepage = "/themepage",
  claimvrynt = '/claimvrynt',
  adminpanel = '/adminpanel',
  artBuilder = '/art-builder'
}
