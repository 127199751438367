import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomSwitch = withStyles((theme) => ({
	root: {
		width: 62,
		height: 24,
		padding: 0,
		margin: theme.spacing(1),
		overflow: 'unset',
	},
	switchBase: {
		padding: 2.4,
		color: '#d0d0d0',
		'&$checked': {
			transform: 'translateX(36px)',
			color: '#bb65ff',
			'& + $track': {
				backgroundColor: '#fff',
				opacity: 1,
			},
		},
		'&$focusVisible $thumb': {
			color: '#bb65ff',
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 20,
		height: 20,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
	// @ts-ignore
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});

const Toggle = ({ isActive = false, handleChange }: any) => {
	return (
		<FormControlLabel
			// @ts-ignore
			control={<CustomSwitch checked={isActive} onChange={handleChange} name='checkedB' />}
			label=''
		/>
	);
};
export default Toggle;
