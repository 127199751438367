import styled from "styled-components";


export const H1 = styled.h1`
font-size: 25px ;
font-weight: 500;
margin-bottom: 20px;
`;

export const H2 = styled.h2`
font-size: 16px ;
font-weight: 500;
margin-bottom: 20px;
`;

export const H3 = styled.h2`
font-size: 12px ;
font-weight: 500;
margin-bottom: 20px;
`;

export const Vryntmodal = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
*{
    box-sizing: border-box;
}
`;

export const Tokencard =  styled.div`
width: 500px;
padding: 20px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-top: 20px;
border-radius: 5px;
box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);

`;

export const Gradientborder = styled.div`
border :2px dotted #66CCFF;
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 15px;
margin-top: 20px;


`;

export const Flexspacebetween = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 0 10px;
`;

export const Flexcolumn = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: flex-start;
width: 100%;
`;


export const Flex = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
border: 0.8px solid rgba(28, 33, 45, 0.12);
border-radius: 5px;
margin-top: 16px;
padding: 15px;
`;