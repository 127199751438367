import styled from "styled-components";

export const CollectionPage = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 14%;
`;
export const UploadSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: unset;
   }
`;
export const LineThrough = styled.h1`
  font-size: 27px;
  text-align: center;
  margin: 60px 0 ;
  color: #7c8188;
  font-weight: 500;
  width: 45%;
  position: relative;
  @media (max-width: 768px) {
    font-size: 12px;
    margin: 0;
   }

  ::before {
    content: "";
    display: block;
    width: 38%;
    height: 2px;
    background: #e5e7eb;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -2;
  }
  ::after {
    content: "";
    display: block;
    width: 38%;
    height: 2px;
    background: #e5e7eb;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -2;
  }
`;
export const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
   }
`;

export const Label = styled.label`
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  color: #394a64;
  border-radius: 4px;
  border: 1px solid #dee5ef;
  cursor: pointer;
  position:absolute;
  top: 50%;
  left: 33%;
`;

export const RightSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top:30px;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100%;
   }
`;
export const Modal = styled.div`
  height: 230%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ScheduleDiv = styled.div`
  width: 40vw;
  height: 40vh;
 // z-index: 3;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;

  > * {
 //   z-index: 4;
  }
`;

export const H1 = styled.h1`
  font-size: 14px;
  font-weight: 300;
  color: "#757676";
`;
export const H4 = styled.h1`
  font-size: 13px;
  color: #757676;
font-weight: 400;
`;

export const Button = styled.button`
  padding: 7px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  color: #394a64;
  border-radius: 4px;
  border: 1px solid #dee5ef;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-80%, -30%);
`;

export const H3 = styled.h3`
  font-size: 13px;
  font-weight: 500;
  color: black;
`;

export const IMG = styled.img`
  width: 85%;
  height: 89%;
  background-size: contain;
  border: 2px dashed #dee5ef;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
   }
   `;

export const BTNimg = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 4px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
`;

export const SpecialRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4%;
  border-top: 1px solid #e5e7eb;
  padding-top: 8px;
`;
export const SpecialRowLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const SpecialRowRight = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
export const Sidetext = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
export const Ticktext = styled.div`
  width: 100%;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Row3 = styled.div`
  width: 95%;
 display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5% 0;
  @media (max-width: 768px) {
    display: unset;
   }
`;
export const InfoSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3%;
  @media (max-width: 768px) {
    display: unset;
   }
  > * {
    width: 45%;
    margin: 0 10px;
    @media (max-width: 768px) {
      width: 100%;
     }
}
`;
export const Title = styled.div`
  margin-top: 30px;
  font-size: 24px;
  color: "#7C8188";
  width: 100%;
  display: flex;
  justify-content: center; 
`;
export const EarningBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #66ccff, #be61ff) 1 1;
  padding: 3vh 0;
  transform: translatey(-8%);
  z-index: -1;
`;
export const EarningBelow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;

  > * {
    width: 23%;
  }
`;

export const Span = styled.span`
  color: #35bcff;
  @media (max-width: 768px) {
  font-size: 18px;
   }
`;

export const Input = styled.input`
  padding: 20px 10px;
  background-color: #fff;
  width: 90%;
  border: 1px solid rgba(162, 162, 162, 0.5);
  border-radius: 4px;
  position: relative;
  text-align: left;

  :focus {
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to bottom, #66ccff, #be61ff) 1 1;
    outline: none;
    border-radius: 4px;
  }

  ::placeholder {
    color: #a2a2a280;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const BTN = styled.button`
  border: 1px solid #dee5ef;
  border-radius: 4px;
  margin: 5px 14px;
  padding: 10px 15px;
  color: #394a64;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  
`;

export const BTN2 = styled.button`
  
  justify-content: center;
  align-items: center;
  padding: 11px 16px;
  background: linear-gradient(to right, #66ccff, #be61ff);
  color: white;
  border-radius: 4px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  margin-right: 30px;
  font-weight: 600;
  @media (max-width: 768px) {
    margin-top:20px;
    width:44%;
   }
   @media (max-width: 425px) {
    width:100%;
   }
`;
export const CalendarPopup = styled.div`
  margin: 70px auto;
  padding: 20px;
  height:450px;  
  background: #fff;
  border-radius: 5px;
  width: 32%;
  position: relative;
  transition: all 5s ease-in-out;

`

export const ContentHeader = styled.div`

 //position: absolute;
width: 450px;
height: 34px;
left: 40px;
top: 66px;

font-family: Source Sans Pro;
font-style: normal;
font-weight: 600;
font-size: 27px;
line-height: 100%;
/* or 32px */
display: flex;
align-items: center;
text-align: center;
justify-content: center;
letter-spacing: -0.01em;

/* text color */

color: #000000;




`
export const CalendarContentDiv = styled.div`
margin-top:20%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
height:100%;
overflow: auto;

`
export const ButtonsDiv = styled.div`
margin-top: 30px;
display: flex;
align-items: center;
justify-content: space-around;
`

export const BTN3 = styled.div`

display: flex;
justify-content: center;
align-items: center;
width: 220px;
height: 40px;
border: 1px solid #dee5ef;
background: #35BCFF;
border-radius: 4px;
margin-left: 1rem;


`
export const BTN4 = styled.div`
 display: flex;
justify-content: center;
align-items: center;
width: 220px;
height: 40px;
border-radius: 4px;
font-size: 15px;
border: none;
border: 1px solid #dee5ef; 
background: #FFF;
margin-left: 1rem;


`
export const HR = styled.hr`

border:1px solid black;


`
export const DatePickerStyled = styled.div`
  /* STYLE FOR WITH */
  .DayPickerInput input {
    width:400px;
    padding: 15px ;
    border-style: solid;
  background-color: #fff;
  border-image: "linear-gradient(to bottom, #66ccff, #be61ff) 1 1";
  border-width: 1px;
  border-radius: 2px;
 
  //.DatePickerInput-OverlayWrapper
  & .DayPickerInput-OverlayWrapperr  
  { 
    
  }
}
`;