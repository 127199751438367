import React, { useState, useEffect } from 'react';
import {
	Market,
	H1,
	Marketabove,
	Image,
	Button,
	Marketaboveright,
	Span,
	H2,
	H3,
	Mintednftdiv,
	BuyComponentdiv,
	Doyouknow,
	Marketbelow,
	MarketLeft,
	ImageText,
	Header,
} from './style';
import { useHistory } from "react-router-dom";
import { LineThrough } from '../ProfileManagement/style';
import { CheckBox, RememberLabel } from '../SignUp/style';
import { BTN2, BTN } from '../Collection2/style';
import Squared from '../../../../assets/image/Squared.png';
import mintednft1 from '../../../../assets/image/mintednft1.png';
import mintednft2 from '../../../../assets/image/mintednft2.png';
import mintednft3 from '../../../../assets/image/mintednft3.png';
import ComponentInventory from '../../../../shared/Components-Inventory';
import questionmark from '../../../../assets/icons/questionmark.svg';
import pyramid from '../../../../assets/icons/pyramid.svg';
import { Chaosinut } from '../Chaos/style';
import Bid from '../Popups/Bid';
import { render } from '@testing-library/react';
import { gql, useMutation, useLazyQuery } from "@apollo/client";
function Marketplace(props:any) {
	const history = useHistory();
	const [enoughComp] = useState(false);


	const [componentPackValue, setComponentPackValue] = useState<number>(0);

	const CollectionIdInfo = gql`
        query getCollection(
			$id: String!
             ) {
                getCollection(
                input: {
                    id: $id
                 }
            ) {
				id
				name
				artPiece
				processedImgUrl
				modelId
				seed
				theme
				userId
				status
				seedNo
				type
            }
        }
    `;

	const [CollectionInformation, { loading, error, data: CollectionData }] = useLazyQuery(
        CollectionIdInfo,
        {
            variables: {
            id: props.match.params.id,
            },
        }
    );
    useEffect(() => {
        // console.log("Calling information")
        CollectionInformation();
    }, []);


	
	const handleBid = () => {
		render(<Bid />);
	};


	const data: any = localStorage.getItem('galaryImageData');
	const value = data && JSON.parse(data);
	// console.log(props.match.params.id)
	return (
		<Market>
			<Marketabove>
				<MarketLeft>
					
					<Image style={{ width: '85%', marginLeft: '5%' }}>
					{CollectionData?.getCollection.type === "CUSTOM" ? <img src={CollectionData?.getCollection.processedImgUrl} alt='' /> : <>
					{CollectionData?.getCollection.type === "SEED" ? <img src={CollectionData?.getCollection.artPiece} alt='' /> : <img src={Squared}/>}
					</> }
						
					</Image>
					<ImageText>You need at least 15 components to build this VRYNT</ImageText>
				</MarketLeft>
				<Marketaboveright style={{ marginRight: '2%' }}>
					<H1>{CollectionData?.getCollection.name ? CollectionData?.getCollection.name : 'Day in a Chinese Shop'}</H1>
					{/* <H2>{CollectionData?.getCollection.theme ? CollectionData?.getCollection.theme : 'Theme'}</H2> */}
					{/* <H3>
						{value?.description
							? value.description
							: 'Lorem ipsum is a placeholder text commonly used to demonstrate the dummy text here so we can use it'}
					</H3> */}
					{/* <H2 style={{ marginTop: '3%' }}>Do you Know?</H2>
					<Doyouknow>
						<H3>
							<img style={{ marginRight: '2px' }} src={pyramid} alt='' />
							NFT yet to be minted <Span>2929</Span>
						</H3>
						<H3>
							<img style={{ marginRight: '2px' }} src={pyramid} alt='' />
							Components required to mint an NFT <Span>50</Span>
						</H3>
					</Doyouknow> */}

					{enoughComp ? (
						<>
							<BTN2
								style={{
									padding: '10px 20px',
									width: '100%',
									fontSize: '15px',
									marginLeft: '1%',
									height: '10%',
								}}
							>
								Start Building (using 18 components)
							</BTN2>
						</>
					) : (
						<>
						
							<Button onClick={(e)=>{history.push(`/vrynt-builder/${props.match.params.id}`)}}>Start Building</Button>
						</>
					)}
				</Marketaboveright>
			</Marketabove>
			{/* <ComponentInventory /> */}
			{/* <Marketbelow>
				<LineThrough style={{ marginBottom: '40px' }}>
					<span
						style={{
							backgroundColor: 'white',
							color: 'black',
						}}
					>
						Minted NFT's in this collection
					</span>
				</LineThrough>
				<Mintednftdiv>
					<img src={mintednft1} alt='' />
					<img src={mintednft2} alt='' />
					<img src={mintednft3} alt='' />
				</Mintednftdiv>
			</Marketbelow> */}
		</Market>
	);
}

export default Marketplace;
