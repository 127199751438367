import styled from "styled-components";


export const Container =styled.div `
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;

`

export const CreditTitle=  styled.h3 `
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 120%;
/* or 22px */

letter-spacing: -0.02em;

/* text color */

color: #000000;



`


export const CreditInput = styled.input`
  width: 90%;
  box-sizing: border-box;
  height: 50px;
  background-color: #fff;
  margin: 10px 0;
  padding: 8px 3%;
  border: 1px #66CCFF dotted;
  position: relative;

  :focus {
      display: none;
    border: 1px #66CCFF dotted;
   // position: relative;
 }

   ::placeholder {
    font-family: Source Sans Pro;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 120%;
/* or 22px */

letter-spacing: -0.02em;

/* text color */

color: #000000;


  }
`;