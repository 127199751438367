import React, { useEffect } from 'react';
import { /* RouteComponentProps */ Redirect, Switch } from 'react-router';
import { Router, Route } from 'react-router-dom';
import history from '../history';
import { Paths } from './types';
import { NotFound } from './NotFound';
import { StyledRoutesContainer } from './style';
import Footer from '../footer';
import Navbar from '../navbar2';
import CreateCollection from '../Collection2/Createcollection';
import { MainContainer } from '../../../../shared/styles/styled';
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';
import ProfileManagement from '../ProfileManagement/ProfileManagement';
import Gallery from '../Gallery/Gallery';
import Home from '../../../home/Home';
import ProtectedRoute from './ProtectedRoute';
import Marketplace from '../Marketplace/Marketplace';
import Gallery2 from '../Gallery2/Gallery';
import Chaos from '../Chaos/Chaos';
import Payment from '../Payment/StripePaymentForm';
import Themepage from '../Themepage/Themepage';
import Claimvrynt from '../Claimvrynt/Claimvrynt';
import Adminpanel from '../Adminpanel/Adminpanel';
import ArtBuilder from '../ArtBuilder';
import UiTabs from "../ArtBuilder/UiTabs"

import AccountSetting from '../AccountSetting/AccountSetting';
import AllUsers from '../AccountSetting/AllUsers';
import { useSelector } from 'react-redux';
import { isForOfStatement } from 'typescript';
import AccountInfo from '../AccountSetting/AccountInfo';
import UserAccountInfo from '../AccountSetting/UserAccountInfo/UserAccountInfo';
import Bid from '../Popups/Bid';
import BuildNftPopUp from '../Popups/BuildNftPopUp';
import AdminRoutes from './AdminRoutes';
import ViewNft from '../Nft/ViewNft';
//import ProfileManagement from "../ProfileManagement/styles";

// import wallet from "../../../../utils/wallet";
// import { useDispatch } from "react-redux";
// import { Login, setChainIdValue } from "../../../../logic/actions";
const notFoundRoute: RouteDefinition = {
	path: '*',
	component: NotFound,
	protected: false,
	title: '',
};

export const routes: RouteDefinition[] = [
	{
		path: Paths.root,
		component: Home,
		protected: false,
		redirect: Paths.home,
		title: 'Home',
		pathType: 0,
	},
	{
		path: Paths.login,
		component: Login,
		protected: false,
		redirect: Paths.login,
		title: 'Login',
		pathType: 1,
	},

	{
		path: Paths.signup,
		component: SignUp,
		protected: false,
		redirect: Paths.home,
		title: 'Sign up',
		pathType: 2,
	},

	{
		path: Paths.createCollection,
		component: CreateCollection,
		protected: false,
		redirect: Paths.createCollection,
		title: 'Create Collection',
		pathType: 3,
	},

	{
		path: Paths.profileManagement,
		component: ProfileManagement,
		protected: false,
		redirect: Paths.profileManagement,
		title: 'Profile Management',
		pathType: 4,
	},
	{
		path: Paths.gallery,
		component: Gallery,
		protected: false,
		redirect: Paths.gallery,
		title: 'Gallery',
		pathType: 5,
	},
	{
		path: Paths.marketplace,
		component: Marketplace,
		protected: false,
		redirect: Paths.marketplace,
		title: 'marketplace',
		pathType: 6,
	},
	{
		path: Paths.gallery2,
		component: Gallery2,
		protected: false,
		redirect: Paths.gallery2,
		title: 'Gallery',
		pathType: 7,
	},
	{
		path: Paths.chaos,
		component: Chaos,
		protected: true,
		redirect: Paths.chaos,
		title: 'chaos',
		pathType: 8,
	},
	{
		path: Paths.themepage,
		component: Themepage,
		protected: true,
		redirect: Paths.themepage,
		title: 'themepage',
		pathType: 9,
	},
	{
		path: Paths.claimvrynt,
		component: Claimvrynt,
		protected: true,
		redirect: Paths.claimvrynt,
		title: 'claimvrynt',
		pathType: 10,
	},
	{
		path: Paths.adminpanel,
		component: Adminpanel,
		protected: true,
		redirect: Paths.adminpanel,
		title: 'adminpanel',
		pathType: 11,
	},

	{
		path: Paths.home,
		component: Home,
		protected: false,
		redirect: Paths.home,
		title: 'Home',
		pathType: 0,
	},
	{
		path: Paths.artBuilder,
		component: ArtBuilder,
		protected: false,
		redirect: Paths.artBuilder,
		title: 'Art Builder',
		pathType: 0,
	},
].concat(notFoundRoute as any); // Ensure that notFound is the last route

export interface RouteDefinition {
	path: string;
	protected?: boolean;
	redirect?: string;
	component?: any;
	routes?: RouteDefinition[];
	title?: string;
	pathType?: number;
}

interface Props {
	// userLoaded: boolean
	// auth:string
}
interface RoutesProps {}

function getRouteRenderWithAuth(route: RouteDefinition, i: number) {
	return () => <route.component />;
}

const Routes: React.FC<Props & RoutesProps> = () => {
	const { loginData, authtoken } = useSelector((state: any) => state.wallet);
	// const dispatch = useDispatch();

	// React.useEffect(() => {
	//   const walletReconnect = async () => {
	//     //@ts-ignore
	//     const walletConnected = JSON.parse(localStorage.getItem("walletConnected"));
	//     //@ts-ignore
	//     const walletConnect = JSON.parse(localStorage.getItem("walletConnect"));
	//     //@ts-ignore
	//     const walletType = JSON.parse(localStorage.getItem("walletType"));
	//     try {
	//       const chainid = await wallet.web3.eth.getChainId();
	//       dispatch(setChainIdValue(chainid));
	//     }
	//     catch (e) {
	//       dispatch(setChainIdValue(0));

	//     }

	//     if (walletConnected) {
	//       //@ts-ignore
	//       if (window.BinanceChain === undefined && walletType === 7) {
	//         localStorage.clear();
	//         window.location.reload();
	//       } else {
	//         try {
	//           await wallet.setProvider(walletType);
	//           const address = await wallet.login(walletType);
	//           dispatch(Login(address));
	//         } catch (err) {
	//           console.log("error in reconnect", err);
	//         }
	//       }
	//     }
	//   };
	//   walletReconnect();
	// }, []);
	/* @ts-ignore */
	const userData: string = JSON.parse(localStorage.getItem('token'));

	//console.log("auth",auth)
	useEffect(() => {
		if (
			(history.location.pathname === '/login' || history.location.pathname === '/signup')
		) {
			history.push('/');
		}
	}, []);

	return (
		<Router history={history}>
			<StyledRoutesContainer>
				<div>
					<Navbar />
					<MainContainer>
						<Switch>
						<Route exact path='/' component={UiTabs} />
							{/* <Redirect exact from='/' to={Paths.login} /> */}
							{/* <Redirect exact from='/vrynt' to='/gallery' /> */}
							{/* <Route exact path='/signup' component={SignUp} /> */}
							{/* <Route exact path='/login' component={Login} /> */}
							{/* <ProtectedRoute exact path='/profile' component={ProfileManagement} /> */}
							{/* <ProtectedRoute exact path='/createCollection' component={CreateCollection} /> */}
							{/* <Route exact path='/gallery' component={Gallery} /> */}
								{/* <Route  exact
									path='/Marketplace/:id'
									component={Marketplace}
								/> */}
								{/* <Route  exact
									path='/allNFT'
									component={ViewNft}
								/>  */}
							{/* <Route exact path='/AccountInfo' component={AccountInfo} />
							<Route exact path='/Bid' component={Bid} />
							<Route exact path='/BuildNft' component={BuildNftPopUp} />
							<ProtectedRoute exact path='/payment' component={Payment} />
							<ProtectedRoute exact path='/gallery' component={Gallery2} />
							<Route exact path='/chaos' component={Chaos} />
							<Route exact path='/themepage' component={Themepage} />
							<Route exact path='/claimvrynt' component={Claimvrynt} />
							<Route exact path='/vrynt-builder/:id' component={ArtBuilder} /> */}
							{/* <Route exact path='/builder' component={UiTabs} /> */}

							{/* <AdminRoutes exact path='/AccountSetting' component={AccountSetting} />
							<AdminRoutes exact path='/allUsers' component={AllUsers} />
							<AdminRoutes
								exact
								path='/UserAccountInfo/:id'
								component={UserAccountInfo}
							/>
							<AdminRoutes exact path='/adminpanel' component={Adminpanel} /> */}
						</Switch>
					</MainContainer>
				</div>
			</StyledRoutesContainer>
			{/* <Footer /> */}
		</Router>
	);
};

export default Routes;
