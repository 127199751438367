import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './modules/app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './logic/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
const persistor = persistStore(store);

const httpLink = createHttpLink({
	uri: 'https://ao.vrynt.io/graphql',
});

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('token');
	const newToken=token?.replace(/^"(.*)"$/, '$1');
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${newToken}` : '',
		},
	};
});
const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
});
ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<Provider store={store}>
				<PersistGate loading={<CircularProgress />} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
