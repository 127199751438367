import styled from "styled-components";

export const Div1=styled.div ` 

display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 45%;
    margin-bottom: 10px;
  
`

export const Div2=styled.div `
display: flex;
justify-content: center;
flex-direction: column;
align-items: flex-start;
width: 100%;
`
