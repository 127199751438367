import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  ClosePopUp,
  ContentDiv,
  Img,
  Popup,
  PopUpOverlay,
} from "../Login/style";
import ToggleInactive from "../../../../assets/icons/toggle-inactive.svg";
/* @ts-ignore */
import axios from "axios";
import upload from "../../../../assets/icons/upload.svg";
import Telegram from "../../../../assets/icons/telegram.svg";
import Discord from "../../../../assets/icons/Discord.svg";
import twitter from "../../../../assets/icons/twitter.png";
import insta from "../../../../assets/icons/insta.png";
import youtube from "../../../../assets/icons/youtube.png";
import tiktok from "../../../../assets/icons/tiktok.png";
import twitch from "../../../../assets/icons/twitch.png";
import Close from "../../../../assets/image/close.svg";
import user from "../../../../assets/icons/user.svg";
import {
  H1,
  Container,
  UploadButton,
  RightSide,
  LeftSide,
  BtnDiv,
  BTNimg,
  Input,
  Row,
  InactiveBTN,
  ProfileSection,
  ChangePasswordSection,
  UploadDiv,
  TextDiv,
  Togglediv,
  InputImg,
  TelegramDiv,
  Toggleside,
  InputsDiv,
  LineThrough,
  StatusInput,
  BTN,
  Availaiblemsg,
  Usermsg,
  Profilepicinput,
  Label,
  ProfileImageContainer,
  ProfileImage,
  Div,
} from "./style";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import CustomToggle from "../AccountSetting/UserAccountInfo/ToggleContainer";
import userProfileBorder from "./profile-border.svg";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";

interface UserData {
  Firstname: string;
  Lastname: string;
  userName: string | null;
  userImage: File | undefined;
  description: string;
  email: string;
  oldpassword: string | null;
  newpassword: string | null;
  mobileNumber: number | null;
  confirmnewpass: string;
  socialTelegram: string;
  socialDiscord: string;
  socialTwitter: string;
  socialYoutube: string;
  socialInstagram: string;
  socialTwitch: string;
  socialTiktok: string;
}

interface UserInfoType {
  Firstname: string;
  Lastname: string;

  userName: string | null;
  profileImageUrl: File | undefined;
  status: string;
  email: string;
  mobile: number | null;
  socialTelegram: string;
  socialDiscord: string;
  EmailVerified: boolean;
  userId: number | null;
  socialTwitter: string;
  socialYoutube: string;
  socialInstagram: string;
  socialTwitch: string;
  socialTiktok: string;
}

const ProfileManagement = () => {
  const { loginData, Message, googleUserdata, errorMessage } = useSelector(
    (state: any) => state.wallet
  );
  const [userImage, setUserImage] = useState<string>();
  const [userInfo, setUserInfo] = useState<UserInfoType>({
    Firstname: "",
    Lastname: "",
    email: "",
    userName: "" || null,
    status: "",
    profileImageUrl: undefined,
    mobile: 0 || null,
    socialDiscord: "",
    socialTelegram: "",
    EmailVerified: false,
    userId: 0 || null,
    socialTwitter: "",
    socialYoutube: "",
    socialInstagram: "",
    socialTwitch: "",
    socialTiktok: "",
  });
  const [imageError, setImageError] = useState<string>();
  const [profilePic, setProfilePic] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const Toggle = ToggleInactive;
  const [Toggleicon, setToggleicon] = useState(Toggle);
  const [passMesssage, setPassMessage] = useState<string>("");
  const dispatch = useDispatch();
  const [userNameMessage, setUserNameMessage] = useState<string>();
  const [userNameNotAvailable, setUserNameNotAvailable] = useState("");
  const [usermsgcolor, setusermsgcolor] = useState("");
  const [docType, setDocType] = useState("passport");
  const [docImage, setDocImage] = useState<any>();
  const [profiledata, setProfileData] = useState<UserData>({
    Firstname: "" || userInfo.Firstname,
    Lastname: "",
    email: "",
    userName: "" || null,
    userImage: undefined,
    description: "" || userInfo.status,
    newpassword: "" || null,
    confirmnewpass: "",
    oldpassword: "" || null,
    socialTelegram: "",
    socialDiscord: "",
    socialTwitter: "",
    socialYoutube: "",
    socialInstagram: "",
    socialTwitch: "",
    socialTiktok: "",

    mobileNumber: 0 || null,
  });
  const [showModal, setShowModal] = useState(false);
  const [availaibleornot, setavailaibleornot] = useState(false);
  const [continues, setContinue] = useState<boolean>(false);
  const [passNotMatch, setPassNotMatch] = useState("");
  const [MSGCOLOR, setMSGCOLOR] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const [notValid, setNotValid] = useState<boolean>(true);
  const [otp, setOtp] = useState<number>();
  const [mydata, setMydata] = useState();
  const [Filename, setFilename] = useState("");
  /* @ts-ignore */
  const userData: string = JSON.parse(localStorage.getItem("token"));
  const [twoFactorAuth, setTwoFactAuth] = useState<boolean>(false);
  const [kycMessage, setKycMessage] = useState("");
  const [disableKyc, setDisableKyc] = useState(false);
  //query
  const UserData = gql`
    query {
      getLoggedInUser {
        first_name
        last_name
        email
        username
        socialDiscord
        status
        socialTwitter
        socialYoutube
        socialInstagram
        socialTwitch
        socialTiktok
        socialTelegram
        _id
        isEmailVerified
        mobile
        kyc_status
        twoFactorAuth
        profileImageUrl
      }
    }
  `;
  const CheckEmailAvailable = gql`
    query checkEmail($data: String!, $data1: String!) {
      checkEmail(input: { userId: $data, newEmail: $data1 }) {
        message
      }
    }
  `;

  const SendEmailVerifyOtp = gql`
    query SendEmailOtp($email: String!) {
      sendEmailOtp(input: { email: $email }) {
        message
      }
    }
  `;

  const VerifyEmailOTP = gql`
    mutation verifyEmailOtp($input: TwoFactorOtpDto!) {
      verifyEmailOtp(input: $input) {
        message
      }
    }
  `;
  //

  //for validation of verify otp button in pop up
  useEffect(() => {
    if (otp && otp.toString().length === 6) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  });

  const ProfileUpdate = gql`
    mutation UpdateProfile($input: UpdateProfileDto!) {
      updateProfile(input: $input) {
        message
      }
    }
  `;

  const UpdateProfilePic = gql`
    mutation UploadUserProfilePicture($input: UploadProfilePictureDto!) {
      uploadUserProfilePicture(input: $input) {
        message
      }
    }
  `;

  const [doProfileUpdate, { data: profileUpdateData }] =
    useMutation(ProfileUpdate);
  const [doUpdateProfilePicture, { data: UpdateProfilePicData }] =
    useMutation(UpdateProfilePic);
  const [LoggedInUser, setLoggedInUser] = useState([]);
  const { error: erros, data: Userdata } = useQuery(UserData);

  useEffect(() => {
    if (Userdata) {
      //console.log("Userdata?.getLoggedInUser.email",Userdata?.getLoggedInUser.email)
      setTwoFactAuth(Userdata?.getLoggedInUser.twoFactorAuth);
      setProfileData({
        ...profiledata,
        email: Userdata?.getLoggedInUser.email,
      });
    }
  }, [Userdata?.getLoggedInUser]);

  useEffect(() => {
    if (UserData) {
      setProfileData({
        ...profiledata,
        Firstname: Userdata?.getLoggedInUser.first_name,
        Lastname: Userdata?.getLoggedInUser.last_name,
      });
    }
  }, [
    Userdata?.getLoggedInUser.first_name,
    Userdata?.getLoggedInUser.last_name,
  ]);

  useEffect(() => {
    if (
      Userdata?.getLoggedInUser.kyc_status === "approved" ||
      loginData?.kyc_status === "under_review" ||
      loginData?.kyc_status === "rejected"
    ) {
      if (Userdata?.getLoggedInUser.kyc_status == "approved") {
        setKycMessage("Approved");
      }
      if (loginData?.kyc_status === "under_review") {
        setKycMessage("Request Submitted and Is Being Reviewed");
      }
       if (loginData?.kyc_status === "rejected") {
        setKycMessage("Rejected");
      }
      setDisableKyc(true);
    } else {
      setKycMessage("Firstname, Lastname and Email should be filled for KYC");
      setDisableKyc(false);
    }
  }, [Userdata?.getLoggedInUser]);

  useEffect(() => {
    if (disableKyc) {
      setFilename("");
    }
  });

  //console.log('userdata', Userdata);
  //for validation of verify otp button in pop up
  useEffect(() => {
    if (otp && otp.toString().length === 6) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  }, [otp]);

  const UserName = gql`
    query LoginMutation($username: String!) {
      checkUsername(input: { username: $username }) {
        message
      }
    }
  `;

  /* @ts-ignore */
  const [usernameAvailbility, { loading, error, data }] = useLazyQuery(
    UserName,
    {
      variables: { username: profiledata.userName },
    }
  );
  //const [doLogin, { data }] = useLazyQuery(USER);

  //useEffect for username check
  useEffect(() => {
    if (profiledata.userName && profiledata.userName.length >= 3) {
      const userNameFormat = /^[a-z0-9_\\.]+$/;
      const data = { username: profiledata.userName };
      setusermsgcolor("blue");
      UserNameCheck(data);

      //login()
    } else {
      if (profiledata.userName !== "") {
        setUserNameMessage("Username must be of length 3 or more");
        setusermsgcolor("red");
        setTimeout(() => setUserNameMessage(""), 10000);
      }
      if (profiledata.userName === null) {
        setUserNameMessage("");
      }
    }
  }, [profiledata.userName]);

  //username check function

  const UserNameCheck = (Usernamedata: any) => {
    usernameAvailbility();
    console.log(data, error);
    if (data?.checkUsername.message) {
      setUserNameMessage("Username avaliable");
      setTimeout(() => setUserNameMessage(""), 5000);
    } else {
      setUserNameMessage("Username not avaliable");
      setusermsgcolor("red");
      setTimeout(() => setUserNameMessage(""), 7000);
    }
  };

  const imageFit = () => {
    if (userImage) {
      return "cover";
    } else {
      return "contain";
    }
  };
console.log(Userdata && Userdata?.getLoggedInUser.isEmailVerified);
  //uploading image API
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    const image = URL.createObjectURL(fileList[0]);
    setUserImage(image);

    handleImageUpload(fileList[0]);

    /* */
    var profileImageURL = "";
    /* @ts-ignore */
    const userData: string = JSON.parse(localStorage.getItem("token"));
    var formData = new FormData();
    /* @ts-ignore */
    formData.append("image", fileList[0]);
    axios
      .post(process.env.REACT_APP_BaseURl + "/upload/image", formData, {
        headers: {
          Authorization: `Token ${userData}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status == 201) {
          console.log(
            "image updated successfuly",
            response.data.data.profileImageUrl
          );

          profileImageURL = response.data.data.profileImageUrl;
          console.log(profileImageURL);

          doUpdateProfilePicture({
            variables: {
              input: {
                profileImageUrl: profileImageURL,
              },
            },
          })
            .then((res) => {
              console.log("res", res.data);
              swal("Profile Pic Updated Successfully");
            })
            .catch((error) => {
              swal(error.message);
              console.log("error ", error);
            });
        }
      })
      .catch((error) => {
        setImageError("error in image upload");
        setTimeout(() => setImageError(""), 3000);
      });

    setTimeout(() => setMessage(""), 3000);
  };
  //image upload
  const handleImageUpload = (Imagedata: any) => {
    //console.log('imagedata', ImageData);
  };

  // function for submitting all the profile data
  const handleSubmit = (event: any) => {
    event.preventDefault();

    var mobileNumber = profiledata.mobileNumber?.toString();

    var spacePattern = /\s/g.test(profiledata?.Firstname);
    console.log("first name", /\s/g.test(profiledata?.Firstname));
    if (profiledata.Firstname === "" || profiledata.Lastname === "") {
      swal("First And Last Name Should Not Be Empty");
    }

    //@ts-ignore
    else if (mobileNumber?.length < 10) {
      swal("Mobile Number Should Be Of Length 10 Or More");
    } else {
      doProfileUpdate({
        variables: {
          input: {
            first_name: profiledata.Firstname,
            last_name: profiledata.Lastname,
            username: profiledata.userName || null,
            mobile: mobileNumber || Userdata?.getLoggedInUser.mobile,
            status: profiledata.description || Userdata?.getLoggedInUser.status,
            currentPassword: profiledata.oldpassword || null,
            newPassword: profiledata.newpassword || null,
            socialDiscord:
              profiledata.socialDiscord ||
              Userdata?.getLoggedInUser.socialDiscord,
            socialTelegram:
              profiledata.socialTelegram ||
              Userdata?.getLoggedInUser.socialTelegram,
            socialTwitter:
              profiledata.socialTwitter ||
              Userdata?.getLoggedInUser.socialTwitter,
            socialInstagram:
              profiledata.socialInstagram ||
              Userdata?.getLoggedInUser.socialInstagram,
            socialTwitch:
              profiledata.socialTwitch ||
              Userdata?.getLoggedInUser.socialTwitch,
            socialTiktok:
              profiledata.socialTiktok ||
              Userdata?.getLoggedInUser.socialTiktok,
            socialYoutube:
              profiledata.socialYoutube ||
              Userdata?.getLoggedInUser.socialYoutube,
            twoFactorAuth: twoFactorAuth,
            isBlocked: false,
          },
        },
      })
        .then((res) => {
          console.log("res", res.data);
          swal("Profile updated successfully");
        })
        .catch((error) => {
          swal(error.message);
          console.log("error ", error);
        });
    }
  };

  const [doVerfiyEmailOtp, { data: verifyEmailOtpData }] =
    useMutation(VerifyEmailOTP);

  //verifying otp for email verification

  const SendingOTP = (data: any) => {
    setShowModal(true);
    console.log("sending otp called", data);
    doVerfiyEmailOtp({
      variables: {
        input: {
          email: data.email,
          otp: data.otp,
        },
      },
    })
      .then((res) => {
        console.log("res", res.data);
        swal("Email Is Verified");
        // Navigate the user
        /* @ts-ignore */
        //      localStorage.setItem('token', JSON.stringify(res.data?.twoFactorVerify.token));
        //    history.push("/profile")
      })
      .catch((error) => {
        swal(error.message);
        console.log("error ", error);
      });

    /* @ts-ignore */
  };
  const email = profiledata.email;

  const [sendingEmailVerifyOTP, { error: otpError, data: EmailOtpData }] =
    useLazyQuery(SendEmailVerifyOtp, {
      variables: {
        email: profiledata.email || Userdata?.getLoggedInUser.email,
      },
    });

  //
  const showingModal = (event: any) => {
    setShowModal(true);
    event.preventDefault();
    sendingEmailVerifyOTP();
  };
  const handleVerifyOTP = (event: any) => {
    event?.preventDefault();
    const data = {
      otp: otp?.toString(),
      email: profiledata.email || Userdata?.getLoggedInUser.email,
    };
    SendingOTP(data);
    // hideModal();
  };

  const hideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (profiledata.email.match(validRegex)) {
        EmailAvalible(profiledata.email);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [profiledata.email]);

  //checking email available

  const onmount = () => {
    if (profiledata.email) {
      console.log(profiledata.email);
    }
  };

  const [CheckingEmailAvailable, { error: errors, data: EmailData }] =
    useLazyQuery(CheckEmailAvailable, {
      variables: {
        data: Userdata?.getLoggedInUser._id,
        data1: profiledata.email,
      },
    });

  const EmailAvalible = (newemailAddress: any) => {
    CheckingEmailAvailable();
    if (error) {
      setMessage("Unavailable email");
      setavailaibleornot(false);
      setMSGCOLOR("Red");
      setTimeout(() => setMessage(""), 10000);
    } else {
      setMessage("Email Availaible");
      setavailaibleornot(true);
      setMSGCOLOR("Blue");
      setTimeout(() => setMessage(""), 10000);
    }
  };

  const profileImageGenerator = () => {
    if (userImage) return userImage;
    return Userdata?.getLoggedInUser.profileImageUrl || user;
  };
  // const imageBorder = () => {

  // }

  const handleDropdownChange = (event: any) => {
    setDocType(event.target.value);
  };

  const handleVerificationImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("image verification");
    const fileList = e.target.files;
    //	console.log("files",fileList)
    if (!fileList) return;
    setFilename(fileList[0]?.name);
    setDocImage(fileList[0]);
    if (
      profiledata.Firstname !== null &&
      profiledata.Lastname !== null &&
      profiledata.email !== null
    ) {
      /* @ts-ignore */
      const userData: string = JSON.parse(localStorage.getItem("token"));
      var formData = new FormData();
      /* @ts-ignore */
      formData.append("document", fileList[0]);
      formData.append("documentType", docType);

      axios
        .post(process.env.REACT_APP_BaseURl + "/user/kyc", formData, {
          headers: {
            Authorization: ` Bearer ${userData}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status == 201) {
            swal("KYC Verification Started");
            setDisableKyc(true);
            setKycMessage("Request Submitted and Is Being Reviewed");
          }
        })
        .catch((error) => {
          swal("Error in Verification Please Try Again");
        });
    } else {
      swal(
        "Please Update Profile With First name, Last name and Email For KYC"
      );
    }
  };
  const handleVerification = () => {
    /* @ts-ignore */
    const userData: string = JSON.parse(localStorage.getItem("token"));
    var formData = new FormData();
    /* @ts-ignore */
    formData.append("document", docImage);
    formData.append("documentType", "passport");

    console.log("formData", formData);
    axios
      .post(process.env.REACT_APP_BaseURl + "/user/kyc", formData, {
        headers: {
          Authorization: ` Bearer ${userData}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("response", response);

        if (response.status == 201) {
          swal("KYC Verification Started");
        }
      })
      .catch((error) => {
        swal("Error in Verification Please Try Again");
      });
  };

  return (
    <Container onSubmit={handleSubmit}>
      <ProfileSection>
        <LeftSide style={{}}>
          <UploadDiv>
            <ProfileImageContainer
              style={
                userImage || Userdata?.getLoggedInUser.profileImageUrl
                  ? { backgroundImage: `url(${userProfileBorder})` }
                  : {}
              }
            >
              <ProfileImage
                // @ts-ignore
                src={profileImageGenerator()}
                defaultValue={user}
              />
            </ProfileImageContainer>
            <TextDiv>
              <H1>Profile pic</H1>
              <Profilepicinput
                type="file"
                id="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <Label htmlFor="file">
                <BTNimg src={upload} />
                Upload
              </Label>
            </TextDiv>
          </UploadDiv>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <InputsDiv>
              <H1>First Name*</H1>
              <Input
                pattern="\s*\S+.*"
                title="only white space not allowed"
                type="text"
                placeholder="Please Enter Your First Name*"
                onChange={(e) =>
                  setProfileData({ ...profiledata, Firstname: e.target.value })
                }
                defaultValue={
                  (userInfo && userInfo.Firstname) ||
                  Userdata?.getLoggedInUser.first_name
                }
                required
                maxLength={50}
              />

              <TelegramDiv>
                <H1>Username*</H1>
                <Input
                  type="textbox"
                  onChange={(e) =>
                    setProfileData({ ...profiledata, userName: e.target.value })
                  }
                  placeholder="Enter Your Username*"
                  // @ts-ignore
                  defaultValue={Userdata?.getLoggedInUser.username || ""}
                  required
                />
                <br />
                <br />
                <Usermsg color={usermsgcolor}>{userNameMessage}</Usermsg>
              </TelegramDiv>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <H1>KYC</H1>

                <Usermsg
                  style={{
                    position: "absolute",
                    marginLeft: "4%",
                  }}
                  color={"green"}
                >
                  ({kycMessage})
                </Usermsg>
              </div>
              <div style={{ display: "flex", marginTop: "3%" }}>
                <select
                  id="dropdown"
                  style={{ width: "70%", height: "50px" }}
                  disabled={disableKyc}
                  onChange={handleDropdownChange}
                >
                  <option value="" selected disabled hidden>
                    Select Document
                  </option>
                  <option value="passport">Passport</option>
                  <option value="national_identity_card">
                    National ID Card
                  </option>
                  <option value="driving_licence">Driving Licence</option>
                  <option value="voter_id">Voter ID</option>
                  <option value="work_permit">Work Permit</option>
                </select>
                <div style={{ marginLeft: "2%" }}>
                  <Profilepicinput
                    style={{ marginLeft: "2%" }}
                    type="file"
                    disabled={disableKyc}
                    id="file1"
                    accept="image/*"
                    onChange={handleVerificationImage}
                  />

                  <Label htmlFor="file1">
                    <BTNimg src={upload} style={{ height: "35px" }} />
                    Upload
                  </Label>
                  <div style={{ width: "60px" }}>{Filename}</div>
                </div>
              </div>
            </InputsDiv>
          </div>
        </LeftSide>

        <RightSide>
          <Toggleside>
            <CustomToggle
              isActive={twoFactorAuth}
              handleChange={() => setTwoFactAuth(!twoFactorAuth)}
              title="Enable Two Factor Authentication To Increase Security"
              textOnRightWithoutContainer
            />
            <br />
            <H1>Description</H1>
            <StatusInput
              style={{
                height: "35%",
                paddingTop: "10px",
                resize: "none",
                width: "90%",
              }}
              placeholder="Enter Your Status Details"
              onChange={(e) =>
                setProfileData({
                  ...profiledata,
                  description: e.target.value,
                })
              }
              defaultValue={Userdata?.getLoggedInUser.status || ""}
            />
          </Toggleside>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <InputsDiv style={{ marginTop: "5%" }}>
              <TelegramDiv>
                <H1 style={{ marginTop: "-.5%", marginBottom: "5%" }}>
                  Last Name*
                </H1>
                <Input
                  style={{ marginTop: "-1%" }}
                  pattern="\s*\S+.*"
                  title="only white space not allowed"
                  type="text"
                  placeholder="Enter Your Last Name*"
                  onChange={(e) =>
                    setProfileData({ ...profiledata, Lastname: e.target.value })
                  }
                  defaultValue={
                    (userInfo && userInfo.Lastname) ||
                    Userdata?.getLoggedInUser.last_name
                  }
                  required
                />
              </TelegramDiv>
              <H1>Mobile number</H1>
              <Input
                min={0}
                type="number"
                onChange={(e) =>
                  setProfileData({
                    ...profiledata,
                    mobileNumber: parseInt(e.target.value),
                  })
                }
                placeholder="Enter Your Mobile Number"
                pattern="^\S+$"
                //	title='mobile number must be minimum of length 10 and no white space'
                // @ts-ignore
                defaultValue={Userdata?.getLoggedInUser.mobile || ""}
              />
              <div style={{ position: "relative", top: "35px" }}>
                <H1>Email ID*</H1>

                {(loginData && loginData.LoggedInWithGoogle) ||
                Userdata?.getLoggedInUser.isEmailVerified ? (
                  <>
                    <Input
                      style={{
                        marginBottom: "12%",
                        position: "relative",
                        width: "390px",
                      }}
                      type="email"
                      placeholder="Enter Your Email Id*"
                      value={
                        (userInfo && userInfo.email) ||
                        Userdata?.getLoggedInUser.email
                      }
                      readOnly={true}
                      required
                    />
                    <Div style={{ height: "50px" }}>Verified</Div>
                  </>
                ) : (
                  <>
                    <Input
                      style={{
                        marginBottom: "12%",
                        position: "relative",
                        width: "360px",
                      }}
                      type="email"
                      placeholder="Enter Your Email Id"
                      onChange={(e) =>
                        setProfileData({
                          ...profiledata,
                          email: e.target.value,
                        })
                      }
                      defaultValue={Userdata?.getLoggedInUser.email || ""}
                    />

                    <BTN style={{ height: "50px" }} onClick={showingModal}>
                      Verify Email
                    </BTN>
                  </>
                )}
                <Availaiblemsg color={MSGCOLOR}>{message}</Availaiblemsg>
              </div>
            </InputsDiv>
          </div>
        </RightSide>
      </ProfileSection>
      <br />

      <LineThrough>
        <span
          style={{
            backgroundColor: "white",
          }}
        >
          Social Media Handle
        </span>
      </LineThrough>
      <ProfileSection>
        <LeftSide>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div style={{ display: "flex", marginTop: "2%" }}>
              <H1>
                {" "}
                Add Telegram Handle{" "}
                <InputImg
                  style={{ height: "20px", width: "35px" }}
                  src={Telegram}
                  alt=""
                />
              </H1>
            </div>
            <TelegramDiv>
              <Input
                pattern="\s*\S+.*"
                title="only white space not allowed"
                type="text"
                placeholder="Add Telegram Handle"
                autoComplete="off"
                onChange={(e) =>
                  setProfileData({
                    ...profiledata,
                    socialTelegram: e.target.value,
                  })
                }
                defaultValue={Userdata?.getLoggedInUser.socialTelegram || ""}
              />
            </TelegramDiv>
            <div style={{ display: "flex" }}>
              <H1>
                {" "}
                Add Twitter Handle{" "}
                <InputImg
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                  src={twitter}
                />
              </H1>
            </div>
            <TelegramDiv>
              <Input
                // @ts-ignore
                type="text"
                pattern="\s*\S+.*"
                title="only white space not allowed"
                placeholder="Add Twitter Handle"
                autoComplete="off"
                onChange={(e) =>
                  setProfileData({
                    ...profiledata,
                    socialTwitter: e.target.value,
                  })
                }
                defaultValue={Userdata?.getLoggedInUser.socialTwitter}
              />
            </TelegramDiv>
            <div style={{ display: "flex" }}>
              <H1>
                {" "}
                Add Youtube Handle{" "}
                <InputImg
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                  src={youtube}
                  alt=""
                />
              </H1>
            </div>
            <TelegramDiv>
              <Input
                // @ts-ignore
                type="text"
                pattern="\s*\S+.*"
                title="only white space not allowed"
                placeholder="Add Youtube Handle"
                autoComplete="off"
                onChange={(e) =>
                  setProfileData({
                    ...profiledata,
                    socialYoutube: e.target.value,
                  })
                }
                defaultValue={Userdata?.getLoggedInUser.socialYoutube}
              />
            </TelegramDiv>
            <div style={{ display: "flex" }}>
              <H1>
                {" "}
                Add Twitch Handle{" "}
                <InputImg
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                  src={twitch}
                  alt=""
                />
              </H1>
            </div>
          </div>

          <TelegramDiv>
            <Input
              // @ts-ignore
              pattern="\s*\S+.*"
              title="only white space not allowed"
              type="text"
              placeholder="Add Twitch Handle"
              autoComplete="off"
              onChange={(e) =>
                setProfileData({
                  ...profiledata,
                  socialTwitch: e.target.value,
                })
              }
              style={{ width: "90%" }}
              defaultValue={Userdata?.getLoggedInUser.socialTwitch}
            />
          </TelegramDiv>
        </LeftSide>

        <RightSide>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "17%",
            }}
          >
            <div style={{ display: "flex" }}>
              <H1>
                {" "}
                Add Discord Handle{" "}
                <InputImg
                  style={{ width: "30px", height: "22px" }}
                  src={Discord}
                  alt=""
                />
              </H1>
            </div>
            <TelegramDiv>
              <Input
                // @ts-ignore
                type="text"
                pattern="\s*\S+.*"
                title="only white space not allowed"
                placeholder="Add Discord Handle"
                autoComplete="off"
                onChange={(e) =>
                  setProfileData({
                    ...profiledata,
                    socialDiscord: e.target.value,
                  })
                }
                defaultValue={Userdata?.getLoggedInUser.socialDiscord}
              />
            </TelegramDiv>
            <div style={{ display: "flex" }}>
              <H1>
                {" "}
                Add Instagram Handle{" "}
                <InputImg
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                  src={insta}
                  alt=""
                />
              </H1>
            </div>
            <TelegramDiv>
              <Input
                // @ts-ignore
                type="text"
                pattern="\s*\S+.*"
                title="only white space not allowed"
                placeholder="Add Instagram Handle"
                autoComplete="off"
                onChange={(e) =>
                  setProfileData({
                    ...profiledata,
                    socialInstagram: e.target.value,
                  })
                }
                defaultValue={Userdata?.getLoggedInUser.socialInstagram}
              />
            </TelegramDiv>
            <div style={{ display: "flex" }}>
              <H1>
                {" "}
                Add TikTok Handle{" "}
                <InputImg
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                  src={tiktok}
                  alt=""
                />
              </H1>
            </div>
            <TelegramDiv>
              <Input
                // @ts-ignore
                type="text"
                placeholder="Add TikTok Handle"
                autoComplete="off"
                onChange={(e) =>
                  setProfileData({
                    ...profiledata,
                    socialTiktok: e.target.value,
                  })
                }
                defaultValue={Userdata?.getLoggedInUser.socialTiktok}
              />
            </TelegramDiv>
          </div>
        </RightSide>
      </ProfileSection>
      <LineThrough>
        <span
          style={{
            backgroundColor: "white",
          }}
        >
          Reset Password
        </span>
      </LineThrough>
      <ChangePasswordSection>
        <LeftSide>
          <H1>New password</H1>
          <Input
            type="password"
            autoComplete="new-password"
            onChange={(e) =>
              setProfileData({ ...profiledata, newpassword: e.target.value })
            }
            placeholder="Enter Your Current Password"
            min={8}
            pattern=".{8,}"
            title="new password must be minimum of length 8 or more"
            // required
          />
          {passMesssage}
        </LeftSide>
        <RightSide>
          <H1>Old password</H1>
          <Input
            type="password"
            autoComplete="off"
            onChange={(e) =>
              setProfileData({
                ...profiledata,
                oldpassword: e.target.value,
              })
            }
            placeholder="Confirm Your New Password"
            min={6}
            max={10}
          />
          {passNotMatch}
        </RightSide>
      </ChangePasswordSection>
      <BtnDiv>
        {continues ||
        (loginData && loginData.LoggedInWithGoogle) ||
        (loginData && loginData.isEmailVerified ||Userdata && Userdata?.getLoggedInUser.isEmailVerified) ? (
          <Button style={{ width: "17%" }}>Continue to Main site </Button>
        ) : (
          <InactiveBTN>Continue to main site</InactiveBTN>
        )}
        {errorMessage && errorMessage}
      </BtnDiv>
      {showModal ? (
        <PopUpOverlay>
          <Popup>
            <H1>Verify Email</H1>
            <br />
            <ClosePopUp onClick={hideModal}>
              <img src={Close} alt="" style={{ cursor: "pointer" }} />
            </ClosePopUp>
            <ContentDiv style={{ width: "100%" }}>
              <H1>An Otp Has Been Sent To {profiledata.email}</H1>
              <Input
                style={{ height: "45px" }}
                type="text"
                id="field-name"
                name="field-name"
                minLength={6}
                maxLength={6}
                onChange={(e) => setOtp(parseInt(e.target.value))}
                required
              />
            </ContentDiv>
            {notValid ? (
              <InactiveBTN style={{ width: "20%" }}>Verify</InactiveBTN>
            ) : (
              <Button style={{ width: "20%" }} onClick={handleVerifyOTP}>
                Verify
              </Button>
            )}

            <H1>{otpMessage}</H1>
          </Popup>
        </PopUpOverlay>
      ) : (
        <> </>
      )}
    </Container>
  );
};
export default ProfileManagement;
